import React from "react"

const FlagPeru = (style:any) => (

  <svg
    width={style.width}
    height={style.height}

    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.22222 2.38419e-07C0.995 2.38419e-07 0 0.995 0 2.22222V12.2222C0 13.4494 0.995 14.4444 2.22222 14.4444H6.66667V2.38419e-07H2.22222Z"
      fill="#D91023"
    />
    <path
      d="M6.66667 14.4444H13.3333V0L6.66667 2.38419e-07V14.4444Z"
      fill="#EEEEEE"
    />
    <path
      d="M17.7778 0H13.3333V14.4444H17.7778C19.005 14.4444 20 13.4494 20 12.2222V2.22222C20 0.995 19.005 0 17.7778 0Z"
      fill="#D91023"
    />
  </svg>
)

FlagPeru.defaultProps = {
  width:"20",
  height:"15"
}

export default FlagPeru
