import React from "react"
import styled from "@emotion/styled"

import greenArrow from "../../../images/green-arrow.svg"

const GreenArrow = styled.div`
  background-image: url(${greenArrow});
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 15px;
`

const GreenArrowIcon = () => <GreenArrow />

export default GreenArrowIcon
