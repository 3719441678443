import React from "react"
import styled from "@emotion/styled"

import depositIconOff from "../../../images/icn-deposit-off.svg"

const DepositIcon = styled.div`
  background-image: url(${depositIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
`

const DepositIconOff = () => <DepositIcon />

export default DepositIconOff
