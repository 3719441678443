const formatAmount = (
  amount: string,
  thousand: string = '.',
  decimals: string = ',',
  removeDecimals: boolean = false
) => {
  const parseAmount = parseFloat(amount)

  if (!isNaN(parseAmount)) {
    const splitedAmount = amount.split(/[,.]/)
    let valueFormat = parseInt(splitedAmount[0], 10).toLocaleString()

    if (thousand === '.') {
      valueFormat = valueFormat.replace(/,/g, '.')
    }

    let value = valueFormat

    if (!removeDecimals && splitedAmount[1]) {
      value = `${valueFormat}${decimals}${splitedAmount[1]}`
    }

    return value
  }

  return amount
}

export {
  formatAmount
}
