import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from "react-responsive-modal"
import { Link } from '@reach/router'
import styled from "@emotion/styled"

import plans from './plans-list'
import PlanDetails from './plan-details'

type Modal = {
  isModalOpen: boolean,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  kycNextStep?: string | null,
}

const ModalTitle = styled.div`
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 32px;
  padding-top: 40px;
  @media (max-width: 600px) {
    width: 273px;
  }
`

const ModalDescription = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  width: 549px;
  display: block;
  margin: auto;
  padding-top: 20px;
  @media (max-width: 812px) {
    width: 294px;
    text-align: center;
    font-size: 14px;
  }
`

const Button = styled(Link)`
  background-color: #30AB76;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 55px;
  padding-right: 55px;
  border-radius: 5px;
  border: none;
  height: 44px;
  font-family: "Roboto";
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 812px) {
    margin-left: 0;
    width: 19.6rem;
  }
`

const CallToAction = styled.div`
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
`

const ContainerDesktop = styled.div`
  width: 700px;

  @media (min-width: 800px) {
    display: flex;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export default function ModalPlans({ isModalOpen, setIsModalOpen, kycNextStep }: Modal) {

  const renderListPlans = plans.map((plan, index): JSX.Element =>
    <PlanDetails {...plan} key={index} />
  )

  return (
    <Modal
      open={isModalOpen}
      onClose={(): void => setIsModalOpen(false)}
      classNames={{
        modal: "modal-plans",
      }}
    >
      <ModalTitle>Tipos de Cuentas</ModalTitle>
      <ModalDescription>
        Dos tipos de cuentas según tu capital inicial destinadas a que puedas vivir la experiencia del trading de la mejor manera y cuides tu capital.
      </ModalDescription>
      <CallToAction>
        {kycNextStep && <Button to={`/${kycNextStep}`}>Completar perfil</Button>}
      </CallToAction>
      <ContainerDesktop>
        {renderListPlans}
      </ContainerDesktop>
    </Modal>
  )
}
