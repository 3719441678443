import React from "react"
import styled from "@emotion/styled"

import loaderGif from "../../../images/loader.gif"

const Loader = styled.img`
  width: 200px;
`

const LoaderGif = () => (
  <Loader src={loaderGif} alt="Preparando todo para ingresar a tu cuenta" />
)

export default LoaderGif
