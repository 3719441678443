import React from "react"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"

import "react-circular-progressbar/dist/styles.css"

type Props = {
  percentage: number
  startIcon: any
  endIcon: any
}

const ProgressCirle = ({ percentage, startIcon, endIcon }: Props) => {
  return (
    <div style={{ width: 60, height: 60 }}>
      <CircularProgressbarWithChildren
        value={percentage}
        text={percentage === 100 ? "" : `${percentage}`}
        styles={{
          root: {},
          path: {
            stroke: `rgb(48, 171, 118, ${percentage / 100})`,
            strokeWidth: 5,
            fillOpacity: 0,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.5s ease 0s",
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
          },
          trail: {
            stroke: "#eeeeee",
            strokeWidth: 5,
            fillOpacity: 0,
            strokeLinecap: "butt",
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
          },
          text: {
            fill: "rgb(48, 171, 118)",
            fontFamily: '"Open Sans", Helvetica, sans-serif',
            fontSize: "18px",
          },
          background: {
            fill: "#ffffff",
          },
        }}
      >
        {percentage === 0 && startIcon}
        {percentage === 100 && endIcon}
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default ProgressCirle
