import React, { useState } from "react"
import styled from "@emotion/styled"
import { navigate } from "@reach/router"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import WarningIconColor from "../ui/images/warning-icon-color"
import CustomButtonV2 from "../ui/custom-button-v2"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 50px auto;
  max-width: 490px;
`

const Title = styled.h3`
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
`
const WarningMessage = styled.p`
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
const ContainerAcceptance = styled.div`
  background-color: #fff0d2;
  padding: 10px 20px;
  margin-bottom: 10%;
`
const Acceptance = styled.p`
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`

const saveButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 48px",
  width: "260px",
  backgroundColor: "#2ba770",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const loadingButtonStyle = saveButtonStyle


const BadTraderProfile = () => {
  const { state } = useAuth()
  const { user, setUser } = state
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    if (isLoading) return
    setIsLoading(true)

    const result = await KYC.updateRiskBadTraderProfile(user as User)
    console.log(result)

    setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <Container>
      <WarningIconColor />
      <Title>
        Tu nivel de conocimiento o riesgo no es el recomendable para hacer Trading
      </Title>
      <WarningMessage>
        Si decides abrir una cuenta y vivir el mundo del trading, ten en cuenta que lo harás bajo tu propia responsabilidad. Será necesario que nos indiques expresamente tu deseo de proceder.
      </WarningMessage>
      <ContainerAcceptance>
        <Acceptance>
          Asumo plena responsabilidad en abrir cuenta con Capitaria. Tras la evaluación de perfilamiento Capitaria me recomienda que el producto trading no se ajusta totalmente a mi perfil como inversionista. Entiendo que el trading no es para todos, por lo que asumo bajo mi responsabilidad abrir una cuenta con Capitaria.
        </Acceptance>
      </ContainerAcceptance>
      <CustomButtonV2
        type="button"
        onClick={handleConfirm}
        loading={isLoading}
        style={saveButtonStyle}
        loadingStyle={loadingButtonStyle}
      >
        Confirmar y continuar
      </CustomButtonV2>
    </Container>
  )
}

export default BadTraderProfile
