import React, { useState } from "react"
import styled from "@emotion/styled"

import { useAuth } from "../../hooks/auth-context"
import { updateCardFileFlag } from "../../services/verify-documents"

import VerifyCardIcon from "../ui/images/verify-card-icon"
import SuccessCardUploadImg from "../ui/images/success-card-upload-img"

import UploadFile from "./upload-file"
import CustomButtonV2 from "../ui/custom-button-v2"

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`

const HowToDo = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 812px) {
    margin-bottom: 1rem;
  }
`

const Title = styled.h3`
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  margin-top: 0;
  margin-bottom: 0;
  color: #1f2425;
  text-align: center;
`

const SubTitle = styled.h4`
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 140%;
  color: #1f2425;
`

const Description = styled.p`
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 140%;
  color: #1f2425;
`

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const UploadFileWrapper = styled.div`
  width: 100%;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SusccessSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
`

const SuccessTitle = styled.h4`
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 118.1%;
  color: #363f41;
  margin: 2rem 0 0;
  max-width: 280px;
`

const SuccessDescription = styled.p`
  text-align: center;
  font-size: 12px;
  line-height: 118.1%;
  color: #363f41;
  margin: 0.25rem 0 1rem;
`

const saveButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 48px",
  width: "260px",
  backgroundColor: "#2ba770",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const saveButtonLoadingStyle = saveButtonStyle

const saveButtonDisabledStyle = {
  marginTop: "1.5rem",
  padding: "12px 48px",
  width: "260px",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const TextValidate = styled.p`
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  display: flex;
  width: 311px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
`

const ContainerTextDescription = styled.div`
  width: 311px;
  margin-top: 10px;
`

const OmmitLink = styled.div`
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  cursor: pointer;
`

const ContainerPassStep = styled.div`
  background-color: #E0F5F5;
  border-radius: 5px;
  width: 377px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 25px;
  @media (max-width: 450px) {
    width: 97%;
  }
`

const SubtitlePassStep = styled.p`
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`

const SuccessScreen = () => (
  <SusccessSection>
    <SuccessCardUploadImg />
    <SuccessTitle>¡TARJETA REGISTRADA CON ÉXITO!</SuccessTitle>
    <SuccessDescription>
      Tu próximo depósito se realizará en segundos
    </SuccessDescription>
  </SusccessSection>
)

type HowToVerifyProps = {
  onClick: () => void
  onClickOmmit: () => void
  isLoading: boolean
}

const HowToVerify = ({ onClick, isLoading, onClickOmmit }: HowToVerifyProps) => {
  const [disableSaveButton, setDisableSaveButton] = useState(true)

  const onChange = () => {
    setDisableSaveButton(false)
  }

  return (
    <>
      <ContainerPassStep>
        <SubtitlePassStep>
          ¿Hiciste Transferencia Bancaria?
        </SubtitlePassStep>
        <OmmitLink onClick={onClickOmmit}>Saltar este paso</OmmitLink>
      </ContainerPassStep>
      <Title>Validación de tarjeta bancaria</Title>
      <TextValidate>Sube una fotografía de tu tarjeta de crédito o débito dejando visible solo los últimos 4 dígitos de tu tarjeta. </TextValidate>
      <UploadContainer>
        <UploadFileWrapper>
          <UploadFile fileName="cardLastNumbers" onChange={onChange} />
        </UploadFileWrapper>

        <CustomButtonV2
          type="button"
          disabled={disableSaveButton}
          loading={isLoading}
          style={saveButtonStyle}
          disabledStyle={saveButtonDisabledStyle}
          loadingStyle={saveButtonLoadingStyle}
          onClick={onClick}
        >
          Enviar y continuar
        </CustomButtonV2>
      </UploadContainer>

      <HowToDo>

        <SubTitle>¿Cómo tiene que ser esta fotografía?</SubTitle>
        <IconWrapper>
          <VerifyCardIcon />
        </IconWrapper>
        <ContainerTextDescription>
          <Description>
            Para tu seguridad, te pedimos una fotografía en donde{" "}
            <strong>
              sólo dejes visibles tu nombre completo y los últimos 4 números de tu tarjeta.
            </strong>
          </Description>
          <Description>
            Con esta imagen validamos que tú eres el titular de esta cuenta y tarjeta, ya que por cumplimiento, no aceptamos pagos de terceros.
          </Description>
        </ContainerTextDescription>
      </HowToDo>

    </>
  )
}

type Props = {
  kyc: boolean
}

const VerifyCard = ({ kyc }: Props) => {
  const { state } = useAuth()
  const user = state.user as User

  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setIsLoading(true)

    try {
      const result = await updateCardFileFlag(user, kyc)

      if (result.user) {
        state.setUser(result.user)
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onOmmitCardFile = async () => {
    setIsLoading(true)

    try {
      const result = await updateCardFileFlag(user, kyc, true)

      if (result.user) {
        state.setUser(result.user)
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Content>
      {user.wasCardFileUploaded ? (
        <SuccessScreen />
      ) : (
        <HowToVerify onClick={onClick} isLoading={isLoading} onClickOmmit={onOmmitCardFile} />
      )}
    </Content>
  )
}

export default VerifyCard
