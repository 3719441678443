import React from 'react'
import CustomCheckbox from "../../ui/custom-checkbox"


export default function Declarations({
  field,
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  children
}: any) {
  return (
    <CustomCheckbox
      label={children}
      value={values[field]}
      onChange={value => setFieldValue(field, value)}
      onBlur={handleBlur}
      hasError={errors[field] && touched[field]}
      errorMessage={errors[field]}
    />
  )
}
