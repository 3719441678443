export const Teams: ExecutiveTeam[] = [
  "BIDASK",
  "METETRADERS",
  "OSIGLIADORES",
  "INKA TRADERS",
  "ALLIGATORS",
]

export const executives: TeamType = {
  BIDASK: [
    {
      name: "Gabriela Mellado",
      title: "Scrum Master",
      email: "gmellado@capitaria.com",
      wasap: "+56997335899",
      image: "gabriela-mellado.png",
      gender: "female",
    },
    {
      name: "Camila Escobar",
      title: "Trading Executive",
      email: "cescobar@capitaria.com",
      wasap: "+56971371936",
      image: "camila-escobar.png",
      gender: "female",
    },
    {
      name: "Patricio Olmos",
      title: "Trading Executive",
      email: "polmos@capitaria.com",
      wasap: "+56954013852",
      image: "patricio-olmos.png",
      gender: "male",
    },
    {
      name: "Felipe Parro",
      title: "Trading Executive",
      email: "fparro@capitaria.com",
      wasap: "+56971356431",
      image: "felipe-parro.png",
      gender: "male",
    },
  ],
  METETRADERS: [
    {
      name: "Constanza Forno",
      title: "Senior Trading Executive",
      email: "constanza@capitaria.com",
      wasap: "+56971356392",
      image: "constanza-forno.png",
      gender: "female",
    },
    {
      name: "Sergio Rivadeneira",
      title: "Scrum Master",
      email: "sergior@capitaria.com",
      wasap: "+56991818221",
      image: "sergio-rivadeneira.png",
      gender: "male",
    },
    {
      name: "Consuelo Podlech",
      title: "Trading Executive",
      email: "cpodlech@capitaria.com",
      wasap: "+56993212801",
      image: "consuelo-podlech.png",
      gender: "female",
    },
    {
      name: "José Tomás Riveros",
      title: "Trading Executive",
      email: "jriveros@capitaria.com",
      wasap: "+56971371926",
      image: "",
      gender: "male",
    },
  ],
  OSIGLIADORES: [
    {
      name: "Luis Felipe Hernández",
      title: "Senior Trading Executive",
      email: "lhernandez@capitaria.com",
      wasap: "+56954013953",
      image: "luis-felipe-hernandez.png",
      gender: "male",
    },
    {
      name: "Gonzalo Lavín",
      title: "Senior Trading Executive",
      email: "glavin@capitaria.com",
      wasap: "+56978596998",
      image: "gonzalo-lavin.png",
      gender: "male",
    },
    {
      name: "Ignacio Vidal",
      title: "Trading executive",
      email: "ividal@capitaria.com",
      wasap: "+56967287402",
      image: "ignacio-vidal.png",
      gender: "male",
    },
    {
      name: "Rosario Saez",
      title: "Trading Executive",
      email: "rsaez@capitaria.com",
      wasap: "+56968436244",
      image: "rosario-saez.png",
      gender: "female",
    },
  ],
  "INKA TRADERS": [
    {
      name: "Santiago Málaga",
      title: "Scrum Master",
      email: "smalaga@capitaria.com",
      wasap: "+51944737651",
      image: "santiago-malaga.png",
      gender: "male",
    },
    {
      name: "Ignacio Higueras",
      title: "Trading Executive",
      email: "ihigueras@capitaria.com",
      wasap: "+51944737651",
      image: "ignacio-higueras.png",
      gender: "male",
    },
    {
      name: "Adriana Cuellar",
      title: "Trading Executive",
      email: "jcuellar@capitaria.com",
      wasap: "+51944737651",
      image: "",
      gender: "female",
    },
    {
      name: "Rodrigo Olivares",
      title: "Trading Executive",
      email: "rolivares@capitaria.com",
      wasap: "+51944737648",
      image: "",
      gender: "male",
    },
  ],
  ALLIGATORS: [
    {
      name: "Nicolás Müller",
      title: "Scrum Master",
      email: "nmuller@capitaria.com",
      wasap: "+56971356434",
      image: "nicolas-muller.png",
      gender: "male",
    },
    {
      name: "Joel Rodríguez",
      title: "Trading Executive",
      email: "jrodriguez@capitaria.com",
      wasap: "+56968435712",
      image: "joel-rodriguez.png",
      gender: "male",
    },
    {
      name: "Jorge Manzione",
      title: "Trading Executive",
      email: "jmanzione@capitaria.com",
      wasap: "+59895437973",
      image: "jorge-manzione.png",
      gender: "male",
    },
  ],
}
