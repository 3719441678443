import React from "react"
import styled from "@emotion/styled"

import calculatorIconOn from "../../../images/icn-calculator-on.svg"

const CalculatorIcon = styled.div`
  background-image: url(${calculatorIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const CalculatorIconOn = () => <CalculatorIcon />

export default CalculatorIconOn
