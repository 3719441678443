import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import PageLayout from "../../page-layout"

import { API, graphqlOperation } from "aws-amplify"

import { useAuth } from "../../../hooks/auth-context"
import { getTokenNuvei } from "../../../graphql/queries"

import LoadingScreenIcon from "../../ui/images/loading-screen"
import SuccessNuveiPage from "../success-page"
import { MexicoCreditCard } from "../mexico-credit-card"
import InputCurrency from "./input-currency"
import AlertBanner from "../../ui/alert-banner"

const CreditCard = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 600px;
  margin-bottom: 10px;
`

const LabelPayment = styled.div`
  background-color: #DDF2FA;
  width: 600px;
  color: black;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  justify-content: center;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
      type: "text/javascript",
      defer: true,
      src,
      onerror: (e: any) => {
        reject(e)
      }
    })
    scriptElem.onload = () => {
      resolve()
    }
    document.body.appendChild(scriptElem)
  })

type PropsPaymentMethod = {
  [key: string]: any
}

const PaymentMethod: PropsPaymentMethod = {
  creditcard: MexicoCreditCard,
}

const ConfigPayments = {
  PEN: {
    env: "prod",
    merchantId: "6989725270836596280",
    merchantSiteId: "243496"
  },
  USD: {
    env: "prod",
    merchantId: "960245599035110614",
    merchantSiteId: "298538"
  },
}

const PeruCreditCard = () => {

  const [safeCharge, setSafeCharge] = useState(null)
  const [amount, setAmount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [modulo, setModulo] = useState(false)
  const [nuveiInfo, setNuveiInfo] = useState({} as any)

  const { state } = useAuth()
  const user = state.user as User

  const [success, setSuccess] = useState(false)
  const [dataSuccess, setDataSuccess] = useState({})
  const [currency, setCurrency] = useState<CurrencyPeruProps>("PEN")

  useEffect(() => {
    const loadSafecharge = async () => {
      await loadScript(
        "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js" //production
      )
      // @ts-ignore
      if (window.SafeCharge) {
        setSafeCharge(
          // @ts-ignore
          window.SafeCharge(ConfigPayments['PEN'])
        )
      }
    }

    loadSafecharge()
  }, [])

  const changeCurrency = (newCurrency: CurrencyPeruProps) => {
    setCurrency(newCurrency)
    setSafeCharge(lastConfig => {
      console.log('Cambiando la config por ', newCurrency)
      // @ts-ignore
      return window.SafeCharge(ConfigPayments[newCurrency])

    })
  }

  const modulodepago = async () => {
    if (loading) return
    if (amount <= 0) return

    setLoading(true)
    const response = await API.graphql(
      graphqlOperation(
        getTokenNuvei,
        {
          cognitoId: user.cognitoId,
          amount: amount,
          currency: currency,
          country: "peru",
        }
      )
    )
    setNuveiInfo(JSON.parse(response.data.getTokenNuvei))
    setLoading(false)
    setModulo(true)
  }

  const onSuccess = (data: any) => {
    setDataSuccess(data)
    setSuccess(true)
  }

  const ComponentPayment: any = PaymentMethod['creditcard']

  return (
    <PageLayout>
      <CreditCard>
        {!modulo && !loading && (
          <InputCurrency
            currency={currency}
            amount={amount}
            setAmount={setAmount}
            onClickPay={modulodepago}
            setCurrency={changeCurrency}
          />
        )}
        {modulo && <LabelPayment>
          Monto a depositar: {currency} ${amount}
        </LabelPayment>}
      </CreditCard>
      {loading && <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>}
      {modulo && !success &&
        <ComponentPayment
          safeCharge={safeCharge}
          nuveiInfo={nuveiInfo}
          onSuccess={onSuccess}
          country="PE"
        />
      }
      {success && <SuccessNuveiPage
        data={dataSuccess}
        amount={amount}
        currency={currency}
        orderId={nuveiInfo?.success?.clientUniqueId}
        paymentMethod="creditcard"
      />}
      <AlertBanner>
        <strong>IMPORTANTE:</strong> Por el uso de Tarjeta de Crédito o Débito,
        este tiene un costo de 3,25%.
        Dicho costo será descontado del monto depositado.
        <br />
        <br />
        <strong>
          NO SE ACEPTAN PAGOS PROVENIENTES DE TARJETAS DE TERCEROS.
        </strong>{" "}
        Si esto sucede, el pago será anulado.
      </AlertBanner>
    </PageLayout>
  )
}

export default PeruCreditCard
