import React, { useEffect } from "react"
import { Redirect } from "@reach/router"

import { useAuth } from "../../hooks/auth-context"
import { logout } from "../../services/auth"

import { logoutTrackUser } from "../../helpers/tracker"

const Logout = () => {
  const {
    state: { setUser },
  } = useAuth()

  useEffect(() => {
    const logoutUser = async () => {
      logoutTrackUser()
      const result = await logout()
      setUser(result.user)
    }

    logoutUser()
  }, [setUser])

  return <Redirect to="/login" noThrow />
}

export default Logout
