import React from "react"
import styled from "@emotion/styled"

const DisclaimerText = styled.section`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 140%;
  text-align: center;
  color: #1A2225;
  max-width: 924px;
  margin: 20px 18px;

  @media (max-width: 425px) {
    text-align: justify;
    margin: 0px 20px 16pxs
  }

  @media (max-width: 768px) {
    margin: 70px 18px 13px;
  }
`

const DisclaimerV2 = () => (
  <DisclaimerText>
    En los mercados financieros se generan rápidos movimientos que pueden
    conllevar a un elevado riesgo de pérdidas. Por esta razón, participar
    activamente en estos mercados con apalancamiento financiero, solo es
    aconsejable para aquellos participantes que puedan asumir dicho riesgo.
    Cualquier análisis o consejo sobre el mercado comunicado a través de
    www.capitaria.com o de algún correo electrónico con este remitente, debe ser
    considerado como una opinión, y nunca como una garantía. En ningún caso y
    bajo ningún concepto, Capitaria se hace responsable de las posibles pérdidas
    incurridas por nuestros clientes. Todo cliente potencial de Capitaria debe
    tener en cuenta que su capital puede verse tanto incrementado como
    disminuido, no debiendo participar en este mercado sin antes considerar si
    esta manera de operar es la adecuada para él, en función de sus condiciones
    económicas. Los clientes potenciales deben también tener en cuenta que no se
    pueden garantizar los resultados de las operaciones, y que los resultados
    obtenidos en el pasado no garantizan resultados futuros. El trading implica
    un alto riesgo y un cliente puede perder una cantidad sustancial de dinero,
    no importando qué método utilice. Capitaria ofrece instrumentos transados a
    través de CFD's, que no están regulados por alguna entidad local.*Argentina:
    depósitos y retiros de dinero sólo con cuenta bancaria en el extranjero*
  </DisclaimerText>
)

export default DisclaimerV2
