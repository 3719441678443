import React from "react"
import styled from "@emotion/styled"
import { DateTime } from "luxon"

const Message = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #637381;
  margin-top: 20px;
  position: relative;
  top: 10px;
  line-height: 14px;
`

export default function Disclaimer() {
  return (
    <Message>
      {" "}
      *Última actualización el{" "}
      {DateTime.now()
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd")}{" "}
      a las 18:30 <br />
      Los instrumentos se transan a través de CFD's. Capitaria no es asesor de
      inversiones.
    </Message>
  )
}
