import React, { useState } from "react"
import { navigate } from "@reach/router"
import styled from "@emotion/styled"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import CustomButtonV2 from "../ui/custom-button-v2"

const Paragraph = styled.div`
  position: relative;
  max-width: 616px;
  background-color: white;
  margin-bottom: 12px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
`

const ParagraphHeadline = styled.h3`
  margin: 0;
  padding: 16px 24px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`

const ParagraphText = styled.p`
  margin: 29px 61px;
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  min-height: 60px;
  transition: max-height .1s, margin .2s linear .1s;
  &.closed {
    overflow: hidden;
    max-height: 0;
    min-height: 0;
    margin: 0 61px;
  }
`

const ContractTitle = styled.h2`
  min-width: 616px;
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #454F5B;
  text-align: center;

  @media (max-width: 812px) {
    min-width: 100%;
  }
`

const ContractAndAnnex = styled.p`
  color: #363f41;
  font-weight: bold;
  font-size: 13.33px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 0;
  & > a {
    color: #5C6AC4;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    line-height: 18px;
  }
`

const buttonStyle = {
  marginTop: "1.5rem",
  padding: "0px",
  width: "18.95rem",
  backgroundColor: "#2ba770",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const loadingButtonStyle = buttonStyle

const CrossButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 27px;
  top: 15px;
  height: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &.open{
    span{
      &:nth-child(1),
      &:nth-child(2){
        transform: translate(0px, 5px) rotate(-90deg);
        margin: 0;
      }
      &:nth-child(2){
        transform: translate(0px, 1px) rotate(0deg);
      }
    }
  }
`

const LineCross = styled.span`
  background: #1d1d1b;
  display: block;
  width: 18px;
  height: 4px;
  border-radius: 5px;
  margin-bottom: -4px;
  -webkit-transition: all 0.5s linear;
  transition: all 0.3s linear;
`

const initialState: StateTerm = {
  contract: true,
  market: false,
  risk: false,
  'market-liquidity': false,
  orders: false,
  'operations-duration': false,
  deposit: false,
  withdrawals: false,
  recommendations: false,
  communications: false,
  term: false,
  dof: false,
  'relevant-terms': false,
}


type Terms = {
  contract: string
  detail: ItemTerm[]
}


type Props = {
  terms: Terms
}

const TermsDetails = ({ terms }: Props) => {
  const { state } = useAuth()
  const user = state.user as User

  const [isLoading, setIsLoading] = useState(false)
  const [open, toogleOpen] = useState<StateTerm>(initialState)

  const onSubmit = async () => {
    setIsLoading(true)

    const result = await KYC.updateTerms(user)
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  const toggleAccordeon = (key: TypeKey) => () =>
  toogleOpen((stateOpenDiv: StateTerm) => ({
    ...stateOpenDiv,
    [key]: !stateOpenDiv[key]
  }))

  return (
    <>
      <ContractTitle>Resumen de los términos y condiciones</ContractTitle>
      {terms.detail.map((values: ItemTerm, idx: number) => {
        return (
          <Paragraph key={`p-${idx}`}>
            <CrossButton
              onClick={toggleAccordeon(values.key)}
              className={open[values.key] ? "" : "open"}
            >
              <LineCross />
              <LineCross />
            </CrossButton>
            <ParagraphHeadline onClick={toggleAccordeon(values.key)}>
              {values.title}
            </ParagraphHeadline>
            <ParagraphText className={open[values.key] ? "" : "closed"}>
              {values.description}
            </ParagraphText>
          </Paragraph>
        )
      })}

      <ContractAndAnnex>
        <a href={terms.contract} target="_blank" rel="noopener noreferrer">
          Descargar todos los términos, condiciones y sus anexos
        </a>
      </ContractAndAnnex>

      <CustomButtonV2
        style={buttonStyle}
        onClick={onSubmit}
        loading={isLoading}
        loadingStyle={loadingButtonStyle}
      >
        Acepto los términos y condiciones
      </CustomButtonV2>
    </>
  )
}

export default TermsDetails
