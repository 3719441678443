import React from "react"
import styled from "@emotion/styled"
import PublicLayout from "../public-layout"
import ValidateEmailContent from "../validate-email/validate-email-content"
import { navigate, useLocation } from "@reach/router"

const Container = styled.section`
  display: flex;
  min-height: 100%;
`

interface Visitor  {
  email: string
  password: string
}

const ValidateEmailV2 = () => {
  const location = useLocation()
  const state = location.state as Visitor

  if (!state || !state.email || !state.password) {
    navigate("/sign-up-v2")
    return <></>
  }

  return (
    <PublicLayout>
      <Container>
        <ValidateEmailContent  email={state.email} password={state.password} />
      </Container>
    </PublicLayout>
  )
}

export default ValidateEmailV2
