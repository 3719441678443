import React, { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { toast } from "react-toastify"
import { Storage } from "aws-amplify"
import * as Sentry from "@sentry/browser"

import UploadProgressCirle from "../ui/upload-progress-circle"

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  flex-grow: 1;
  margin-left: 7.5px;
  margin-right: 7.5px;
`

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #DFE3E8;
  padding: 12px;
  width: 191px;
  background-color: #fff;
  max-width: 140px;
  height: 114px;
  text-align: center;
`

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    max-height: 114px;
  }
`

const Title = styled.h4`
  line-height: 110%;
  font-weight: bold;
  color: #1f2425;
  text-align: center;
  margin: 0 0 0.75rem;
  font-size: .7em;
  margin-top: 8px;

`

const UploadIconWrapper = styled.div`
  padding: 1rem 0 0;
`

const UploadNote = styled.p`
  font-size: 12px;
  line-height: 11px;
  color: #757575;
`

const FileInput = styled.input`
  display: none;
`

const MessageError = styled.div`
  background-color: #FFCAC0;
  color: #B42006;
  border-radius: 3.064px;
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

type FileTypes = {
  [key: string]: string
}

const fileTypes: FileTypes = {
  "image/png": "png",
  "image/jpeg": "jpeg",
  "application/pdf": "pdf",
}

type Props = {
  title?: string
  fileName: string
  onChange: (event: any) => void
}

const listError = {
  INVALID_TYPE: "Tipo de archivo no soportado",
  INVALID_SIZE: "Archivo excede limite de peso",
  ERROR_UPLOAD: "Error al subir el archivo. Intentelo más tarde",
}

const MAX_SIZE_FILE = 10 * 1024 * 1024

const UploadFile = ({ title, fileName, onChange }: Props) => {
  const [percentage, setPercentage] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setHasError] = useState("")

  useEffect(() => {
    setPercentage(0)
    setHasError("")
  }, [fileName])

  const changeHandler = async (event: any) => {
    setHasError("")
    setPercentage(0)
    const file = event.target.files[0]

    if (file) {
      setLoading(true)
      const ext = fileTypes[file.type]

      if (file.size >= MAX_SIZE_FILE) {
        setHasError(listError.INVALID_SIZE)
      } else if (ext) {
        // fake start for small files
        setPercentage(27)

        try {
          const uploadedFile = await Storage.put(`${fileName}.${ext}`, file, {
            level: "private",
            contentType: file.type,
            progressCallback: (progress: any) => {
              setPercentage(
                Math.round((progress.loaded / progress.total) * 100)
              )
            },
          })

          onChange(uploadedFile)
        } catch (error) {
          Sentry.captureException(error)
          toast.error(
            "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
          )
          setPercentage(0)
          setHasError(listError.ERROR_UPLOAD)
        }
      } else {
        setHasError(listError.INVALID_TYPE)
      }
      setLoading(false)
    }
  }
  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      {hasError && <MessageError>{hasError}</MessageError>}
      <UploadContainer>
        <UploadBox>
          <BoxContent
            onClick={() => {
              if (isLoading) return

              if (fileInputRef.current)
                fileInputRef.current.click()
            }}
          >
            <FileInput
              ref={fileInputRef}
              key={`idf-${fileName}`}
              id={fileName}
              type="file"
              accept="image/png, image/jpeg, .pdf"
              onChange={changeHandler}
            />

            <UploadIconWrapper>
              <UploadProgressCirle
                percentage={percentage}
                hasError={hasError !== ""}
              />
            </UploadIconWrapper>

            <UploadNote>Peso máximo 5 MB en formato jpg, png</UploadNote>
          </BoxContent>
        </UploadBox>
        <Title>{title}</Title>
      </UploadContainer>
    </>
  )
}

export default UploadFile
