import React from "react"
import styled from "@emotion/styled"

import dailyLogo from "../../../images/daily-logo.png"

const Daily = styled.img`
  width: 38px;
  height: 22px;
`

const DailyLogo = () => <Daily src={dailyLogo} alt="Capitaria Daily" />

export default DailyLogo
