import React from "react"
import styled from "@emotion/styled"

import { formatAmount } from "../../helpers/currency"

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  @media (max-width: 812px) {
    padding: 3rem 0 5rem;
    width: 100%;
  }
`

const Content = styled.div`
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  width: 560px;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 560px) {
    width: 100%;
    max-width: 560px;
    padding: 1rem 0;
  }
`

const Title = styled.h4`
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #1f2425;
  margin: 0.25rem 0 1.5rem;
`

const Table = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  &:first-of-type {
    font-weight: 600;
    align-items: center;
    @media (max-width: 560px) {
      display: none;
    }
  }
  @media (max-width: 560px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  @media (min-width: 561px) {
    &:nth-of-type(odd) {
      background-color: #e5e5e5;
    }
  }
`

const Cell = styled.div`
  text-align: center;
  padding: 5px 0;
  @media (max-width: 560px) {
    &:nth-of-type(odd) {
      background-color: #e5e5e5;
    }
  }
`

const Name = styled(Cell)`
  min-width: 100px;
`
const Currency = styled(Cell)`
  min-width: 70px;
`
const Deposit = styled(Cell)`
  min-width: 130px;
`
const Ransom = styled(Cell)`
  min-width: 130px;
`

const TextMobile = styled.div`
  display: none;
  font-size: 10px;
  font-weight: 600;
  @media (max-width: 560px) {
    display: block;
  }
`

const renderSubAccount = (subaccount: SubAccount, idx: number) => {
  const currency = subaccount.currency.toLowerCase()

  const deposit = subaccount[`total_deposit_${currency}`]

  const ransom = subaccount[`total_ransom_${currency}`]

  return (
    <Row key={`idx-row-subaccounts-${idx}`}>
      <Name>
        {subaccount.name}
        <TextMobile>Nombre</TextMobile>
      </Name>
      <Currency>
        {subaccount.currency}
        <TextMobile>Moneda</TextMobile>
      </Currency>
      <Deposit>
        {formatAmount(deposit, ".", ",", currency === "clp")}
        <TextMobile>Depositos</TextMobile>
      </Deposit>
      <Ransom>
        {formatAmount(ransom, ".", ",", currency === "clp")}
        <TextMobile>Rescates</TextMobile>
      </Ransom>
    </Row>
  )
}

const SubAccounts = ({ totalSubAccounts }: SubAccounts) => (
  <Container>
    <Content>
      <Title>Subcuentas</Title>
      <Table>
        <Row>
          <Name>Nombre</Name>
          <Currency>Moneda</Currency>
          <Deposit>Total Depositos</Deposit>
          <Ransom>Total Rescates</Ransom>
        </Row>
        {totalSubAccounts.map(renderSubAccount)}
      </Table>
    </Content>
  </Container>
)

export default SubAccounts
