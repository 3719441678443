import React from "react"
import styled from "@emotion/styled"

import bancoEstado from "../../../images/banco-estado.png"

const BancoEstado = styled.img`
  width: 136px;
  height: 37px;
`

const BancoEstadoLogo = () => (
  <BancoEstado src={bancoEstado} alt="Banco Estado" />
)

export default BancoEstadoLogo
