/** @jsx jsx */
import { useState, ChangeEvent } from "react"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import Select, { OptionTypeBase } from "react-select"

import ErrorIcon from "../ui/images/error-icon"

const FormGroup = styled.div`
  margin-bottom: 1rem;
`

const Label = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
`

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectWrapper = styled.div`
  margin-right: 1rem;
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

const days: OptionTypeBase[] = Array.from(Array(31), (_, x) => {
  const day = {
    label: `${x + 1}`,
    value: x + 1 < 10 ? `0${x + 1}` : `${x + 1}`,
  }
  return day
})
const months: OptionTypeBase[] = [
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
]
const currentYear = new Date().getFullYear()
const years: OptionTypeBase[] = []
for (let i = currentYear; i >= 1920; i--) {
  years.push({ label: i, value: i })
}

type Props = {
  label: string
  style?: any
  onChange?: (e: string | ChangeEvent<any>) => void
  hasError?: boolean | ""
  errorMessage?: string
}

const CustomDatePicker = ({
  label,
  style,
  onChange,
  hasError,
  errorMessage,
}: Props) => {
  const [date, setDate] = useState("####-##-##")

  const updateDate = (dateChange: any, date: string, type: string) => {
    const splittedDate = date.split("-")
    let newDate = ""

    if (type === "day") {
      newDate = `${splittedDate[0]}-${splittedDate[1]}-${dateChange.value}`
    } else if (type === "month") {
      newDate = `${splittedDate[0]}-${dateChange.value}-${splittedDate[2]}`
    } else if (type === "year") {
      newDate = `${dateChange.value}-${splittedDate[1]}-${splittedDate[2]}`
    }

    setDate(newDate)
    if (onChange) onChange(newDate)
  }

  const daysStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 70,
      height: 46,
      borderColor: hasError ? "#e76a6a" : "#e8e8e8",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
  }
  const monthsStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 132,
      height: 46,
      borderColor: hasError ? "#e76a6a" : "#e8e8e8",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
  }

  const yearsStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 92,
      height: 46,
      borderColor: hasError ? "#e76a6a" : "#e8e8e8",
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
  }

  return (
    <FormGroup css={style}>
      <Label>{label}</Label>

      <DateWrapper>
        <SelectWrapper>
          <Select
            styles={daysStyles}
            placeholder="1"
            options={days}
            onChange={value => updateDate(value, date, "day")}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            styles={monthsStyles}
            placeholder="Enero"
            options={months}
            onChange={value => updateDate(value, date, "month")}
          />
        </SelectWrapper>
        <Select
          styles={yearsStyles}
          placeholder={currentYear}
          options={years}
          onChange={value => updateDate(value, date, "year")}
        />
      </DateWrapper>

      {hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </Error>
      )}
    </FormGroup>
  )
}

export default CustomDatePicker
