import React from "react"
import styled from "@emotion/styled"

import verifyIconOff from "../../../images/icn-verify-documents-off.svg"

const VerifyIcon = styled.div`
  background-image: url(${verifyIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const VerifyIconOff = () => <VerifyIcon />

export default VerifyIconOff
