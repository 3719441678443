const listSteps = [
  "kyc/validate-phone",
  "kyc/validate-documents",
  "kyc/validate-liveness",
  "kyc/select-course",
  "kyc/personal-info",
  "kyc/deposit",
  "kyc/document-credit-card",
  "kyc/validate-deposit",
  "kyc/platform",
  "kyc/risk-profile",
  "kyc/knowledge-profile",
  "kyc/bad-trader-profile",
  "kyc/terms",
]

export default listSteps
