import * as Yup from "yup"

const blockedCountries = [
  "United States",
  "Afghanistan",
  "Bosnia and Herzegovina",
  "North Korea",
  "Iraq",
  "Iran",
  "Laos",
  "Syria",
  "Uganda",
  "Vanuatu",
  "Yemen",
]

const PersonalSchema = Yup.object().shape({
  residenceCountry: Yup.string()
    .test(
      "isAllowedCountry",
      "En estos momentos no estamos operando en ${value}, lo sentimos" /* eslint-disable-line no-template-curly-in-string */,
      value => !blockedCountries.includes(value)
    )
    .required("Este campo es requerido"),
  address: Yup.string().required("Este campo es requerido"),
  job: Yup.string().required("Este campo es requerido"),
  occupation: Yup.string().required("Este campo es requerido"),
  isPEP: Yup.boolean().required("Este campo es requerido"),
})

export default PersonalSchema
