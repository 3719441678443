import DiscoverPlatform from "./discover-plataform"
import FirstTrade from "./first-trade"
import Concepts from "./concepts"
import TradingVsInvesting from "./trading-vs-investing"

const steps: StepSlider[] = [
  {
    title: "1. Trading vs Inversiones",
    component: TradingVsInvesting,
    question:
      "Una de las ventajas del trading por sobre una inversión tradicional es",
    options: [
      "Por lo general se abre una operación y se esperan años para poder tomar utilidades",
      "Se puede operar tanto al alza como a la baja. Esto duplica las posibles oportunidades que se pueden encontrar",
      "Existen mitigadores de riesgo",
      "Respuesta 2 y 3",
    ],
    correctAnswer: 4,
  },
  {
    title: "2. Conceptos básicos",
    component: Concepts,
    question: "Un tipo de mitigador de riesgo en el trading es:",
    options: ["Stop Loss", "Seguro contra pérdida", "No abrir una operación"],
    correctAnswer: 1,
  },
  {
    title: "3. Conoce la plataforma",
    component: DiscoverPlatform,
    question: "¿Que es la equidad?",
    options: [
      "Equidad muestra el dinero real que tienes en tu cuenta considerando operaciones abiertas y cerradas",
      "Equidad son las garantías que tienes tomadas",
      "Equidad es lo que depositaste",
    ],
    correctAnswer: 1,
  },
  {
    title: "4. Tu primera operación",
    component: FirstTrade,
    question: "Para abrir una operación, es muy importante:",
    options: [
      "Hacer un análisis previo",
      "Fijarme bien en la cantidad de lotes que voy a operar",
      "Definir un stop loss y take profit",
      "Todas las anteriores",
    ],
    correctAnswer: 4,
  },
]

export default steps
