import React from "react"
import styled from "@emotion/styled"

import calculatorIconOff from "../../../images/icn-calculator-off.svg"

const CalculatorIcon = styled.div`
  background-image: url(${calculatorIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const CalculatorIconOff = () => <CalculatorIcon />

export default CalculatorIconOff
