import React from "react"

const FlagChile = (style:any) => (

  <svg width={style.width} height={style.height} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Chile">
    <g id="Vector">
    <path d="M3.47373 3.87164L2.68957 3.30206L1.90541 3.87164L2.20499 2.94998L1.42082 2.37999H2.38999L2.68957 1.45832L2.98915 2.37999H3.95831L3.17415 2.94998L3.47373 3.87164ZM5.41663 0H1.66666C0.746245 0 0 0.746245 0 1.66666V5.41663H5.41663V0Z" fill="#1F429B"/>
    <path d="M13.3333 0H5.41663V5.41663H14.9999V1.66666C14.9999 0.746245 14.2537 0 13.3333 0Z" fill="#EEEEEE"/>
    <path d="M0 5.41663L9.36979e-05 9.16661C9.36979e-05 10.087 0.746339 10.8333 1.66675 10.8333H13.3333C14.2538 10.8333 15 10.087 15 9.16661L14.9999 5.41663H5.41663H0Z" fill="#D42D27"/>
    <path d="M2.98915 2.37999L2.68957 1.45832L2.38999 2.37999H1.42082L2.20499 2.94998L1.90541 3.87164L2.68957 3.30206L3.47373 3.87164L3.17415 2.94998L3.95831 2.37999H2.98915Z" fill="white"/>
    </g>
    </g>
  </svg>
)
FlagChile.defaultProps = {
  width: "15",
  height: "15",
}

export default FlagChile
//
