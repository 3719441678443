/** @jsx jsx */
import { jsx } from "@emotion/core"
import { FocusEvent, ChangeEvent, KeyboardEvent } from "react"
import styled from "@emotion/styled"

import ErrorIcon from "../ui/images/error-icon"

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Label = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
`

const Input = styled.input`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  @media (max-width: 425px) {
    width: 368px;
    height: 50px;
  }
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
  @media (max-width: 812px) {
    max-width: 300px;
  }
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

const inputError = {
  border: "1px solid #e76a6a",
}

type Props = {
  label: string
  sublabel?: string | React.ReactNode
  name: string
  placeholder?: string
  type?: string
  tabIndex?: number | null
  value?: string
  style?: any
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => {}
  hasError?: boolean | ""
  errorMessage?: string
  styleLabel?: any
  styleGroup?: any
  styleLabelWrapper?: any
  id?: any
}

const CustomInput = ({
  label,
  sublabel,
  name,
  placeholder,
  type = "text",
  tabIndex = null,
  value,
  style,
  styleLabel,
  onChange,
  onBlur,
  onKeyPress,
  hasError,
  errorMessage,
  id,
  styleGroup,
  styleLabelWrapper,
}: Props) => {
  const othersProps: { tabIndex?: number } = {}

  if (tabIndex) {
    othersProps.tabIndex = tabIndex
  }

  return (
    <FormGroup css={styleGroup}>
      <LabelWrapper css={styleLabelWrapper}>
        <Label htmlFor={name} css={styleLabel}>
          {label}
        </Label>
        {sublabel}
      </LabelWrapper>

      <Input
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        css={hasError ? { ...style, ...inputError } : style}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
          onKeyPress && onKeyPress(event)
        }
        }
        {...othersProps}
      />

      {hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </Error>
      )}
    </FormGroup>
  )
}

export default CustomInput
