import React, { useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { DateTime } from "luxon"
import styled from "@emotion/styled"

import { useSimulator } from "./context"
import { getInstrumentsPrice } from "../../../../graphql/queries"
import { trackEvent } from "../../../../helpers/tracker"
import Parameter from "./Parameter"
import fields from "./fields"
import ResultPrediction from "./ResultPrediction"
import ButtonSimulator from "../../../ui/button-simulator"

const Container = styled.div`
  background: #1b2229;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 5px;
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 1px 18px rgba(0, 0, 0, 0.04),
    0px 3px 5px rgba(0, 0, 0, 0.08);
  margin-bottom: 25px;
  @media (max-width: 812px) {
    width: 350px;
  }
`

const Title = styled.div`
  color: #44d091;
  display: flex;
  justify-content: center;
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 24px;
  @media (max-width: 812px) {
    text-align: center;
    font-size: 20px;
  }
`

const SubtitleWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 15px;
  }
`

const Subtitle = styled.div`
  width: 500px;
  font-family: Roboto;
  @media (max-width: 812px) {
    font-size: 14px;
    padding-left: 27.7px;
    padding-right: 27.7px;
  }
`

const Fields = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 812px) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
  }
`

const Prediction = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const FooterMessage = styled.div`
  font-family: Roboto;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 30px;
  @media (max-width: 812px) {
    padding-left: 27.7px;
    padding-right: 27.7px;
    top: 0px;
  }
`

const WrapperButtonSimulator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Simulator() {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [lastDate, setLastDate] = useState<string>("")
  const simulator = useSimulator()

  const loadSimulation = async () => {
    setLoading(true)
    simulator.setResponse({})
    const timeframes: Record<string, any> = {
      "1d": { days: 1 },
      "3d": { days: 3 },
      "1w": { weeks: 1 },
      "2w": { weeks: 2 },
      "1m": { months: 1 },
    }
    const minusTimeframe = timeframes[simulator.values.date]
    const date = DateTime.now()
      .minus(minusTimeframe)
      .toFormat("yyyy-MM-dd")
    const {
      data: { getInstrumentsPrice: response },
    } = await API.graphql(
      graphqlOperation(getInstrumentsPrice, {
        nemo: simulator.values.nemo,
        date,
      })
    )
    trackEvent("homeprospecto:simulador:boton-simular", {
      timeframe: simulator.values.date,
      instrumento: simulator.values.nemo,
      direccion:
        simulator.values.direction === "bid" ? "compra" : "venta",
      tamaño: simulator.values.size,
    })
    const { body } = JSON.parse(response)
    simulator.setResponse(body)
    if (body && body.instruments && body.instruments.length) {
      const date = body.instruments[0].date.split("-")
      const newDate = `${date[2]}-${date[1]}-${date[0]}`
      setLastDate(newDate)
    }
    setIsActive(true)
    setLoading(false)
  }

  const DynamicDisclaimer = () => {
    return (
      <>
        {lastDate.length
          ? `**Precio referencial basado en la hora
        de cierre del día ${lastDate}`
          : ` **Precio referencial basado en la
        hora de cierre en días con mercado abierto `}
      </>
    )
  }

  return (
    <Container>
      <Title>Simulador de Oportunidades</Title>
      <SubtitleWrapper>
        <Subtitle>
          Selecciona un instrumento, una compra o venta y una temporalidad para
          ver cuanto podrías haber ganado o perdido
        </Subtitle>
      </SubtitleWrapper>
      <Fields>
        {fields.map((field: Field, index: number) => (
          <Parameter {...field} key={index} setIsActive={setIsActive} />
        ))}
      </Fields>
      <WrapperButtonSimulator>
        <ButtonSimulator
          onClick={loadSimulation}
          text="Simular"
          loading={loading}
        />
      </WrapperButtonSimulator>
      <Prediction>
        <ResultPrediction isActive={isActive} />
      </Prediction>
      <FooterMessage>
        *Listado instrumentos populares. Al acceder a nuestra plataforma tendrás
        acceso a más de 500 instrumentos
        <br />
        <DynamicDisclaimer />
      </FooterMessage>
    </Container>
  )
}
