import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { trackEvent } from "../../helpers/tracker"

import { useAuth } from "../../hooks/auth-context"

import { Brokers } from "../../services/brokers"

import { API, graphqlOperation } from "aws-amplify"
import { getUserBroker } from "../../graphql/queries"

import LoadingScreenIcon from "../ui/images/loading-screen"
import PageLayout from "../page-layout"

import Transactions from "./transactions"
import UserCountryDepositOnboarding from "./user-country-deposit-onboarding"
import Withdraw from "./withdraw"

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 5rem;
  background-color: #ffffff;
  @media (max-width: 812px) {
    background-color: transparent;
  }
`

const Tab = styled.div`
  text-align: center;
  color: #1f2425;
  width: 217px;
  padding-bottom: 0.5rem;
  cursor: pointer;
`

const activeTab = {
  fontWeight: 600,
  borderBottom: "4px solid #30ab76",
}

const tabsList = {
  deposit: "Depositar fondos",
  withdraw: "Retirar fondos",
  transactions: "Transacciones",
}

const DepositPage = () => {
  const [showSection, setShowSection] = useState("deposit")
  const [broker, setBroker] = useState("loading")
  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    const getExecutivesTeamName = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(getUserBroker, { cognitoId: user.cognitoId })
        )
        const userBroker = JSON.parse(response.data.getUserBroker)

        if (
          userBroker.hasOwnProperty("sf_Broker__c") &&
          Brokers.includes(userBroker.sf_Broker__c)
        ) {
          setBroker(userBroker.sf_Broker__c)
        } else {
          setBroker("")
        }
      } catch (error) {
        Sentry.setExtra("error", JSON.stringify(error))
        Sentry.captureException(error)
        toast.error(
          "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
        )

        setBroker("")
      }
    }

    getExecutivesTeamName()
  }, [user.cognitoId])

  const onClickTab = (tab: string) => () => {
    trackEvent(`deposit:tab:${tab}`)
    setShowSection(tab)
  }

  const goDeposit = () => setShowSection("deposit")

  if (broker === "loading") {
    return (
      <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>
    )
  }

  return (
    <PageLayout>
      <Tabs>
        {Object.entries(tabsList).map(([key, title], idx: number) => (
          <Tab
            key={`idx-tabs-deposit-${idx}`}
            style={showSection === key ? activeTab : undefined}
            onClick={onClickTab(key)}
          >
            {title}
          </Tab>
        ))}
      </Tabs>
      {showSection === "deposit" && <UserCountryDepositOnboarding broker={broker} />}
      {showSection === "withdraw" && <Withdraw />}
      {showSection === "transactions" && <Transactions goDeposit={goDeposit} />}
    </PageLayout>
  )
}

export default DepositPage
