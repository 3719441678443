import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "@emotion/styled"

import mastercardImage from "../../../images/mastercard.png"
import visaImage from "../../../images/visa.png"
import FlagPeru from "../../ui/images/flag-peru"
import FlagUsa from "../../ui/images/flag-usa"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import ButtonCustom from "../../ui/custom-button"


const ContainerNuvei = styled.div`
  min-width: 600px;
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    max-width: 414px;
  }
`

const ContainerCreditCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
`

const ImgCreditCard = styled.img`
  width: 44px;
  height: 44px;
  margin: 0 10px;
`

const PrefixSpan = styled.span`
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: black;
  text-align: center;
  border: 1px solid #919eab;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-right: 0;
  border-radius: 8px 0px 0px 8px;
`

const ContainerInput = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
`

const WrapperInput = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const InputDeposit = styled.input`
  border: 1px solid #919eab;
  border-radius: 8px;
  width: 358px;
  height: 56px;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
`

const styleButtonDeposit = {
  background: "#2BA770",
  color: "white",
  border: "none",
  borderRadius: "8px",
  width: "100%",
  height: "50px",
}

const ContainerButton = styled(WrapperInput)`
  margin-top: 15px;
`

const ContainerSelectCurrency = styled.div`
  width: 100%;
  height: 40px;
  padding: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;

`

const SelectCurrency = styled.button`
  width: 66px;
  height: 29px;
  left: 569px;
  top: 415px;
  border: 2px solid #2BA770;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.disabled {
    opacity: 40%;
    border: 2px solid buttonface;
  }
`

type Props = {
  currency: string
  amount: number
  setAmount: Dispatch<SetStateAction<number>>
  setCurrency: (newCurrency: CurrencyPeruProps) => void
  onClickPay: () => Promise<void>
}


type TranslateCurerncyProps = {
  [key in CurrencyPeruProps]: (props: any) => JSX.Element
}


const InputCurrency = ({ currency, amount, setAmount, onClickPay, setCurrency }: Props) => {
  const listCurrency: CurrencyPeruProps[] = ["PEN"]

  if (useFeatureIsOn("payment-peru-usd"))
    listCurrency.push("USD")

  const translateCurrencyFlag: TranslateCurerncyProps = {
    USD: FlagUsa,
    PEN: FlagPeru,
  }

  const [currencySelected, setCurrencySelected] = useState(currency)
  const [loading, setLoading] = useState(false)

  const changeCurrency = (newCurrency: CurrencyPeruProps) => {
    if (loading) return
    setLoading(true)
    setCurrencySelected(newCurrency)
    setCurrency(newCurrency)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  return (
    <ContainerNuvei>
      {listCurrency.length > 1 && (
        <ContainerSelectCurrency>
          {React.Children.map(listCurrency, (currencyCountrySelected) => {
            const FlagComponent = translateCurrencyFlag[currencyCountrySelected]
            return (
              <SelectCurrency
                className={currencyCountrySelected !== currencySelected ? "disabled" : ""}
                onClick={() => changeCurrency(currencyCountrySelected)}
              >
                <FlagComponent />
                {currencyCountrySelected}
              </SelectCurrency>
            )

          })}
        </ContainerSelectCurrency>
      )}
      <WrapperInput>
        <ContainerInput>
          <PrefixSpan>{currency}</PrefixSpan>
          <InputDeposit
            id="sessionField"
            placeholder="Monto a depositar"
            type="text"
            value={amount || ""}
            onChange={(e) => setAmount(parseInt(e.target.value, 10))}
          />
        </ContainerInput>
      </WrapperInput>
      <ContainerButton>
        <ButtonCustom onClick={onClickPay} style={styleButtonDeposit} loadingStyle={styleButtonDeposit} loading={loading} >Depositar</ButtonCustom>
      </ContainerButton>
      <ContainerCreditCard>
        <ImgCreditCard src={visaImage} alt="visa" />
        <ImgCreditCard src={mastercardImage} alt="mastercard" />
      </ContainerCreditCard>
    </ContainerNuvei>
  )
}

export default InputCurrency
