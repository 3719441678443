const occupations: string[] = [
  "Abogado/Juez",
  "Académico",
  "Actor",
  "Actuario",
  "Administrador",
  "Agente",
  "Agricultor",
  "Analista",
  "Arquitecto",
  "Artista",
  "Asegurador",
  "Asesor",
  "Asistente",
  "Astrofísico",
  "Audiovisual",
  "Auditor",
  "Banquero",
  "Biólogo",
  "Cajero",
  "Cartógrafo",
  "CEO",
  "CFO",
  "Chef",
  "Científico",
  "Cientista Politico",
  "Comerciante",
  "Conductor",
  "Construccion Civil",
  "Consultor",
  "Contador",
  "Contralor",
  "Contratista",
  "Corredor de propiedades",
  "Data Scientist",
  "Deportista",
  "Desarrollador",
  "Director",
  "Diseñador",
  "Distribuidor",
  "Dueño(a) de casa",
  "Economista",
  "Educador",
  "Ejecutivo",
  "Embajador",
  "Empleado",
  "Empleado público",
  "Empresario",
  "Enfermero",
  "Entrenador/instructor",
  "Escritor",
  "Estudiante",
  "Eventos",
  "Examinador",
  "Farmaceútico",
  "Finanzas",
  "Fisioterapeuta",
  "Fotógrafo",
  "Funcionario público",
  "Geofísico",
  "Geólogo",
  "Gerente",
  "Gerente general",
  "Importador/Exportador",
  "Independiente",
  "Ingeniero",
  "Inversionista",
  "Investigador",
  "Jefe contabilidad",
  "Jefe de proyectos",
  "Jefe de ventas",
  "Joyero",
  "Jubilado",
  "KAM",
  "Logística",
  "Manager",
  "Marketing",
  "Mecánico",
  "Médico/dentista/veterinario",
  "Militar/Oficial",
  "Minero",
  "Modista",
  "Músico",
  "Nutricionista",
  "Odontólogo",
  "Oficinista",
  "Operador",
  "Paisajista",
  "Pensionado",
  "Periodista",
  "Piloto/Auxiliar de vuelo",
  "Policia/Bombero/PDI",
  "Préstamos",
  "Prevención riesgos",
  "Product Manager",
  "Productor Eventos",
  "Profesor",
  "Psicologo",
  "Publicista",
  "Químico",
  "Quiropráctico",
  "Recursos Humanos",
  "Relaciones Públicas",
  "Seguridad",
  "Seguros e inversiones",
  "Servicio al cliente",
  "Software",
  "Sommelier",
  "Subgerente",
  "Técnico",
  "Tecnología",
  "Terapeuta",
  "Transportista",
  "Vendedor",
]

export default occupations.map(occupation => ({label: occupation, value: occupation}))
