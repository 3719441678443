import React from "react"
import styled from "@emotion/styled"


interface FormGroupProps {
  isSelected: boolean
}

const FormGroup = styled.div<FormGroupProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  width: 102px;
  height: 64px;
  text-align: center;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected && {
      backgroundColor: "#fff",
      borderColor: "#2ba770",
      boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.08), 0px 3px 14px 0px rgba(0, 0, 0, 0.04), 0px 8px 10px 0px rgba(0, 0, 0, 0.06)",
      opacity: "1",
    }
  }
`

const InputCheckbox = styled.input`
`

const Label = styled.label`
  font-family: "Open Sans";
  color: #919eab;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`

type Props = {
  id: string
  name: string
  label: string
  labelStyle?: any
  inputStyle?: any
  onClick: any
  isSelected: boolean
}

const AnswerCheckbox = ({
  id,
  name,
  label,
  labelStyle,
  onClick,
  isSelected,
}: Props) => {

  const handleClick = () => {
    onClick(id)
  }

  return (
    <FormGroup isSelected={isSelected}>
      <InputCheckbox
        id={id}
        type="checkbox"
        name={name}
        checked={isSelected}
        onClick={handleClick}
      />
      <Label htmlFor={id} style={labelStyle}>
        {label}
      </Label>
    </FormGroup>
  )
}

export default AnswerCheckbox
