import React from "react"
import styled from "@emotion/styled"

import PageLayout from "../page-layout"
import Opportunities from "../shared/home/opportunities"
import Simulator from "../shared/home/simulator"
import { ContextProvider } from "../shared/home/simulator/context"
import DiscoverOpportunities from "./discover-opportunities"
import Slider from "./slider"
import { useAuth } from "../../hooks/auth-context"
import { navigate } from "@reach/router"

const Container = styled.div`
  display: block;
  width: 100%;
`

const Content = styled.div`
  padding: 3rem 0;
  @media (max-width: 812px) {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 3rem;
  @media (max-width: 812px) {
    display: block;
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }
`

const WelcomeTitle = styled.h1`
  font-family: Work Sans;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 24px;
  }
`

const WelcomeSubtitle = styled.div`
  text-align: center;
  width: 516px;
  line-height: 20px;
  font-family: Roboto;
  @media (max-width: 812px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    word-break: break-word;
    font-size: 14px;
    text-align: center;
    text-justify: auto;
  }
`

const ContainerAllValidations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 812px) {
    flex-direction: column;
  }
`
const ContainerValidation = styled.div`
  width: 388px;
  height: 116px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #1F2425;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`

const TitleValidation = styled.p`
  width: 376px;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Button = styled.button`
  background: #2ba770;
  border-radius: 5px;
  height: 48px;
  color: #ffffff;
  text-transform: none;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 10px 30px rgba(0,0,0,0.15);
  padding: 16px 0px;
  font-family: "Roboto";
  margin-top: 1rem;
  height: 48px;
  width: 300px;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 10px 0;
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: 18px;
    line-height:138.889%;
  }
`


const HomeView = (): JSX.Element => {
  const { state } = useAuth()
  const user = state.user as User

  return (
    <PageLayout>
      <Container>
        <Content>
          <ContainerAllValidations>
            {!user.wasPhoneValidate &&
              <ContainerValidation>
                <TitleValidation>Es necesario que vuelvas a validar tu información</TitleValidation>
                <Button
                type="button"
                onClick={() => {
                  navigate("/phone-validation-status")
                }}>
                  Validar Teléfono
                </Button>
              </ContainerValidation>
            }
            {!user.wasIdFileUploaded &&
              <ContainerValidation>
                <TitleValidation>Es necesario que vuelvas a validar tu información</TitleValidation>
                <Button
                type="button"
                onClick={() => {
                  navigate("/documents-validation-status")
                }}>
                  Validar documento
                </Button>
              </ContainerValidation>
            }
            {!user.wasLivenessFileUploaded &&
              <ContainerValidation>
                <TitleValidation>Es necesario que vuelvas a validar tu información</TitleValidation>
                <Button
                type="button"
                onClick={() => {
                  navigate("/liveness-validation-status")
                }}>
                  Validar prueba de vida
                </Button>
              </ContainerValidation>
            }
          </ContainerAllValidations>
          <Welcome>
            <WelcomeTitle>Bienvenido a Capitaria</WelcomeTitle>
            <WelcomeSubtitle>
              Estamos en la misión de empoderar a las personas para que
              tomen el control de sus inversiones entregándoles educación
              constante, información oportuna y una plataforma para que en
              un click puedan acceder a los mercados del mundo
            </WelcomeSubtitle>
          </Welcome>
          <Opportunities />
          <Slider />
          <ContextProvider>
            <Simulator />
          </ContextProvider>
          <DiscoverOpportunities />
        </Content>
      </Container>
    </PageLayout>
  )
}

export default HomeView
