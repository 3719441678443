import React, { memo, useEffect, useState } from "react"
import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/browser"

import LoadingScreenIcon from "../ui/images/loading-screen"
import { useAuth } from "../../hooks/auth-context"
import { trackEvent } from "../../helpers/tracker"

const styleCss = css`
  input {
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #d3dce6;
    color: #4a5568;
    display: block;
    outline: none;
    padding: 5px 12px;
    width: 360px;
    font-family: Nunito Sans, sans-serif;
  }
  input:placeholder-shown {
    border: 1px solid #d3dce6;
  }
  input:hover {
    border: 1px solid #95aac1;
  }
  input:focus {
    border: 1px solid #00becf;
  }
  #card-field-placeholder,
  #card-field,
  #card-number,
  #card-expiry,
  #card-cvc {
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #d3dce6;
    color: #4a5568;
    display: block;
    margin-top: 5px;
    outline: none;
    padding: 7px 12px;
    width: 100%;
  }
  #card_number_three_fields {
    margin-top: 5px;
    padding: 7px 12px;
    width: 470px;
  }

  .pay-button {
    background-color: #00becf;
    border-radius: 3px;
    border: 1px solid #00a6b5;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 140px;
  }
  .pay-button:hover {
    color: #fff;
    background-color: #0f93a1;
  }
  .validation-error {
    color: #ff3f5a;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    visibility: hidden;
  }

  label {
    color: #4a5568;
    font-size: 14px;
    font-weight: 600;
  }
  #card_number {
    width: 700px;
  }
  #cardholder_name {
    width: 470px;
  }
  .App h1 {
    color: #00becf;
  }
  #card-field-placeholder.sfc-focus,
  #card-field.sfc-focus,
  #card-number.focused,
  #card-expiry.focused,
  #card-cvc.focused {
    border: 1px solid #00becf;
  }

  #card-field-placeholder:hover,
  #card-field:hover,
  #card-number:hover,
  #card-expiry:hover,
  #card-cvc:hover {
    border: 1px solid #95aac1;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .sfcModal-header {
    height: 1.5rem;
  }
  .sfcModal-dialog {
    margin: 55px auto;
    max-width: 492px;
    position: relative;
    width: auto;
  }
  .sfcModal-content {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #dfdfdf;
    outline: 0;
    position: relative;
  }
  .sfcModal-close {
    border: 0;
    color: #2c2a2a;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.4rem;
  }
  .sfcIcon--close:before {
    content: "\\2716";
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const ButtonDeposit = styled.button`
  background-color: #2BA770;
  color: white;
  border: none;
  border-radius: 8px;
  width: 400px;
  height: 50px;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`


export const MexicoBankTransfer = memo(({ safeCharge, nuveiInfo, onSuccess, country }: any) => {
  const [loading, setLoading] = useState(true)

  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    if (safeCharge) {
      setLoading(false)
    }
  }, [safeCharge])

  const createPayment = (e: any) => {
    if (loading) return
    setLoading(true)
    try {
      safeCharge.createPayment(
        {
          sessionToken: nuveiInfo.success.sessionToken,
          paymentOption: {
            "alternativePaymentMethod": {
              "paymentMethod": "apmgw_STPmex"
            }
          },
          userDetails: {
            country: country,
          },
          billingAddress: {
            email: user.email,
            country: country
          }
        },
        (response: any) => {
          setLoading(false)
          onSuccess(response)
          if (response.result !== 'APPROVED') {
            const messageError = `email: ${user.email} - code: ${response.errCode} - description: ${response.errorDescription}`
            Sentry.captureException(messageError)
            trackEvent("payment-bank-deposit:mexico:error", {
              error: response.errorDescription,
              code: response.errCode,
            })
          }
        }
      )
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  return (
    <>
      <Global styles={styleCss} />
      <Container>
        {loading && (
          <LoadingWrapper>
            <LoadingScreenIcon />
          </LoadingWrapper>
        )}
        {!loading && (
          <>
            <ContainerButton>
              <ButtonDeposit className="pay-button" onClick={createPayment}>
                Transferir
              </ButtonDeposit>
            </ContainerButton>
          </>
        )}
      </Container>
    </>
  )
})
