import React, { useEffect, useState } from "react"

type Props = {
  active?: boolean
}

const BankTransferImage = ({ active=false }: Props) => {
  const [color, setColor] = useState('#919EAB')

  useEffect(() => {
    if (active) {
      setColor('#2BA770')
    } else {
      setColor('#919EAB')
    }

  }, [active])

  return (
    <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.0667 6.21832C25.9667 6.21832 22.345 8.54166 20.5 11.89C18.5867 8.54166 15.0333 6.21832 10.9333 6.21832C4.92 6.21832 0 11.1383 0 17.1517C0 23.165 4.92 28.085 10.9333 28.085C15.0333 28.085 18.5867 25.7617 20.5 22.4133C22.345 25.7617 25.9667 28.085 30.0667 28.085C36.08 28.085 41 23.165 41 17.1517C41 11.1383 36.08 6.21832 30.0667 6.21832ZM10.9333 26.7183C5.67167 26.7183 1.36667 22.4133 1.36667 17.1517C1.36667 11.89 5.67167 7.58499 10.9333 7.58499C14.8967 7.58499 18.3133 10.045 19.7487 13.53C19.3387 14.6917 19.1337 15.8533 19.1337 17.1517C19.1337 18.45 19.3387 19.6117 19.7487 20.7733C18.3137 24.2583 14.8971 26.7183 10.9333 26.7183ZM30.0667 26.7183C24.805 26.7183 20.5 22.4133 20.5 17.1517C20.5 11.89 24.805 7.58499 30.0667 7.58499C35.3283 7.58499 39.6333 11.89 39.6333 17.1517C39.6333 22.4133 35.3283 26.7183 30.0667 26.7183Z" fill={color}/>
      <path d="M17.7668 14.4183V13.0516L10.9334 9.63495L4.1001 13.0516V14.4183H5.46676V19.2016H4.78343V20.5683H4.1001V21.9349H17.7668V20.5683H17.0834V19.2016H16.4001V14.4183H17.7668ZM12.3001 19.2016H9.56676V14.4183H12.3001V19.2016ZM6.83343 14.4183H8.2001V19.2016H6.83343V14.4183ZM15.0334 19.2016H13.6668V14.4183H15.0334V19.2016Z" fill={color}/>
      <path d="M34.1666 15.1016V14.4183C34.1666 12.915 32.9366 11.685 31.4332 11.685H30.7499V10.3183H29.3832V11.685H28.6999C27.1966 11.685 25.9666 12.915 25.9666 14.4183V15.1016C25.9666 16.605 27.1966 17.835 28.6999 17.835H29.3832V21.2516H28.6999C27.9482 21.2516 27.3332 20.6366 27.3332 19.885V19.2016H25.9666V19.885C25.9666 21.3883 27.1966 22.6183 28.6999 22.6183H29.3832V23.985H30.7499V22.6183H31.4332C32.9366 22.6183 34.1666 21.3883 34.1666 19.885V19.2016C34.1666 17.6983 32.9366 16.4683 31.4332 16.4683H30.7499V13.0516H31.4332C32.1849 13.0516 32.7999 13.6666 32.7999 14.4183V15.1016H34.1666ZM31.4332 17.835C32.1849 17.835 32.7999 18.45 32.7999 19.2016V19.885C32.7999 20.6366 32.1849 21.2516 31.4332 21.2516H30.7499V17.835H31.4332ZM29.3832 16.4683H28.6999C27.9482 16.4683 27.3332 15.8533 27.3332 15.1016V14.4183C27.3332 13.6666 27.9482 13.0516 28.6999 13.0516H29.3832V16.4683Z" fill={color}/>
      <path d="M31.3647 4.85167L28.9047 0L27.743 1.70833C25.488 0.615 23.028 0.0683333 20.4997 0.0683333C16.263 0.0683333 12.2309 1.64 9.08838 4.44167L9.97671 5.46667C12.915 2.87 16.605 1.435 20.5 1.435C22.755 1.435 25.01 1.91333 26.9917 2.87L25.8984 4.57833L31.3647 4.85167Z" fill={color}/>
      <path d="M31.7755 29.9983L30.8872 28.9733C28.0172 31.5017 24.3272 32.8683 20.5005 32.8683C18.2455 32.8683 15.9905 32.39 14.0088 31.4333L15.1022 29.725L9.6355 29.3833L12.0955 34.235L13.2572 32.5267C15.5122 33.62 18.0405 34.1667 20.5005 34.1667C24.6688 34.235 28.7005 32.7317 31.7759 29.9983H31.7755Z" fill={color}/>
    </svg>
  )
}

export default BankTransferImage
