import { API, graphqlOperation } from "aws-amplify"
import * as Sentry from "@sentry/browser"
import { toast } from "react-toastify"

import {
  updateIdFileUploaded,
  updateAddressFileUploaded,
  updateCardFileUploaded,
  updateLivenessFileUploaded,
} from "../graphql/mutations"

import { trackEvent } from "../helpers/tracker"
import { DefaultValue } from "../components/ui/custom-country-picker"

type UploadFileResponse = {
  user: User | null
  error: string
}

export const updateIdFileFlag = async (
  user: User,
  countrySelect: DefaultValue,
  typeDocument: string,
  kyc: boolean = true
): Promise<UploadFileResponse> => {
  trackEvent("profile:verify-identity")

  try {
    const response = await API.graphql(
      graphqlOperation(updateIdFileUploaded, {
        id: user.id,
        uploaded: true,
        country: {
          name: countrySelect.value,
          code: countrySelect.code,
        },
        typeDocument: typeDocument,
        kyc,
      })
    )

    const updatedUser = response.data.updateIdFileUploaded

    trackEvent("profile:verify-identity:success")

    return {
      user: updatedUser,
      error: "",
    }
  } catch (error) {
    const errorMessage =
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

    trackEvent("profile:verify-identity:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(errorMessage)

    return {
      user: null,
      error: errorMessage,
    }
  }
}

export const updateLivenessFileFlag = async (
  user: User,
  kyc: boolean = true
): Promise<UploadFileResponse> => {
  trackEvent("profile:verify-liveness")

  try {
    const response = await API.graphql(
      graphqlOperation(updateLivenessFileUploaded, {
        id: user.id,
        uploaded: true,
        kyc,
      })
    )

    const updatedUser = response.data.updateLivenessFileUploaded

    trackEvent("profile:verify-liveness:success")

    return {
      user: updatedUser,
      error: "",
    }
  } catch (error) {
    const errorMessage =
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

    trackEvent("profile:verify-liveness:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(errorMessage)

    return {
      user: null,
      error: errorMessage,
    }
  }
}

export const updateAddressFileFlag = async (user: User) => {
  trackEvent("profile:verify-address")

  try {
    const response = await API.graphql(
      graphqlOperation(updateAddressFileUploaded, {
        id: user.id,
        uploaded: true,
      })
    )

    const updatedUser = response.data.updateAddressFileUploaded

    trackEvent("profile:verify-address:success")

    return {
      user: updatedUser,
      error: "",
    }
  } catch (error) {
    const errorMessage =
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

    trackEvent("profile:verify-address:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(errorMessage)

    return {
      user: null,
      error: errorMessage,
    }
  }
}

export const updateCardFileFlag = async (
  user: User,
  kyc: boolean,
  ommit: boolean = false
) => {
  trackEvent("profile:verify-card")

  try {
    const response = await API.graphql(
      graphqlOperation(updateCardFileUploaded, {
        id: user.id,
        uploaded: true,
        kyc,
        ommit,
      })
    )

    const updatedUser = response.data.updateCardFileUploaded

    trackEvent("profile:verify-card:success")

    return {
      user: updatedUser,
      error: "",
    }
  } catch (error) {
    const errorMessage =
      "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

    trackEvent("profile:verify-card:error:generic")
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.captureException(error)
    toast.error(errorMessage)

    return {
      user: null,
      error: "",
    }
  }
}
