import React from "react"
import { Link, Location } from "@reach/router"
import styled from "@emotion/styled"
import CapitariaLogoWhite from "./images/capitaria-logo-white"

import CapitariaLogo from "./images/capitaria-logo-black"
import HeaderItem from "./header-item"
import { useAuth } from "../../hooks/auth-context"
import { getIndexPathFromUser } from "../../helpers/index-path-user"
import Progressbar from "./progressbar"
import listSteps from "../../helpers/list-steps"

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 4rem;
  background-color: #f4f6f8;
  @media (max-width: 812px) {
    padding: 0.25rem 1.375rem;
  }
`
const HeaderWrapperDark = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 4rem;
  background-color: #1f2425;
  @media (max-width: 812px) {
    padding: 0.25rem 1.375rem;
  }
`

const ItemList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

type Props = {
  showLinks: boolean
}

const calculateWidth = (nextStep: string) => {
  return 100 / listSteps.length * (listSteps.indexOf(nextStep) + 1)
}

const Header = ({ showLinks }: Props) => {
  const { state } = useAuth()
  const user = state.user as User

  const indexPath = getIndexPathFromUser(user)

  return (
    <Location>
      {({ location }) => (
        <>
          {user.kycNextStep === "home" ? (
            <HeaderWrapperDark className="navbar">
              <Link to={indexPath}>
                <CapitariaLogoWhite />
              </Link>

              {showLinks && (
                <ItemList>
                  <HeaderItem
                    location={location}
                    path="/logout"
                    text="Salir"
                    iconOff=""
                    iconOn=""
                  />
                </ItemList>
              )}
            </HeaderWrapperDark>
          ) : (
            <>
              {user.kycNextStep !== "home" && <Progressbar width={calculateWidth(user.kycNextStep)} />}
              <HeaderWrapper className="navbar">
                <Link to={indexPath}>
                  <CapitariaLogo />
                </Link>

                {showLinks && (
                  <ItemList>
                    <HeaderItem
                      location={location}
                      path="/logout"
                      text="Salir"
                      iconOff=""
                      iconOn=""
                    />
                  </ItemList>
                )}
              </HeaderWrapper>
            </>
          )}
        </>
      )}
    </Location>
  )
}

export default Header
