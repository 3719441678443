import React from "react"
import styled from "styled-components"
import BankBox from "./bank-box"

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 814px) {
    overflow: hidden;
  }
`

const Slides = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  text-align: center;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  @media (max-width: 1275px) {
    justify-content: normal;
  }
  @media (min-width: 814px) {
    width: 100%;
    min-height: 150px;
    max-height: 300px;
  }
  @media (max-width: 814px) {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    width: 318px;
    height: 110px;
  }
`

const CarouselOptionsBanks = ({ listBanks, openModal }: any) => {

  return (
    <Slider>
      <Slides>
        {listBanks.map((bank: any) => (
          <BankBox
            isSolesAndDollarAccount={bank.isSolesAndDollarAccount}
            isDollarAccount={bank.isDollarAccount}
            extraText={bank.extraText}
            onClick={openModal}
            account={bank.account}
          >
            {bank.text ? bank.text : null}
            {bank.image}
          </BankBox>
        ))}
      </Slides>
    </Slider>
  )
}

export default CarouselOptionsBanks
