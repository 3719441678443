import React, { CSSProperties } from "react"
import styled from "@emotion/styled"

const addTextCSS: CSSProperties = {
  flexDirection: "column",
  paddingBottom: "10px",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "120%",
  letterSpacing: "0.1px",
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1rem 0 0;
  margin: 0rem 5px;
  width: 217px;
  height: 110px;
  cursor: pointer;
`

const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 198px;
`

const AccountType = styled.div`
  font-family: "Open Sans";
  font-size: 0.625em;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  background-color: #1f2425;
  padding: 0.25rem;
  color: #ffffff;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

const getAccountTypeLabel = (
  isDollarAccount: boolean,
  isSolesAndDollarAccount: boolean
) => {
  let label = <AccountType style={{ visibility: "hidden" }}>Cuenta</AccountType>

  if (isDollarAccount) {
    label = <AccountType>Cuenta en dólares</AccountType>
  }

  if (isSolesAndDollarAccount) {
    label = <AccountType>Cuenta en dólares y soles</AccountType>
  }

  return label
}

type Props = {
  children: any
  isDollarAccount?: boolean
  isSolesAndDollarAccount?: boolean
  onClick: (selectedAccount: BankAccount, logo: any) => void
  account: BankAccount
  extraText?: boolean
}

const BankBox = ({
  children,
  isDollarAccount = false,
  isSolesAndDollarAccount = false,
  onClick,
  account,
  extraText = false,
}: Props) => (
  <Box onClick={() => onClick(account, children)} tabIndex={0}>
    <BoxContent style={extraText ? addTextCSS : undefined} >{children}</BoxContent>
    {getAccountTypeLabel(isDollarAccount, isSolesAndDollarAccount)}
  </Box>
)

export default BankBox
