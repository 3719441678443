import React from "react"
import styled from "@emotion/styled"

import uploadCheck from "../../../images/upload-check.svg"

const UploadCheck = styled.div`
  background-image: url(${uploadCheck});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 21px;
`

const UploadCheckIcon = () => <UploadCheck />

export default UploadCheckIcon
