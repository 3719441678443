import React from "react"
import styled from "@emotion/styled"

import successCardUpload from "../../../images/success-card-upload.png"

const SuccessCardUpload = styled.img`
  width: 178px;
  height: 159px;
`

const SuccessCardUploadImg = () => (
  <SuccessCardUpload
    src={successCardUpload}
    alt="¡GRACIAS! YA TENEMOS LA INFORMACIÓN NECESARIA PARA CONFIRMAR TU CUENTA Y AGILIZAR TUS RETIROS"
  />
)

export default SuccessCardUploadImg
