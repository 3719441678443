import React from "react"
import { Link } from "@reach/router"
import styled from "@emotion/styled"

import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

import isotypeBg from "../../images/isotype-bg.png"
import BackArrowIcon from "../ui/images/back-arrow-icon"
import CapitariaLogoBlack from "../ui/images/capitaria-logo-black"

import Disclaimer from "../disclaimer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-position: right;
  background-size: 450px 100%;
  background-image: url(${isotypeBg});
  background-repeat: no-repeat;
  @media (max-width: 812px) {
    background: transparent;
    padding-bottom: 2rem;
  }
`

const NavBar = styled.nav`
  padding-top: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 812px) {
    justify-content: space-between;
    padding: 2.55rem 1.25rem 0;
  }
`

const Content = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 812px) {
    padding: 4rem 0 7rem;
  }
`

const LogoWrapper = styled.div`
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 13.33px;
  font-weight: 600;
  text-decoration: none;
  line-height: 140%;
  color: #1f2425;
  padding: 0 2rem;
  @media (max-width: 812px) {
    padding: 0;
  }
`

const LinkText = styled.span`
  padding-left: 0.5rem;
`

type Props = {
  children: React.ReactNode
}

const ForgotPasswordLayout = ({ children }: Props) => {
  const queryStringParams = useQueryString()

  return (
    <Container>
      <NavBar>
        <LogoWrapper>
          <CapitariaLogoBlack />
        </LogoWrapper>

        <StyledLink
          to={`/login${queryStringParams.queryString}`}
          onClick={() => {
            trackEvent("forgot-password:link-to:login")
          }}
        >
          <BackArrowIcon /> <LinkText>Volver</LinkText>
        </StyledLink>
      </NavBar>
      <Content>{children}</Content>
      <Disclaimer />
    </Container>
  )
}

export default ForgotPasswordLayout
