import React, { useEffect, useState } from "react"

type Props = {
  active?: boolean
}

const CreditCardImage = ({ active=false }: Props) => {
  const [color, setColor] = useState("#919EAB")
  const [colorLine, setColorLine] = useState("#FFFFFF")

  useEffect(() => {
    if (active) {
      setColor("#2BA770")
      setColorLine("#A4F8D5")
    } else {
      setColor("#919EAB")
      setColorLine("#FFFFFF")
    }

  }, [active])
  return (
    <svg width="37" height="20" viewBox="0 0 37 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="37" height="20" rx="2" fill={color}/>
      <rect y="4" width="37" height="2" fill={colorLine}/>
      <rect x="26" y="10" width="8" height="2" fill={colorLine}/>
    </svg>
  )
}

export default CreditCardImage
