import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { API, graphqlOperation } from "aws-amplify"
import { getKnowledgeProfileQuestions } from "../../graphql/queries"

import { trackEvent } from "../../helpers/tracker"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import CheckKycNextStep from "./check-kyc-step"
import Questions from "./questions"


const KnowledgeProfile = () => {
  const { state } = useAuth()
  const { user, setUser } = state

  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(getKnowledgeProfileQuestions, {})
        )
        const knowledgeProfileQuestions = JSON.parse(
          response.data.getKnowledgeProfileQuestions
        )

        setQuestions(knowledgeProfileQuestions)
      } catch (error) {
        trackEvent("kyc:knowledge-profile:error:loading-questions")
        Sentry.setExtra("error", JSON.stringify(error))
        Sentry.captureException(error)
        toast.error(
          "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
        )
      }
    }

    getQuestions()
  }, [])

  const onSubmit = async (values: any) => {
    setIsLoading(true)

    const result = await KYC.updateKnowledgeProfile(user as User, values)

    setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <CheckKycNextStep>
      <Questions
        questions={questions}
        onSubmit={onSubmit}
        isLoading={isLoading}
        title="Revisemos tu conocimiento"
      />

    </CheckKycNextStep>
  )
}

export default KnowledgeProfile
