import React, { useEffect, useState } from "react"
import { fetchCountry } from "@capitaria/capitaria-utms"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { navigate } from "@reach/router"
import { Formik } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import { trackEventMetamap } from "../../helpers/tracker"
import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"
import CustomPhoneInput from "../ui/custom-phone-input"
import WarningIcon from "../ui/images/warning-icon"
import OtpInput from "../ui/opt-input"
import IconComplete from "./images/complete"
import CustomButtonV2 from "../ui/custom-button-v2"

const styleCss = css`
  .input-telephone {
    width: 335px;
    @media (max-width: 450px) {
      width: 300px;
    }
  }

  div.react-tel-input input.tel-input {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    height: 48px;
    width: 265px;
    @media (max-width: 450px) {
      width: 230px;
    }
  }
`

const Paragraph = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-align: center;
  margin: 12px 0px 30px;
  @media (max-width: 425px) {
    max-width: 284px;
  }
`

const Form = styled.form`
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;

  .selected-flag {
    width: 62px;
    padding: 0;
    .flag {
      zoom: 1.25;
      right: 50%;
      margin-right: -5px;
      .arrow {
        border-left-width: 4px;
        border-right-width: 4px;
        border-top-width: 7px;
        border-top-color: #000000;
      }
    }
  }
`

const ContainerStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10% auto;
  width: 90%;
`

const buttonStyle = {
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  background: "#2BA770",
  letterSpacing: "0.1px",
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.06), 0px 1px 18px rgba(0, 0, 0, 0.04), 0px 3px 5px rgba(0, 0, 0, 0.08)",
  width: "300px",
}

const buttonStyleLoading = {
  width: "300px",
}



const ResendLink = styled.a`
  font-size: 13px;
  line-height: 140%;
  font-weight: bold;
  cursor: pointer;
  width: 300px;
  height: 45px;
  text-align: center;
  border: 1px solid #2BA770;
  background-color: #fff;
  border-radius: 6px;
  color: #2BA770;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: #a9a9a9;
    cursor: not-allowed;
    text-decoration: none;
    background-color: transparent;
    border: none;
  }
`

const ContainerValidatePhone = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 300px;
  margin: 60px auto;
`
const TextValidationStatus = styled.div`
  margin: 3% 0;
`
const Title = styled.h1`
  max-width: 300px;
  color: #1F2425;
  text-align: center;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  @media (max-width: 435px) {
    max-width: 205px;
  }
`

const ContentInputsCodeConfirmPhone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 189px;
  margin-bottom: 5%;

  & > * {
    width: 42px;
    min-height: 43px;
    flex-shrink: 0;
    border-radius: 5px;
  }
`

const BasicTextGray = styled.p`
  color: #363F41;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: none !important ;
`
const BoldText = styled.strong`
  color: #363F41;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration-line: underline;
`

const ContainerReasons = styled.div`
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  @media (max-width: 425px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 60%;
  }
  @media (min-width: 1024px) {
    max-width: 30%;
  }
`

const Reason = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto;
  text-align: center;
`

const WhyQuestion = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  font-weight: 600;
  font-family: Roboto;
`

const schemaValidationPhone = Yup.object().shape({
  phone: Yup.string()
    .required("Este campo es requerido")
    .matches(
      /^[+]*(\d{1,4})?[-\s\\./0-9]*$/,
      "El número de teléfono solo puede incluir números, -, +, () y espacios"
    )
    .min(
      11,
      `El número de teléfono es muy corto`
    ),
})

type EditPhoneProps = {
  user: User | null
  onSubmit: (values: { phone: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

const ReasonExplain = () => (
  <ContainerReasons>
    <WhyQuestion>
      ¿Porque necesitamos validar tu teléfono?
    </WhyQuestion>
    <Reason>
      Tu teléfono es la forma más rápida de contactarnos contigo para ayudarte en todo momento y es parte fundamental para validar tu información personal.
    </Reason>
  </ContainerReasons>
)

const EditPhone = ({
  user,
  onSubmit,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: EditPhoneProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [countryValue, setCountryValue] = useState("cl")

  useEffect(() => {
    const getCountryValue = async () => {
      const countryCode = await fetchCountry(true)
      const countryCodeLow = countryCode.countryCode.toLowerCase()

      if (countryCodeLow !== countryValue) {
        setCountryValue(countryCodeLow)
      }
    }
    getCountryValue()
  }, [countryValue])

  const savePhone = async (values: any) => {
    setIsLoading(true)
    trackEventMetamap('phone-confirmed')
    await onSubmit(values)
    setIsLoading(false)
  }

  return (
    <ContainerStep>
      <Title>Verifiquemos tu Teléfono</Title>
      <Paragraph>
        Para continuar con la creación de tu cuenta es necesario que validemos tu teléfono para esto, enviaremos un código a tu teléfono mediante SMS
      </Paragraph>
      <Formik
        enableReinitialize
        initialValues={{
          phone: `+${user && user.phone ? user.phone : ""}`,
        }}
        validationSchema={schemaValidationPhone}
        onSubmit={savePhone}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <CustomPhoneInput
                country={countryValue}
                name="phone"
                label="Teléfono"
                preferredCountries={["cl", "pe", "uy", "mx"]}
                localization="es"
                enableSearch
                searchPlaceholder="Buscar país"
                searchNotFound="No se ha encontrado el país buscado"
                dropdownClass="country-list"
                inputClass="tel-input"
                containerClass="react-tel-input"
                inputProps={{
                  name: "phone",
                  id: "phone",
                  autoFocus: false,
                }}
                className="input-telephone"
                onBlur={handleBlur}
                errors={errors.phone}
                hasError={errors.phone && touched.phone}
                value={values.phone}
                errorMessage={errors.phone}
                onChange={(e: any) => {
                  handleChange(e)
                  setFieldValue("phone", e)
                }}
              />
              <CustomButtonV2
                type="submit"
                style={buttonStyle}
                loading={isLoading}
                loadingStyle={buttonStyleLoading}
              >
                Confirmar Teléfono
              </CustomButtonV2>
              {notCode && (
                <ResendLink onClick={submitNotCode}>
                  {!isLoadingNotCode
                    ? "No he recibido el código"
                    : "Un minuto por favor..."}
                </ResendLink>
              )}
            </Form>
          )
        }}
      </Formik>
      <ReasonExplain />
    </ContainerStep>
  )
}

type ConfirmCodeProps = {
  phoneState: string
  seconds: number
  isWaiting: boolean
  onClickResendCode: () => void
  onSendCode: (values: { code: string }) => Promise<void>
  notCode: boolean
  isLoadingNotCode: boolean
  submitNotCode: () => Promise<void>
}

const labelCSS = {
  fontSize: "18px",
  width: "100%",
  textAlign: "center",
  marginBottom: "13px",
}

const ConfirmCode = ({
  phoneState,
  seconds,
  isWaiting,
  onClickResendCode,
  onSendCode,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: ConfirmCodeProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const sendCode = async (values: any) => {
    setIsLoading(true)
    trackEventMetamap('sending-verification-code')
    await onSendCode(values)
    setIsLoading(false)
  }

  return (
    <ContainerStep>
      <Title>Verifiquemos tu Teléfono</Title>
      <Paragraph>
        Hemos enviado tu código via SMS al teléfono {phoneState}
      </Paragraph>
      <Formik
        enableReinitialize
        initialValues={{
          code: "",
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required("Este campo es requerido"),
        })}
        onSubmit={sendCode}
      >
        {({
          values,
          setFieldValue,
          touched,
          errors,
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ContentInputsCodeConfirmPhone>
                <OtpInput
                  name="code"
                  label="Ingresa el código enviado"
                  styleLabel={labelCSS}
                  value={values.code}
                  valueLength={4}
                  onChange={(value) => { setFieldValue("code", value) }}
                  hasError={errors.code && touched.code}
                  errorMessage={errors.code}
                />
              </ContentInputsCodeConfirmPhone>
              <CustomButtonV2
                type="submit"
                style={buttonStyle}
                loading={isLoading}
                loadingStyle={buttonStyleLoading}
              >
                Confirmar Código
              </CustomButtonV2>
              <br />
              {!notCode && !isLoading && (
                <ResendLink className={isWaiting ? "disabled" : ""} onClick={onClickResendCode}>
                  {!isWaiting ? (
                    <>
                      <BasicTextGray>¿No has recibido ningún código?</BasicTextGray>
                      <BoldText>¡Inténtalo de nuevo!</BoldText>
                    </>
                  ) : (
                    <>Puedes volver a enviar tu código en {seconds} segundo
                      {
                        seconds !== 1 ? "s" : ""
                      }
                    </>
                  )}
                </ResendLink>
              )}
              {notCode && (
                <ResendLink onClick={submitNotCode}>
                  {!isLoadingNotCode
                    ? "No he recibido el código"
                    : "Un minuto por favor..."}
                </ResendLink>
              )}
            </Form>
          )
        }
        }
      </Formik>
      <ReasonExplain />
    </ContainerStep>
  )
}

type ConfirmPhoneProps = EditPhoneProps & ConfirmCodeProps & {
  updatePhone: boolean
}

const ConfirmPhone = ({
  user,
  updatePhone,
  onSubmit,
  phoneState,
  seconds,
  isWaiting,
  onClickResendCode,
  onSendCode,
  notCode,
  isLoadingNotCode,
  submitNotCode,
}: ConfirmPhoneProps) => {
  return (
    <>
      {
        updatePhone ?
          <ConfirmCode
            phoneState={phoneState}
            seconds={seconds}
            isWaiting={isWaiting}
            onClickResendCode={onClickResendCode}
            onSendCode={onSendCode}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          /> :
          <EditPhone
            user={user}
            onSubmit={onSubmit}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          />
      }
    </>
  )
}

type StateValidatePhoneProps = {
  confirmPhone?: boolean
}

const StateValidatePhone = ({ confirmPhone }: StateValidatePhoneProps) => (
  <ContainerValidatePhone>
    <Title>Numero telefónico</Title>
    <div>{confirmPhone ? <IconComplete /> : <WarningIcon />}</div>
    <TextValidationStatus>
      Numero telefónico {confirmPhone ? '' : 'no'} validado
    </TextValidationStatus>
    {
      !confirmPhone &&
      <div>Lo validaremos más adelante, por ahora continúa con el resto de las validaciones</div>
    }
    <CustomButtonV2
      style={buttonStyle}
      loadingStyle={buttonStyleLoading}
      onClick={() => navigate('/home')}
    >
      Volver
    </CustomButtonV2>
  </ContainerValidatePhone>
)

const TIMER = 60


type Props = {
  kyc: boolean
}

const ValidatePhone = ({ kyc }: Props) => {
  const { state } = useAuth()
  const { user, setUser } = state

  const [updatePhone, setUpdatePhone] = useState(false)
  const [phoneState, setPhoneState] = useState("")

  const [seconds, setSeconds] = useState(TIMER)
  const [isWaiting, setIsWaiting] = useState(false)

  const [notCode, setNotCode] = useState(false)
  const [isLoadingNotCode, setIsLoadingNotCode] = useState(false)

  useEffect(() => {
    let interval: any = null

    if (isWaiting) {
      if (seconds === 0) {
        clearInterval(interval)
        setIsWaiting(false)
        setSeconds(TIMER)
      } else {
        interval = setInterval(() => setSeconds(seconds => seconds - 1), 1000)
      }
    }
    return () => clearInterval(interval)
  }, [isWaiting, seconds])

  const onClickResendCode = () => {
    if (!isWaiting) {
      setIsWaiting(true)
      trackEventMetamap('resend code validation phone number')
      onSubmit({ phone: phoneState })
    }
  }

  const onSubmit = async (values: { phone: string }) => {
    const phone: string = values.phone.replace('+', '')
    const result = await KYC.updatePhone(user as User, phone, kyc)
    setPhoneState(values.phone)
    if (result) {
      setUser({
        ...(user as User),
        phone,
      })
      setUpdatePhone(true)
    } else {
      toast.error(
        "No se puede volver a enviar el código"
      )
      setNotCode(true)
    }
  }

  const onSendCode = async (values: { code: string }) => {
    try {
      const result = await KYC.confirmCode(user as User, values.code, kyc)
      console.log(result.confirmCode)
      if (result.wasPhoneValidate) {
        setUser({
          ...(user as User),
          confirmPhone: result.confirmCode,
          wasPhoneValidate: result.wasPhoneValidate,
          kycNextStep: result.kycNextStep,
        })
        if (kyc) {
          navigate(`/${result.kycNextStep}`)
        }
      }

    } catch (error) {
      console.log(error)
    }

  }

  const submitNotCode = async () => {
    if (!isLoadingNotCode) {
      setIsLoadingNotCode(true)
      trackEventMetamap('code-not-received')
      const result = await KYC.notReceivedCode(user as User, kyc)

      setUser(result.user)
      if (kyc) {
        navigate(`/${result.user.kycNextStep}`)
      }
      setIsLoadingNotCode(false)
    }
  }

  return (
    <>
      <Global styles={styleCss} />
      {
        user && user.wasPhoneValidate ?
          <StateValidatePhone confirmPhone={user.confirmPhone} /> :
          <ConfirmPhone
            user={user}
            updatePhone={updatePhone}
            onSubmit={onSubmit}
            phoneState={phoneState}
            seconds={seconds}
            isWaiting={isWaiting}
            onClickResendCode={onClickResendCode}
            onSendCode={onSendCode}
            notCode={notCode}
            isLoadingNotCode={isLoadingNotCode}
            submitNotCode={submitNotCode}
          />

      }
    </>
  )
}

export default ValidatePhone
