import React from "react"
import styled from "@emotion/styled"

import xIcon from "../../../images/x.png"

const X = styled.img`
  width: 14px;
  height: 14px;
`

const XIcon = () => <X src={xIcon} alt="skip" />

export default XIcon
