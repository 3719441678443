import React from "react"
import styled from "@emotion/styled"

import { formatAmount } from "../../helpers/currency"

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Content = styled.div`
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  width: 560px;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 50px;
  @media (max-width: 560px) {
    width: 100%;
    max-width: 560px;
    padding: 1rem 0;
  }
`

const Title = styled.h4`
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #1f2425;
  margin: 0.25rem 0 1.5rem;
`

const Table = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  &:first-of-type {
    font-weight: 600;
    align-items: center;
    @media (max-width: 560px) {
      display: none;
    }
  }
  @media (max-width: 560px) {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  @media (min-width: 561px) {
    &:nth-of-type(odd) {
      background-color: #e5e5e5;
    }
  }
`

const Cell = styled.div`
  text-align: center;
  padding: 5px 0;
  @media (max-width: 560px) {
    &:nth-of-type(odd) {
      background-color: #e5e5e5;
    }
  }
`

const DateText = styled(Cell)`
  min-width: 100px;
`
const Currency = styled(Cell)`
  min-width: 70px;
`
const SubAccount = styled(Cell)`
  min-width: 130px;
`
const Amount = styled(Cell)`
  min-width: 130px;
`
const Type = styled(Cell)`
  min-width: 100px;
`

const TextMobile = styled.div`
  display: none;
  font-size: 10px;
  font-weight: 600;
  @media (max-width: 560px) {
    display: block;
  }
`

const sortTransactions = (
  { date: dateA }: Transaction,
  { date: dateB }: Transaction
) => {
  const [dayA, monthA, yearA] = dateA.split("-")
  const [dayB, monthB, yearB] = dateB.split("-")
  const timeB: Date = new Date(`${yearB}-${monthB}-${dayB}`)
  const timeA: Date = new Date(`${yearA}-${monthA}-${dayA}`)

  return timeB.getTime() - timeA.getTime()
}

const TransactionList = ({ transactionsList }: TransactionsList) => {
  transactionsList.sort(sortTransactions)

  return (
    <Container>
      <Content>
        <Title>Transacciones</Title>
        <Table>
          <Row>
            <DateText>Fecha</DateText>
            <SubAccount>Subcuenta</SubAccount>
            <Currency>Moneda</Currency>
            <Type>Tipo</Type>
            <Amount>Monto</Amount>
          </Row>
          {transactionsList.map((transaction: Transaction, idx: number) => (
            <Row key={`idx-row-transaction-${idx}`}>
              <DateText>
                {transaction.date}
                <TextMobile>Fecha</TextMobile>
              </DateText>
              <SubAccount>
                {transaction.subaccount}
                <TextMobile>Subcuenta</TextMobile>
              </SubAccount>
              <Currency>
                {transaction.currency}
                <TextMobile>Moneda</TextMobile>
              </Currency>
              <Type>
                {transaction.transaction_type}
                <TextMobile>Tipo</TextMobile>
              </Type>
              <Amount>
                {formatAmount(
                  transaction.amount,
                  ".",
                  ",",
                  transaction.currency === "CLP"
                )}
                <TextMobile>Monto</TextMobile>
              </Amount>
            </Row>
          ))}
        </Table>
      </Content>
    </Container>
  )
}

export default TransactionList
