import React, { useState } from "react"
import styled from "@emotion/styled"

import LoadingScreenIcon from "../ui/images/loading-screen"
import Question from "./question"
import CustomButtonV2 from "../ui/custom-button-v2"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  @media (max-width: 812px) {
    padding: 0 1.5rem 3rem;
  }
`

const QuestionGroup = styled.section`
  max-width: 700px;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const buttonStyle = {
  margin: "2rem 0 5rem",
  width: "222px",
}
const Title = styled.h3`
  width: 60%;
  color: #1F2425;

  text-align: center;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
`

const loadingButtonStyle = buttonStyle
const disabledButtonStyle = buttonStyle

type Value = {
  [key: string]: string[]
}

type Props = {
  title: string,
  questions: {
    id: string
    query: string
    type: string
    description?: string
    answers: Answer[]
  }[]
  isLoading: boolean
  onSubmit?: (values: Value) => void
}

const Questions = ({ title, questions, isLoading, onSubmit }: Props) => {
  const [values, setValues] = useState({} as Value)

  const updateMultiAnswer = (answerId: string, value?: boolean) => {
    const answerSplittedId = answerId.split("-")
    const questionId = `${answerSplittedId[0]}-${answerSplittedId[1]}`
    const answeredQuestion = questions.find(q => q.id === questionId)
    let newAnswer = [answerId]
    let removeQuestion = false

    if (
      answeredQuestion?.type === "multi-checkbox" &&
      Array.isArray(values[questionId])
    ) {
      const answersIds = values[questionId] as string[]
      const filtered = answersIds.filter(id => id !== answerId)

      if (value) {
        newAnswer = [...filtered, answerId]
      } else {
        if (filtered.length === 0) {
          removeQuestion = true
        } else {
          newAnswer = [...filtered]
        }
      }
    }
    return { newAnswer, questionId, removeQuestion }
  }

  const multiSelectedOptionsChange = (options: any[]) => {
    if (options.length > 0) {
      const firstAnswer = options[0].answerId
      const answerSplittedId = firstAnswer.split("-")
      const questionId = `${answerSplittedId[0]}-${answerSplittedId[1]}`

      if (options.every(answer => answer.value)) {
        const listAnswerIds: string[] = options.map(answer => answer.answerId)
        setValues({ ...values, [questionId]: listAnswerIds })
      }
      else {
        const listAnswerIds: string[] = options.filter(answer => answer.value).map(answer => answer.answerId)
        if (listAnswerIds.length === 0) {
          delete values[questionId]
          setValues({ ...values })
        }
        else {
          setValues({ ...values, [questionId]: listAnswerIds })
        }
      }
    }
  }

  const updateAnswer = (answerId: string, value?: boolean) => {
    const { newAnswer, questionId, removeQuestion } = updateMultiAnswer(answerId, value)
    if (removeQuestion) {
      delete values[questionId]
      setValues({ ...values })
    } else {
      setValues({ ...values, [questionId]: newAnswer })
    }
  }

  const onClick = () => {
    if (onSubmit) {
      onSubmit(values)
    }
  }

  if (questions.length === 0) {
    return (
      <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>
    )
  }

  return (
    <Container>
      <Title>
        {title}
      </Title>
      <QuestionGroup>
        {questions.map((q, idx) => (
          <Question
            key={`query-${idx}`}
            name={`query-${idx}`}
            query={q.query}
            type={q.type}
            description={q.description}
            answers={q.answers}
            onClick={updateAnswer}
            multiSelectedOptionsChange={multiSelectedOptionsChange}
          />
        ))}
      </QuestionGroup>

      <CustomButtonV2
        style={buttonStyle}
        loading={isLoading}
        loadingStyle={loadingButtonStyle}
        disabledStyle={disabledButtonStyle}
        disabled={questions.length !== Object.keys(values).length}
        onClick={onClick}
      >
        CONTINUAR
      </CustomButtonV2>
    </Container>
  )
}

export default Questions
