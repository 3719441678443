const operations: Option[] = [
  {
    value: "ask",
    text: "Comprar"
  },
  {
    value: "bid",
    text: "Vender"
  },
]

export default operations
