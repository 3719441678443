import React from "react"
import styled from "@emotion/styled"

import errorIcon from "../../../images/error-icon.svg"

const Error = styled.div`
  background-image: url(${errorIcon});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 7px;
`

const ErrorIcon = () => <Error />

export default ErrorIcon
