import React from "react"
import styled from "@emotion/styled"

import PageLayout from "../page-layout"

const Container = styled.div`
  display: flex;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
`

const Title = styled.h1`
  font-family: "Open Sans";
  font-size: 1.75em;
  margin: 0;
  color: #1f2425;
  @media (max-width: 812px) {
    font-weight: bold;
    font-size: 24px;
    line-height: 116%;
  }
`

const Description = styled.p`
  font-family: Nunito;
  line-height: 140%;
  font-size: 1em;
  color: #1f2425;
  margin: 0.5rem 0 0;
  text-align: center;
  max-width: 544px;
  @media (max-width: 812px) {
    font-size: 18px;
    line-height: 120%;
    max-width: 314px;
  }
`

type Props = {
  title?: string
  description?: string
  children: any
}

const KycLayout = ({ title, description, children }: Props) => {
  return (
    <PageLayout>
      <Container>
        <Content>
          <TitleWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </TitleWrapper>
          {children}
        </Content>
      </Container>
    </PageLayout>
  )
}

export default KycLayout
