import React from "react"
import styled from "@emotion/styled"
import { trackEvent } from "../../helpers/tracker"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const LinkZendesk = styled.a`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 14px 22px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-decoration: none;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`

const Title = styled.div`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 25px;
  line-height: 120.4%;
  color: #1f2425;
  text-align: center;
  max-width: 610px;
  margin-bottom: 40px;
`

const Description = styled.p`
  font-size: 16px;
  line-height: 140%;
  max-width: 550px;
  text-align: center;
`

const Help = () => (
  <Container>
    <Content>
      <Title>
        Si tienes alguna duda, puedes ir a nuestra sección de preguntas
        frecuentes
      </Title>
      <Description>
        Todo lo que hacemos en Capitaria es para que tengas la mejor experiencia
        posible en el Trading. En esta sección encontrarás las preguntas más
        recurrentes de nuestros Traders.
      </Description>

      <LinkZendesk
        onClick={() => trackEvent("help:link:zendesk")}
        target="_blank"
        href="https://capitaria.zendesk.com/hc/es/categories/360003147732-Preguntas-Frecuentes"
      >
        PREGUNTAS FRECUENTES
      </LinkZendesk>
    </Content>
  </Container>
)

export default Help
