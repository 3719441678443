import React from "react"
import { Link } from "@reach/router"
import styled from "@emotion/styled"
import { Formik } from "formik"
import * as Yup from "yup"

import { useQueryString } from "@capitaria/capitaria-utms"

import Disclaimer from "../disclaimer"
import CustomInput from "../ui/custom-form-input"
import CustomButton from "../ui/custom-button"

import isotypeBg from "../../images/isotype-bg.png"
import CapitariaLogoBlack from "../ui/images/capitaria-logo-black"
import { trackEvent } from "../../helpers/tracker"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-position: right;
  background-size: 450px 100%;
  background-image: url(${isotypeBg});
  background-repeat: no-repeat;
  @media (max-width: 812px) {
    background: transparent;
    padding-bottom: 2rem;
  }
`

const Content = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 812px) {
    padding: 4rem 0 7rem;
  }
`

const Description = styled.p`
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
  margin: 0;
  text-align: center;
  @media (max-width: 812px) {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    max-width: 166px;
    margin-top: 1rem;
  }
`

const Form = styled.form`
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NavBar = styled.nav`
  padding-top: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 812px) {
    justify-content: space-between;
    padding: 2rem 1.25rem 0;
  }
`

const LogoWrapper = styled.div`
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
`

const CreateAccountMsg = styled.span`
  font-weight: 600;
  line-height: 140%;
  font-size: 0.875em;
  color: #1f2425;
  @media (max-width: 812px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  margin: 0 2rem;
  padding: 12px 24px;
  color: #ffffff;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  @media (max-width: 812px) {
    padding: 12px;
    margin: 0;
  }
`

const ForgotPassordLink = styled(Link)`
  font-size: 12px;
  line-height: 140%;
  color: #505050;
  text-decoration: none;
`

const buttonCSS = {
  marginTop: "1rem",
  height: "48px",
  width: "100%",
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const loadingButtonCss = { ...buttonCSS }

const inputCSS = {
  width: "350px",
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const Error = styled.p`
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  @media (max-width: 812px) {
    max-width: 300px;
  }
`

const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 27.65px;
  line-height: 116%;
  margin: 0;
  color: #1f2425;
  @media (max-width: 812px) {
    text-align: center;
    max-width: 166px;
  }
`

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("El email ingresado no es válido")
    .required("Este campo es requerido"),
  password: Yup.string().required("Este campo es requerido"),
})

type Props = {
  loading: boolean
  onSubmit: (name: string, password: string) => void
  submitError: string
}

const LoginContent = ({ loading, onSubmit, submitError }: Props) => {
  const queryStringParams = useQueryString()

  return (
    <Container>
      <NavBar>
        <LogoWrapper>
          <CapitariaLogoBlack />
        </LogoWrapper>

        <CreateAccountMsg>¿No tienes una cuenta?</CreateAccountMsg>
        <StyledLink
          to={`/sign-up${queryStringParams.queryString}`}
          onClick={() => {
            trackEvent("login:link-to:sign-up")
          }}
        >
          Crea tu cuenta
        </StyledLink>
      </NavBar>
      <Content>
        <Title>Bienvenido a Capitaria</Title>
        <Description>Ingresa tus datos para entrar a tu perfil</Description>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={values => {
            onSubmit(values.email, values.password)
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props
            return (
              <Form onSubmit={handleSubmit}>
                <CustomInput
                  name="email"
                  label="Email"
                  style={inputCSS}
                  type="email"
                  tabIndex={1}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={errors.email && touched.email}
                  errorMessage={errors.email}
                />

                <CustomInput
                  name="password"
                  label="Contraseña"
                  sublabel={
                    <ForgotPassordLink
                      to={`/forgot-password${queryStringParams.queryString}`}
                      onClick={() => {
                        trackEvent("login:link-to:forgot-password")
                      }}
                    >
                      Olvidé mi contraseña
                    </ForgotPassordLink>
                  }
                  type="password"
                  style={inputCSS}
                  tabIndex={2}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={errors.password && touched.password}
                  errorMessage={errors.password}
                />

                <CustomButton
                  style={buttonCSS}
                  loading={loading}
                  type="submit"
                  loadingStyle={loadingButtonCss}
                >
                  ENTRAR
                </CustomButton>

                {submitError.length > 0 && <Error>{submitError}</Error>}
              </Form>
            )
          }}
        </Formik>
      </Content>
      <Disclaimer />
    </Container>
  )
}

export default LoginContent
