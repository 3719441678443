/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInstrumentsPrice = /* GraphQL */ `
  query GetInstrumentsPrice(
    $nemo: String!
    $date: String
    $start: String
    $end: String
  ) {
    getInstrumentsPrice(nemo: $nemo, date: $date, start: $start, end: $end)
  }
`;
export const getOpportunities = /* GraphQL */ `
  query GetOpportunities($quantity: String) {
    getOpportunities(quantity: $quantity)
  }
`;
export const info = /* GraphQL */ `
  query Info($cognitoId: ID!) {
    info(cognitoId: $cognitoId) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const getSubaccountsAndTransactions = /* GraphQL */ `
  query GetSubaccountsAndTransactions($cognitoId: ID!) {
    getSubaccountsAndTransactions(cognitoId: $cognitoId)
  }
`;
export const getUserBroker = /* GraphQL */ `
  query GetUserBroker($cognitoId: ID!) {
    getUserBroker(cognitoId: $cognitoId)
  }
`;
export const getTokenNuvei = /* GraphQL */ `
  query GetTokenNuvei(
    $cognitoId: ID!
    $amount: String!
    $currency: String!
    $country: String!
  ) {
    getTokenNuvei(
      cognitoId: $cognitoId
      amount: $amount
      currency: $currency
      country: $country
    )
  }
`;
export const getUserExecutivesTeam = /* GraphQL */ `
  query GetUserExecutivesTeam($cognitoId: ID!) {
    getUserExecutivesTeam(cognitoId: $cognitoId)
  }
`;
export const getRiskProfileQuestions = /* GraphQL */ `
  query GetRiskProfileQuestions {
    getRiskProfileQuestions
  }
`;
export const getKnowledgeProfileQuestions = /* GraphQL */ `
  query GetKnowledgeProfileQuestions {
    getKnowledgeProfileQuestions
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      identityId
      salesforceId
      givenName
      familyName
      email
      phone
      birth
      dni
      signUpCountry
      originCountry
      residenceCountry
      city
      address
      job
      liveInUSA
      isPEP
      riskProfile
      riskPoints
      knowledgeProfile
      knowledgePoints
      userProfileSignature
      isKycApproved
      acceptTerms
      userAcceptTermsSignature
      customerStatus
      customerType
      customerCategory
      kycNextStep
      sf_Broker__c
      subaccounts {
        total_deposit_clp
        total_ransom_pen
        total_deposit_usd
        total_ransom_clp
        total_ransom_usd
        total_deposit_pen
        name
        currency
      }
      wasIdFileUploaded
      userIdFileSignature
      frontSideIdFilePath
      backSideIdFilePath
      wasAddressFileUploaded
      userAddressFileSignature
      addressFilePath
      wasCardFileUploaded
      userCardFileSignature
      cardFilePath
      wasLivenessFileUploaded
      confirmPhone
      wasPhoneValidate
      wasCreatedInMarketingCloud
      wasCreatedInSalesforce
      needKyc
      utmSource
      utmMedium
      utmCampaign
      utmId
      createdAt
      updatedAt
      validatePersonalInfo
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        identityId
        salesforceId
        givenName
        familyName
        email
        phone
        birth
        dni
        signUpCountry
        originCountry
        residenceCountry
        city
        address
        job
        liveInUSA
        isPEP
        riskProfile
        riskPoints
        knowledgeProfile
        knowledgePoints
        userProfileSignature
        isKycApproved
        acceptTerms
        userAcceptTermsSignature
        customerStatus
        customerType
        customerCategory
        kycNextStep
        sf_Broker__c
        subaccounts {
          total_deposit_clp
          total_ransom_pen
          total_deposit_usd
          total_ransom_clp
          total_ransom_usd
          total_deposit_pen
          name
          currency
        }
        wasIdFileUploaded
        userIdFileSignature
        frontSideIdFilePath
        backSideIdFilePath
        wasAddressFileUploaded
        userAddressFileSignature
        addressFilePath
        wasCardFileUploaded
        userCardFileSignature
        cardFilePath
        wasLivenessFileUploaded
        confirmPhone
        wasPhoneValidate
        wasCreatedInMarketingCloud
        wasCreatedInSalesforce
        needKyc
        utmSource
        utmMedium
        utmCampaign
        utmId
        createdAt
        updatedAt
        validatePersonalInfo
      }
      nextToken
    }
  }
`;
