import React, { useEffect, useContext, createContext, useState } from "react"

import { autoLogin } from "../services/auth"

type State = {
  user: User | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  userIsLoading: boolean
  firstLoad: boolean
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>
}

const initState: State = {
  user: null,
  setUser: () => { },
  userIsLoading: false,
  firstLoad: false,
  setFirstLoad: () => { },
}

const AuthContext = createContext({
  state: { ...initState },
})

const AuthProvider = (props: any) => {
  const [user, setUser] = useState(null as User | null)
  const [firstLoad, setFirstLoad] = useState(false)
  const [userIsLoading, setUserIsLoading] = useState(true)

  useEffect(() => {
    const getCurrentUser = async () => {
      setUserIsLoading(true)

      const loggedUser = await autoLogin()
      setUser(loggedUser)
      setUserIsLoading(false)
    }

    getCurrentUser()
  }, [])

  return (
    <AuthContext.Provider
      value={{ state: { user, setUser, userIsLoading, firstLoad, setFirstLoad } }}
      {...props}
    />
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
