import React, { useEffect } from "react"
import styled from "@emotion/styled"

import { Container, Title, SubTitle } from "./styles"
import useLoadWistia from "./helper"
import trackEventPq from "../trackEventPq"

const VideoAnalytics = styled.span`
  display: inline-block;
  height: 200px;
  position: relative;
  width: 400px;
  @media (max-width: 812px) {
    width: 300px;
  }
`

export default function Concepts({
  setShowModal,
  setWistia,
  salesforceId,
}: SliderProps) {
  const loadWistia = useLoadWistia({ setShowModal, setWistia })

  useEffect(() => {
    loadWistia("ei280c3qda")
  }, [loadWistia])

  return (
    <Container>
      <Title>Conceptos básicos</Title>
      <VideoAnalytics
        className="wistia_embed wistia_async_ei280c3qda popover=true popoverAnimateThumbnail=true"
        onClick={() => {
          trackEventPq({
            id: salesforceId,
            event: {
              element: "video2",
              column: "played",
              value: true
            },
          })
        }}
        id="wistia_ei280c3qda"
      >
        &nbsp;
      </VideoAnalytics>
      <SubTitle>
        ¿Qué es un CFD? ¿Que es un lote?¿Cómo funciona el apalancamiento?
        Descubre todo lo necesario para entender el fascinante mundo del trading
      </SubTitle>
    </Container>
  )
}
