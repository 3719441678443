/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { useMemo } from "react"
import styled from "@emotion/styled"

import ErrorIcon from "../ui/images/error-icon"

const RE_DIGIT = new RegExp(/^\d+$/)


const ContentInputsCodeConfirmPhone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 189px;
  margin-bottom: 5%;
   input {
    width: 42px;
    height: 43px;
    flex-shrink: 0;
    border: 1px solid #DFE3E8;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 17.5px;
  }
  &.error input {
    border: 1px solid #E76A6A;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Label = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
`

const ErrorOTP = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
  @media (max-width: 812px) {
    max-width: 300px;
  }
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

type OTPProps = {
  label: string
  sublabel?: string | React.ReactNode
  name: string
  styleLabel?: any
  value: string
  valueLength: number
  onChange: (value: string) => void
  hasError?: boolean | ""
  errorMessage?: string
}

const OtpInput = ({
  label,
  sublabel,
  name,
  styleLabel,
  value,
  valueLength,
  onChange,
  hasError,
  errorMessage,
}: OTPProps) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items: Array<string> = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null

    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '

    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue.trim())

      if (!isTargetValueDigit) {
        return
      }

      focusToNextInput(target)
    } else if (targetValueLength === valueLength) {
      onChange(targetValue.trim())

      target.blur()
    }
  }
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length)

    if (e.key !== 'Backspace' || targetValue !== '') {
      return
    }

    focusToPrevInput(target)
  }
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e

    // keep focusing back until previous input
    // element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }

    target.setSelectionRange(0, target.value.length)
  }

  return (
    <ContentInputsCodeConfirmPhone
      className={hasError ? 'error' : ''}
    >
      <LabelWrapper>
        <Label htmlFor={name} css={styleLabel}>
          {label}
        </Label>
        {sublabel}
      </LabelWrapper>

      <div>
        {valueItems.map((digit, idx) => (
          <input
            key={idx}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className="otp-input"
            value={digit}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
      </div>
      {hasError && (
        <ErrorOTP>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorOTP>
      )}
    </ContentInputsCodeConfirmPhone>
  )
}

export default OtpInput
