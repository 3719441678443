import React, { useState } from "react"
import styled from "@emotion/styled"

import Graph from "./Graph"
import Spinner from "../../../../images/loader-opportunities.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: 5px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.06), 0px 3px 15px rgba(0, 0, 0, 0.04),
    0px 4px 5px rgba(0, 0, 0, 0.08);
  font-family: Roboto;
  font-weight: 700;
  height: 112px;
  position: relative;
  @media (max-width: 812px) {
    margin: 12px;
    margin-bottom: 20px;
    height: auto;
    border: 1.5px solid rgba(0, 0, 0, 0.06);
  }
`

const Title = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #1f2425;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 11px;
  display: block;
  width: 100%;
  @media (max-width: 812px) {
    font-size: 15px;
    top: 20px;
  }
  display: block;
  padding-right: 7;
  padding-left: 7;
  border-radius: 5;
`

const Nemo = styled.div`
  font-size: 12px;
  @media (max-width: 812px) {
    font-size: 13px;
  }
`

const Price = styled.span`
  font-size: 12.85px;
  font-family: Roboto;
`

const WrapperInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70%;
  gap: 11px;
  @media (max-width: 812px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const Percentage = (props: any) => {
  const Component = styled(Price)`
    margin-top: 5px;
    font-weight: 400;
    font-size: 10px;
    font-weight: 700;
    color: ${props.percentage < 0 ? "#ff0000" : "#238636"};
  `
  return <Component>{props.percentage}%</Component>
}

const StockDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
`

const GraphWrapper = styled.div`
  position: relative;
  right: 5px;
  min-width: 100px;
  @media (max-width: 812px) {
  }
`

const InstrumentDetails = styled.div`
  margin-left: 15px;
`

export default function MarketInfo(props: Market) {
  const { name, close, variation, prices } = props
  const instrument = props.instrument.replace("#", "")
  const [tooltip, setTooltip] = useState<boolean>(false)
  const borderColor = variation >= 0 ? "green" : "red"
  const Tooltip = ({ children }: any) => {
    const Component = styled(Title)`
      position: absolute;
      display: ${!tooltip ? "none" : "block"};
      top: ${name.split(" ").length <= 2 ? "-42px" : "-37px"};
      font-size: ${name.split(" ").length <= 2 ? "11px" : "50%"};
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      @media (max-width: 812px) {
        display: none;
      }
    `
    return <Component>{children}</Component>
  }

  return (
    <Wrapper>
      <Tooltip>{name}</Tooltip>
      <Title
        onMouseOver={() => {
          setTooltip(true)
        }}
        onMouseOut={() => {
          setTooltip(false)
        }}
      >
        {name &&
          name
            .split(" ")
            .slice(0, 3)
            .join(" ")}
      </Title>
      <WrapperInformation>
        <InstrumentDetails>
          <Nemo>{instrument}</Nemo>
          <StockDetails>
            <Price>${close}</Price>
            <Percentage percentage={variation.toFixed(2)} />
          </StockDetails>
        </InstrumentDetails>
        <GraphWrapper>
          {prices && prices.length ? (
            <Graph prices={prices} borderColor={borderColor} />
          ) : (
            <img src={Spinner} width="100" height="50" alt="" />
          )}
        </GraphWrapper>
      </WrapperInformation>
    </Wrapper>
  )
}
