import { fetchCountry } from "@capitaria/capitaria-utms"
import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"

import { trackEventMetamap } from "../../helpers/tracker"
import { useAuth } from "../../hooks/auth-context"
import { updateIdFileFlag } from "../../services/verify-documents"
import CustomButton from "../ui/custom-button"
import CustomCountryPicker, { OptionType } from "../ui/custom-country-picker"
import CustomModal from "../ui/custom-modal"
import IconComplete from "./images/complete"
import UploadFile from "./upload-file"

import imageQR from '../metamap/images/qr-onboarding.png'
import { navigate } from "@reach/router"
import CustomButtonV2 from "../ui/custom-button-v2"

const IdWrapper = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  @media (max-width: 812px) {
    flex-direction: column;
    font-size: 19px;
  }
`

const HowToDo = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`

const Title = styled.h3`
  font-family: "Open Sans";
  font-size: 27.65px;
  font-weight: 700;
  line-height: 116%;
  margin: 0;
  color: #1f2425;

  @media (max-width: 425px) {
    font-family: "Open Sans";
    font-size: 27.65px;
    font-style: normal;
    font-weight: 700;
    line-height: 116%;
  }
  @media (max-width: 320px) {
    font-size: 25px;
  }
`

const Description = styled.p`
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 110%;
  color: #1f2425;
  @media (max-width: 812px) {
    font-size: 14px;
  }
`

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const UploadRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  justify-content: space-between;
`

const UploadFileWrapper = styled.div`
  width: 140px;
  flex-grow: 1;
`

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const defaultSaveButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 44px",
  fontSize: "16px",
  width: "260px",
  height: "50px",
}

const buttonCSS = {
  marginTop: "1rem",
  width: "260px",
  height: "50px",
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: "18px",
    lineHeight: "138.889%",
    textAlign: "center",
  },
}

const loadingButtonCss = buttonCSS

const LinkQR = styled.a`
  font-size: 12px;
  text-decoration: underline;
  line-height: 140%;
  font-weight: bold;
  padding-top: 10px;
  cursor: pointer;
`

const WrapperQR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`

const ContainerTextQR = styled.div`
  font-family: 'Nunito';
  margin: 30px 40px;
`

const ContainerImageQR = styled.img`
  width: 300px;
  margin-bottom: 30px;
`

const TitleModalCSS = {
  display: "none"
}

const CountryContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
`

const Indication = styled.p`
  max-width: 320px;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`

const ContainerSelectorTypeDocument = styled.div`
  width: 100%;
`

const ContainerTypeDocument = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const LabelTypeDocument = styled.div`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1f2425;
  margin: 14px auto 24px;
`

const TypeDocument = styled.div`
  cursor: pointer;
  width: 127px;
  height: 64px;
  align-items: center;
  display: flex;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Roboto';
  border: 1px solid #C4CDD5;
  border-radius: 5px;
  color: #919EAB;
  justify-content: center;
  background-color: transparent;

  &.selected {
    border: 1px solid #44D091;
    color: white;
    background-color: #2BA770;

  }
`

const ContainerDocumentsUploaded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 300px;
  margin: 60px auto;
`

type WrapperUploadFileProps = {
  type: string
  onChangeFrontSide: () => void
  onChangeBackSide: () => void
  onChangePassport: () => void
}

const WrapperUploadFile = ({
  type,
  onChangeFrontSide,
  onChangeBackSide,
  onChangePassport,
}: WrapperUploadFileProps) => {
  let listComponent = []
  if (type === 'dni') {
    listComponent.push(
      <UploadFileWrapper>
        <UploadFile
          fileName="idFrontside"
          title="Cara Frontal ID"
          onChange={onChangeFrontSide}
        />
      </UploadFileWrapper>
    )
    listComponent.push(
      <UploadFileWrapper>
        <UploadFile
          fileName="idBackside"
          title="Cara Trasera ID"
          onChange={onChangeBackSide}
        />
      </UploadFileWrapper>
    )
  } else if (type === 'passport') {
    listComponent.push(
      <UploadFileWrapper>
        <UploadFile
          fileName="passport"
          title="Sube tu fotografía"
          onChange={onChangePassport}
        />
      </UploadFileWrapper>
    )

  }
  return <>{listComponent}</>
}

type ValidateIdentityProps = {
  kyc: boolean
}

const ValidateIdentityDocument = ({ kyc }: ValidateIdentityProps) => {
  const [frontSideUploaded, setFrontSideUploaded] = useState(false)
  const [backSideUploaded, setBackSideUploaded] = useState(false)
  const [passportUploaded, setPassportUploaded] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [defaultCountry, setDefaultCountry] = useState<OptionType | undefined>(undefined)
  const [showLinkQR, setShowLinkQR] = useState(false)
  const [countryDefault, setCountryDefault] = useState<string | undefined>(undefined)
  const [selectedCountry, setSelectedCountry] = useState<OptionType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [typeDocumentSelected, setTypeDocumentSelected] = useState<"dni" | "passport">("dni")

  const { state } = useAuth()

  const user = state.user as User

  useEffect(() => {
    const getCountryValue = async () => {
      const country = await fetchCountry(true)

      const countryName = country.countryName
      if (countryName !== countryDefault) {
        setCountryDefault(countryName)
      }
    }
    if (countryDefault === undefined) {
      getCountryValue()
    }

  }, [countryDefault])


  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1025px)")

    const handleMediaChange = (e: any) => {
      setShowLinkQR(e.matches)
    }

    handleMediaChange(mediaQuery)

    mediaQuery.addEventListener("change", handleMediaChange)

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange)
    }
  }, [])

  let disabledButton = true
  if (typeDocumentSelected === "passport") {
    disabledButton = !passportUploaded
  }
  if (typeDocumentSelected === "dni") {
    disabledButton = !(frontSideUploaded && backSideUploaded)
  }

  const onClick = async () => {
    setIsLoading(true)

    try {
      const country = selectedCountry !== undefined ? selectedCountry : defaultCountry

      if (country) {
        const result = await updateIdFileFlag(user, country, typeDocumentSelected, kyc)

        if (result.user) {
          state.setUser(result.user)

          if (result.user.wasIdFileUploaded) {
            state.setUser({
              ...(result.user as User),
              wasIdFileUploaded: result.user.wasIdFileUploaded,
              kycNextStep: result.user.kycNextStep,
            })
            if (kyc) {
              navigate(`/${result.user.kycNextStep}`)
            }
          }
        }
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onChangeFrontSide = () => {
    setFrontSideUploaded(true)
    trackEventMetamap('upload-complete-idfront')
  }

  const onChangeBackSide = () => {
    setBackSideUploaded(true)
    trackEventMetamap('upload-complete-idback')
  }

  const onChangePassport = () => {
    setPassportUploaded(true)
    trackEventMetamap('upload-complete-passport')
  }

  const onSave = () => {
    trackEventMetamap('send-documents-to-be-validated')
    onClick()
  }

  const onClickQR = () => {
    setShowQR(true)
    trackEventMetamap('open-QR-Option-id-document')
  }

  const onClickTypeDocument = (type: "dni" | "passport") => () => {
    if (type !== typeDocumentSelected) {
      if (type === "passport") {
        setFrontSideUploaded(false)
        setBackSideUploaded(false)
      }
      if (type === "dni") {
        setPassportUploaded(false)
      }
    }
    setTypeDocumentSelected(type)
    trackEventMetamap('change-in-document-type')
  }

  return (
    <IdWrapper>
      <HowToDo>
        <Title>Documento de identidad</Title>
        <Description>
          Para crear tu cuenta es necesario validar tu identidad a través de tu ID de país
        </Description>
        <CustomCountryPicker
          defaultValue={countryDefault}
          label="Selecciona el país de documento"
          placeholder="Seleccionar país"
          width={268}
          onSelectDefaultValue={(value: OptionType | undefined) => {
            if (defaultCountry === undefined)
              setDefaultCountry(prevValue => value)

            trackEventMetamap('default-change-in-document-country', value)
          }}
          onChange={(value: OptionType ) => {
            setCountryDefault(value.value)
            setSelectedCountry(value)
            trackEventMetamap('change-in-document-country')
          }}
        />
      </HowToDo>
      {defaultCountry !== undefined &&
        <>
          <HowToDo>
            <ContainerSelectorTypeDocument>
              <LabelTypeDocument>Selecciona el tipo de documento</LabelTypeDocument>
              <ContainerTypeDocument>
                <TypeDocument
                  className={typeDocumentSelected === 'dni' ? 'selected' : ''}
                  onClick={onClickTypeDocument('dni')}
                >
                  Documento de identidad
                </TypeDocument>
                <TypeDocument
                  className={typeDocumentSelected === 'passport' ? 'selected' : ''}
                  onClick={onClickTypeDocument('passport')}
                >
                  Pasaporte
                </TypeDocument>
              </ContainerTypeDocument>
            </ContainerSelectorTypeDocument>

            <Indication>
              Sube una fotografía {typeDocumentSelected === 'dni' ? "por ambos lados de tu ID de país" : "de tu pasaporte"}. Es importante que el documento esté vigente y que se vea claramente el nombre completo, el número de identificación y fecha de nacimiento
            </Indication>
            {showLinkQR && (
              <LinkQR onClick={onClickQR}>
                Continuar en el telefono
              </LinkQR>
            )}
          </HowToDo>

          <UploadContainer>
            <UploadRow>
              <WrapperUploadFile
                type={typeDocumentSelected}
                onChangeBackSide={onChangeBackSide}
                onChangeFrontSide={onChangeFrontSide}
                onChangePassport={onChangePassport}
              />
            </UploadRow>

            <SaveButtonWrapper>
              <CustomButtonV2
                type="button"
                disabled={disabledButton}
                loading={isLoading}
                style={buttonCSS}
                disabledStyle={defaultSaveButtonStyle}
                loadingStyle={loadingButtonCss}
                onClick={onSave}
              >
                Confirmar
              </CustomButtonV2>
            </SaveButtonWrapper>
          </UploadContainer>
        </>
      }
      <CustomModal
        isOpen={showQR}
        styleObj={TitleModalCSS}
        closeModal={() => { }}
        title=''
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
      >
        <WrapperQR>
          <ContainerTextQR>
            Para continuar desde tu telefono movil, debes escanear el siguiente QR
          </ContainerTextQR>
          <ContainerImageQR src={imageQR} alt="" />
          <CustomButton
            onClick={() => {
              window.location.reload()
              trackEventMetamap('press-reload-modal-id-document')
            }}
          >
            Terminé!
          </CustomButton>
        </WrapperQR>
      </CustomModal>
    </IdWrapper>
  )
}

type Props = {
  kyc: boolean
}

const ValidateDocuments = ({ kyc }: Props) => {
  const { state } = useAuth()
  const user = state.user as User

  return (
    <>
      {
        user.wasIdFileUploaded ?
          <ContainerDocumentsUploaded>
            <Title>Documento de identidad</Title>
            <div><IconComplete /></div>
            <div>Documento guardado exitosamente</div>
            <CustomButtonV2
              style={buttonCSS}
              onClick={() => navigate('/home')}
            >
              Volver
            </CustomButtonV2>
          </ContainerDocumentsUploaded> :
          <CountryContainer>
            <ValidateIdentityDocument kyc={kyc} />
          </CountryContainer>
      }

    </>
  )
}

export default ValidateDocuments
