import styled from "@emotion/styled"


const Error = styled.div`
display: flex;
align-content: center;
align-items: center;
color: #e76a6a;
font-weight: 600;
font-size: 13px;
line-height: 140%;
padding: 0.5rem 0;
max-width: 352px;
`

const Form = styled.form`
display: flex;
flex-direction: column;
align-items: center;
padding: 3.5rem 0 3rem;
width: 458px;
margin: 0 auto;
@media (max-width: 812px) {
  width: 100%;
  padding: 2rem 1.5rem 5rem;
}
`

const InputWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`

const CheckboxWrapper = styled.div`
display: flex;
justify-content: flex-start;
margin-top: 1rem;
width: 100%;
flex-direction: column;
`

const buttonStyle = {
marginTop: "2.75rem",
padding: "12px",
height: "48px",
width: "200px",
}


const Label = styled.label`
font-family: Nunito;
font-weight: 600;
font-size: 13.33px;
line-height: 140%;
color: #1f2425;
`

const AddressWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

const LabelFlex = styled.div`
  display: flex;
  align-items: center;
`

const loadingButtonStyle = buttonStyle

const checkboxLabelStyle = {
  width: "100%",
  "@media (maxWidth: 812px)": {
    width: "290px",
  },
}

const checkboxFormGroupStyle = {
  width: "300px",
  "@media (maxWidth: 812px)": {
    marginRight: 0,
    width: "300px",
  },
}

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

type Style = Record<string, any>

const inputStyle: Style = {
  width: "219px",
  "@media (max-width: 812px)": {
    width: "327px",
  },
}

const addressInputStyle: Style = {
  width: "90px",
  height: "56px",
  "@media (max-width: 812px)": {
    width: "90px"
  },
}

const AddressLabel = styled.div`
  margin-right: 5px;
`;

export {
  inputStyle,
  addressInputStyle,
  Error,
  Form,
  InputWrapper,
  CheckboxWrapper,
  buttonStyle,
  Label,
  loadingButtonStyle,
  checkboxLabelStyle,
  checkboxFormGroupStyle,
  ErrorMessage,
  AddressWrapper,
  LabelFlex,
  AddressLabel
}
