import styled from "@emotion/styled"
import React, { useState } from "react"
import { trackEvent } from "../../../helpers/tracker"
import "./styles.css"

const Button = styled.div`
  background: #2BA770;
  color: #fff;
  padding: 10px 55px;
  border-radius: 5px;
  border: none;
  height: 44px;
  font-family: "Roboto";
  font-weight: 500;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 812px) {
    margin-left: 0;
    width: 19.6rem;
  }
`

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
`

const AddressContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
`

const AddressLabel = styled.div`
  label {
    font-weight: bold;
  }
`

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
`

type PropsModalGoogleMaps = {
  address: string
  setAddress: (address: string) => void
  city: string
  setCity: (city: string) => void
  setShowModal: (showModal: boolean) => void
  setFieldValue: (field: string, value: string) => void
  values: Record<string, any>
  streetNumber: string
  setStreetNumber: (streetNumber: string) => void
  setError: (error: boolean) => void
  setGmapResults: (gmapsResults: any) => void
  setAddressError: (error: string) => void
}

type Information = {
  address: string
  streetNbr: number
  postalCode: string
  city: string
  region: string
  lat: number
  lng: number
}

function ModalGoogleMaps({
  address,
  setAddress,
  city,
  setCity,
  setFieldValue,
  setShowModal,
  streetNumber,
  setStreetNumber,
  setError,
  values,
  setGmapResults,
  setAddressError
}: PropsModalGoogleMaps) {
  const [errors, setErrors] = useState<Record<string, string>>({
    address: "",
    city: "",
    streetNumber: ""
  })
  const validateAddress = () => {
    if (streetNumber.length === 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        streetNumber: "Ingresa un número de calle",
      }))
      return false
    }

    if (!address) {
      setErrors(prevErrors => ({
        ...prevErrors,
        address: "Ingresa una dirección",
      }))
      return false
    }

    if (!city) {
      setErrors(prevErrors => ({
        ...prevErrors,
        city: "Ingresa una ciudad o estado",
      }))
      return false
    }
    setFieldValue("address", address)
    setFieldValue("city", city)
    setError(false)
    return true
  }

  const handleContinue = () => {
    const isValid = validateAddress()

    if (isValid) {
      setErrors({
        address: "",
        city: "",
        streetNumber: "",
      })
      setAddressError("")
      setShowModal(false)
      setGmapResults([])
      setError(false)
      setAddress(`${values["address"]} ${streetNumber}, ${city}`)
      setFieldValue("address", `${values["address"]} ${streetNumber}, ${city}`)
      trackEvent("kyc:personal-info:manual-address:continue", {
        address: values["address"],
        city,
        streetNumber
      })
    } else {
      trackEvent("kyc:personal-info:manual-adress:error", {
        address,
        city,
        streetNumber,
        errors,
      })
    }
  }


  return (
    <div className="modal-gmaps">
      <div className="modal-gmaps-title">
        <div>Ingresa tu dirección</div>
        <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
      </div>
      <div className="modal-gmaps-content">
        <FlexColumnContainer>
          <div>
            <AddressContainer>
              <AddressLabel>
                <label>Calle</label>
              </AddressLabel>
              {errors["address"].length ? (
                <ErrorMessage>{errors["address"]}</ErrorMessage>
              ) : null}
            </AddressContainer>
            <div>
              <input
                type="text"
                className="input-gmaps"
                onChange={event => {
                  setAddress(event.target.value)
                  setFieldValue("address", event.target.value)
                  setErrors({ ...errors, address: "" })
                }}
                value={values["address"]}
              />
            </div>
          </div>
          <div>
            <AddressContainer>
              <AddressLabel>
                <label>Número</label>
              </AddressLabel>
              {errors["streetNumber"].length ? (
                <ErrorMessage>{errors["streetNumber"]}</ErrorMessage>
              ) : null}
            </AddressContainer>
            <div>
              <input
                type="text"
                className="input-gmaps"
                onChange={event => {
                  setStreetNumber(event.target.value)
                  // setFieldValue("address", event.target.value)
                  setErrors({ ...errors, streetNumber: "" })
                }}
                value={streetNumber}
              />
            </div>
          </div>
          <div>
            <AddressContainer>
              <AddressLabel>
                <label>Ciudad</label>
              </AddressLabel>
              {errors["city"].length ? (
                <ErrorMessage>{errors["city"]}</ErrorMessage>
              ) : null}
            </AddressContainer>
            <div>
              <input
                type="text"
                className="input-gmaps"
                onChange={event => {
                  setCity(event.target.value)
                  setFieldValue("city", event.target.value)
                  setErrors({ ...errors, city: "" })
                }}
                value={city}
              />
            </div>
          </div>
        </FlexColumnContainer>
        <Container>
          <Button onClick={handleContinue}>Continuar</Button>
        </Container>
      </div>
    </div>
  )
}

export default ModalGoogleMaps
