import React from "react"

const IconToDisplay = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Plus">
		<path id="Vector 4" d="M12 3V21" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path id="Vector 5" d="M3 12L21 12" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
	</svg>
)

export default IconToDisplay
