import React from "react"
import styled from "@emotion/styled"

import weeklyLogo from "../../../images/weekly-logo.png"

const Weekly = styled.img`
  width: 52px;
  height: 20px;
`

const WeeklyLogo = () => <Weekly src={weeklyLogo} alt="Capitaria Weekly" />

export default WeeklyLogo
