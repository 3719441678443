import React from "react"
import styled from "@emotion/styled"

import DisplaySources from "./display-sources"

const Container = styled.div`
  text-align: center;
  margin-top: 35px;
`

const Title = styled.div`
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 32px;
  @media (max-width: 812px) {
    font-size: 22px;
  }
`

export default function DiscoverOpportunities() {
  return (
    <Container>
      <Title>
        ¿Cómo me entero de más oportunidades?
      </Title>
      <DisplaySources />
    </Container>
  )
}
