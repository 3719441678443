import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

const MobileContainer = styled.div`
  border-right: 1px solid #e0e0e0;
  background-color: #ffffff;
  width: 100;
  cursor: pointer;
`

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
  text-decoration: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 0 0.375rem;
  width: 365px;
  height: 48px;
  @media (min-width: 812px) {
    padding: 0 9px;
    height: 28px;
  }
`

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 2rem;
  width: 365px;
  height: 100%;
`

type DropdownProps = {
  country: string
  items: ValueDropdown[]
  onchange: Function
  defaultCountry: string
}

const Item = styled.li`
  border-bottom: 1px solid #e0e0e0;
  padding: 0.75rem 0.375rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const TextOptions = styled.p`
  margin: 0 10px;
	color: #1F2425;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.1px;
  width: 220px;
`

const Change = styled.p`
	width: 55px;
	color: #2BA770;
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.1px;
	margin-left: 10%;
`


const MobileNavbarDeposit = ({ country, items, onchange, defaultCountry }: DropdownProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const [selectedItem, setSelectedItem] = useState(items[0])

  useEffect(() => {
    const searchCountry = items.filter(i => i.id === country)
    if (searchCountry.length > 0) {
      setSelectedItem(searchCountry[0])
    } else {
      setSelectedItem(items.filter(i => i.id === defaultCountry)[0])
    }
  }, [country, items, defaultCountry])

  const onClick = () => setIsOpened(!isOpened)
  const handleItemClick = (item: ValueDropdown) => {
    setSelectedItem(item)
    setIsOpened(false)
    onchange(item)
  }

  return (
    <MobileContainer>
      <ToggleButton onClick={onClick}>
        {selectedItem.iconFlag}{" "}
        <TextOptions>{selectedItem.text}</TextOptions>
        <Change>Cambiar</Change>
      </ToggleButton>

      {isOpened && (
        <LinkList>
          {items.map((item, index) => (
            <Item key={index} onClick={() => handleItemClick(item)}>
              {item.iconFlag}
              <TextOptions>{item.text}</TextOptions>
            </Item>
          ))}
        </LinkList>
      )}
    </MobileContainer>
  )
}

export default MobileNavbarDeposit
