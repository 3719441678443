import React from "react"
import "./styles.css"

type AppProps = {
  onSkipClick: () => void
  onSubmitClick: () => void
  question: string
  options: string[]
  setAnswer: (answer: number) => void
}

function ModalWistia(props: AppProps) {
  function handleSkipClick() {
    props.onSkipClick()
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="question">Pregunta</div>
        <hr className="modal-divider" />
        <p className="modal-title">
          {props.question}
        </p>
        <form className="modal-form">
          <div className="modal-options">
            {props.options.map((option, index) => (
              <label key={option} className="modal-option">
                <div>
                  <input
                    type="radio"
                    name="option"
                    value={option}
                    onChange={() => {
                      props.setAnswer(index+1)
                    }}
                  />
                </div>
                <div>{option}</div>
              </label>
            ))}
          </div>
        </form>
        <hr className="modal-divider" />
        <div className="modal-buttons">
          <button className="modal-button-skip" onClick={handleSkipClick}>
            Omitir
          </button>
          <button className="modal-button" onClick={props.onSubmitClick}>
            Enviar
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalWistia
