import React from "react"

import UploadArrowIcon from "../ui/images/upload-arrow-icon"
import UploadCheckIcon from "../ui/images/upload-check-icon"
import UploadFailedIcon from "./images/upload-failed-icon"

import ProgressCircle from "./progress-circle"

type Props = {
  percentage: number
  hasError?: boolean
}

const UploadProgressCircle = ({ percentage, hasError = false }: Props) =>
  hasError ? (
    <UploadFailedIcon />
  ) : (
    <ProgressCircle
      percentage={percentage}
      startIcon={<UploadArrowIcon />}
      endIcon={<UploadCheckIcon />}
    />
  )

export default UploadProgressCircle
