import React from "react"
import styled from "@emotion/styled"

import helpIconOn from "../../../images/icn-help-on.svg"

const HelpIcon = styled.div`
  background-image: url(${helpIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
`

const HelpIconOn = () => <HelpIcon />

export default HelpIconOn
