import React from "react"
import styled from "@emotion/styled"

import { useSimulator } from "./context"

const Amount = styled.div`
  font-size: 40px;
  font-family: "Work Sans";
  font-weight: 700;
  color: #fff;
`

const Text = styled.div`
  font-family: Roboto;
  font-weight: 500;
`


type CalculateWinLose = {
  direction: string
  priceToday: any
  lastPrice: number
  contractSize: number
  size: number
}


const calculateWinLose = ({
  direction,
  lastPrice,
  priceToday,
  contractSize,
  size,
}: CalculateWinLose) => {
  const result: Record<string, any> = { amount: 0, state: null }
  const spread = priceToday.ask - priceToday.bid
  switch (direction) {
    case "bid":
      result.amount =
        contractSize * size * (lastPrice - priceToday.bid - spread)
      result.state =
        lastPrice - priceToday.ask - spread > 0 ? "ganado" : "perdido"
      break
    case "ask":
      result.amount =
        contractSize * size * (lastPrice + spread - priceToday.bid)
      result.state =
        priceToday.bid - lastPrice + spread > 0 ? "ganado" : "perdido"
      break
  }
  result.state = result.amount ? result.state : "quedado igual"
  return result
}

export default function ResultPrediction({ isActive }: any) {
  const { response, values } = useSimulator()
  const { direction } = values
  if (!isActive || !response) return null

  const { instruments = null, price_now = null } = response
  if (!instruments || !price_now) return null

  const lastPrice = instruments[0]
  const priceToday = price_now[0]
  const contractSize: number = price_now[0].contract_size

  if (lastPrice && priceToday) {
    const result = calculateWinLose({
      direction,
      lastPrice: lastPrice.close,
      priceToday,
      contractSize,
      size: parseFloat(values.size),
    })

    const formatNumber = result.amount.toFixed(2)

    return (
      result.state && (
        <>
          <Text>Hubieses {result.state} *:</Text>
          <Amount>
            {priceToday.base_currency} ${Math.abs(formatNumber)}
          </Amount>
        </>
      )
    )
  } else {
    return null
  }
}
