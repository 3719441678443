import React, { useState } from "react"
import styled from "styled-components"
import KYC from "../../services/kyc"

import IconStanby from "../ui/images/icon-standby-validation"
import CustomButtonV2 from "../ui/custom-button-v2"
import { useAuth } from "../../hooks/auth-context"
import { navigate } from "@reach/router"

const ContainerView = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Title = styled.h3`
	width: 308px;
	font-family: Open Sans;
	font-size: 27.65px;
	font-style: normal;
	font-weight: 700;
	line-height: 116%;
	color: #1f2425;
	text-align: center;
`

const Text = styled.p`
	text-align: center;
	max-width: 45%;
	margin-top: 40px;
	@media(max-width:768px) {
		max-width: 50%;
	}
	@media (min-width: 320px) and (max-width: 425px){
		max-width: 70%;
	}
`

const TextForContinue = styled.p`
	text-align: center;
	max-width: 35%;
	@media(max-width:768px) {
		max-width: 40%;
	}
	@media (min-width: 320px) and (max-width: 425px){
		max-width: 80%;
	}
`
const saveButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 48px",
  width: "260px",
  backgroundColor: "#2ba770",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const loadingButtonStyle = saveButtonStyle

const ValidationOnHold = () => {
  const { state } = useAuth()
  const user = state.user as User

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)

    const result = await KYC.updateValidationDeposit(user)
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <ContainerView>
      <Title>
        Validando depósito y tu información
      </Title>
      <IconStanby />
      <Text>
        Una vez validado tu información y tu depósito, <strong>te enviaremos un email con tus accesos a la plataforma.</strong>
      </Text>
      <br />
      <TextForContinue>
        Mientras, sigue completando los últimos pasos de tu perfil para que puedas operar
      </TextForContinue>
      <CustomButtonV2
        type="button"
        loading={isLoading}
        style={saveButtonStyle}
        loadingStyle={loadingButtonStyle}
        onClick={onSubmit}
      >
        Continuar
      </CustomButtonV2>

    </ContainerView>
  )
}

export default ValidationOnHold
