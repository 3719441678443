import React, { useEffect } from "react"

import styled from "@emotion/styled"

import { Container, Title, SubTitle } from "./styles"
import useLoadWistia from "./helper"
import trackEventPq from "../trackEventPq"

const VideoAnalytics = styled.span`
  display: inline-block;
  height: 200px;
  position: relative;
  width: 400px;
  @media (max-width: 812px) {
    width: 300px;
  }
`

const FirstTrade = ({
  showModal,
  setShowModal,
  wistia,
  setWistia,
  salesforceId,
}: SliderProps) => {
  const loadWistia = useLoadWistia({ setShowModal, setWistia })

  useEffect(() => {
    loadWistia("snutl4cjcc")
  }, [loadWistia])

  return (
    <Container>
      <Title>Tu primera operación</Title>
      <VideoAnalytics
        className="wistia_embed wistia_async_snutl4cjcc popover=true popoverAnimateThumbnail=true"
        onClick={() => {
          trackEventPq({
            id: salesforceId,
            event: {
              element: "video4",
              column: "played",
              value: true
            },
          })
        }}
        id="wistia_snutl4cjcc"
      >
        &nbsp;
      </VideoAnalytics>
      <SubTitle>
        ¿Cómo abrir y cerrar una operación? ¿En que tengo que fijarme antes de
        abrir una operación? Todo lo que necesitas saber para entrar en el
        mercado y tomar oportunidades
      </SubTitle>
    </Container>
  )
}

export default FirstTrade
