import React, { useState } from 'react'

import styled from "@emotion/styled"
import DropdownIndicatorIcon from "../ui/images/dropdown-indicator-icon"


const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-color: #919EAB;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  padding: 0px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 17em;
  line-height: 1em;
`

const CheckboxDropdownContent = styled.div`
  width: 100%;
`

const Dropdown = styled.div``

const DropdownButton = styled.button`
  width: 100%;
  text-align: justify;
  padding: 10px 17px;
  background-color: #ffffff;
  border-color: #919EAB;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: #808080;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledCheckbox = styled.input`
  display: inline-block !important;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  filter: hue-rotate(184deg);
`

const DropdownLabel = styled.label`
  height: 51px;
  padding-top: 10px;
  display: flex;
  align-items: center;
`

type SelectedOptions = {
  value: string
  label: string
  selected?: boolean | undefined
}

interface Props {
  options: { value: string; label: string }[]
  placeholder: string
  onChange: (selectedOptions: SelectedOptions[]) => void
}

const DropdownCheckbox: React.FC<Props> = ({ options, placeholder, onChange }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    value: string; label: string; selected?: boolean
  }[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionToggle = (option: { value: string; label: string }) => {
    const optionValue = option.value
    const isSelected = selectedOptions.some((o) => o.value === optionValue)
    let newSelectedOptions = []

    if (isSelected) {
      newSelectedOptions = selectedOptions.map((o) => {
        return { ...o, selected: o.value !== optionValue }
      })
    } else {
      newSelectedOptions = selectedOptions.concat([{ ...option, selected: true }])
    }

    setSelectedOptions(newSelectedOptions.filter(option => option.selected === true))
    onChange(newSelectedOptions)
  }

  return (
    <CheckboxDropdownContent>
      <Dropdown>
        <DropdownButton onClick={handleToggle}>
          {selectedOptions.length > 0 ? `${selectedOptions.length} opci${selectedOptions.length !== 1 ? 'ones' : 'ón'} seleccionada${selectedOptions.length !== 1 ? 's' : ''}` : placeholder}
          <DropdownIndicatorIcon />
        </DropdownButton>
        {isOpen && (
          <DropdownMenu>
            {options.map((option: { value: string; label: string }) => (
              <DropdownLabel key={option.value}>
                <StyledCheckbox
                  type="checkbox"
                  name={option.value}
                  checked={selectedOptions.some((o) => o.value === option.value)}
                  onChange={() => handleOptionToggle(option)}
                />
                {option.label}
              </DropdownLabel>
            ))}
          </DropdownMenu>
        )}
      </Dropdown>
    </CheckboxDropdownContent>
  )
}

export default DropdownCheckbox
