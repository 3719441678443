import React from "react"
import styled from "@emotion/styled"

import warningIconColor from "../../../images/warning-icon-color.svg"

const WarningIconColorDiv = styled.div`
  background-image: url(${warningIconColor});
  background-repeat: no-repeat;
  background-size: cover;
  width: 39px;
  height: 33.79px;
`

const WarningIconColor = () => <WarningIconColorDiv />

export default WarningIconColor
