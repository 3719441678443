import React from "react"
import styled from "@emotion/styled"

import logoCapitaria from "../../../images/logo-capitaria-black.svg"

const LogoCapitaria = styled.div`
  background-image: url(${logoCapitaria});
  background-repeat: no-repeat;
  background-size: cover;
  width: 138px;
  height: 30px;
  @media (max-width: 812px) {
    width: 123.585px;
    height: 27px;
  }
`

const LogoCapitariaBlack = () => <LogoCapitaria />

export default LogoCapitariaBlack
