/** @jsx jsx */
import { jsx } from "@emotion/core"
import { FocusEvent } from "react"
import styled from "@emotion/styled"

import ErrorIcon from "../ui/images/error-icon"


const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const Label = styled.label`
  position: relative;
  cursor: pointer;
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
  clear: both;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32px;
`

const Text = styled.span`
  & > a {
    color: #1f2425;
  }
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
  @media (maxWidth: 812px) {
    max-width: 300px;
  }
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxInput = styled.input`
  margin-right: 8px;
`

type Props = {
  formGroupStyle?: any
  label: string | React.ReactNode
  labelStyle?: any
  value: string
  onChange?: (event: string) => void
  onBlur?: (event: FocusEvent<any>) => void
  hasError?: boolean | ""
  errorMessage?: string
}

const CustomCheckbox = ({
  formGroupStyle,
  label,
  labelStyle,
  value,
  onChange,
  onBlur,
  hasError,
  errorMessage,
}: Props) => (
  <FormGroup css={formGroupStyle}>
    <Label css={labelStyle}>
      <Text>{label}</Text>
      <CheckContainer>
        <CheckboxInput
          type="checkbox"
          value={value}
          onChange={e => {
            const boolValue = e.target.value === "true"
            if (onChange) onChange((!boolValue).toString())
          }}
          onBlur={onBlur}
        />
        <span className="checkmark"></span>
      </CheckContainer>
    </Label>

    {hasError && (
      <Error>
        {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
      </Error>
    )}
  </FormGroup>
)

export default CustomCheckbox
