import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { API, graphqlOperation } from "aws-amplify"
import { getRiskProfileQuestions } from "../../graphql/queries"

import { trackEvent } from "../../helpers/tracker"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import CheckKycNextStep from "./check-kyc-step"
import Questions from "./questions"

const RiskProfile = () => {
  const { state } = useAuth()
  const user = state.user as User

  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(getRiskProfileQuestions, {})
        )
        const riskProfileQuestions = JSON.parse(
          response.data.getRiskProfileQuestions
        )

        setQuestions(riskProfileQuestions)
      } catch (error) {
        trackEvent("kyc:risk-profile:error:loading-questions")
        Sentry.setExtra("error", JSON.stringify(error))
        Sentry.captureException(error)
        toast.error(
          "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
        )
      }
    }

    getQuestions()
  }, [])

  const onSubmit = async (values: any) => {
    setIsLoading(true)

    const result = await KYC.updateRiskProfile(user, values)

    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <CheckKycNextStep>
      <Questions
        questions={questions}
        onSubmit={onSubmit}
        isLoading={isLoading}
        title="Revisemos tu perfil de riesgo"
      />
    </CheckKycNextStep>
  )
}

export default RiskProfile
