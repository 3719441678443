import React from "react"
import styled from "@emotion/styled"

import footerBackground from "./../images/footer-background.svg"
import ScrollTop from "../helpers/scroll-top"
import Header from "./ui/header"
import Sidebar from "./ui/sidebar"

const Container = styled.div`
  display: flex;
  min-height: 100%;
  background-image: url(${footerBackground});
  background-repeat: no-repeat;
	background-size: 100% 156.835%;
  background-position: 1% -33%;
  @media (max-width: 812px) {
    flex-direction: column;
    background-size: 132% 155.835%;
  }
  @media (max-width: 450px) {
    background-position: 1% -17%;
    background-size: 180% 172.835%;
  }
`

const Content = styled.div`
  width: 100%;
  @media (max-width: 812px) {
    padding: 0 0rem;
  }
`

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <ScrollTop />
      <Header showLinks={true} />
      <Container>
        <Sidebar />
        <Content>{children}</Content>
      </Container>
    </>
  )
}

export default Layout
