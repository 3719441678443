import { useCallback } from 'react';

type Wistia = {
  setShowModal: (showModal: boolean) => void
  setWistia: (wistia: any) => void
}

const useLoadWistia = ({ setShowModal, setWistia }: Wistia) => {
  const loadWistia = useCallback(
    id => {
      // @ts-ignore
      const _wistia = Wistia.embed(id, {
        videoFoam: true,
        popover: true,
        popoverAnimateThumbnail: true,
      })
      setWistia(_wistia)
      _wistia.bind("end", function() {
        _wistia.popover.hide()
        setShowModal(true)
      })
    },
    [setShowModal, setWistia]
  )

  return loadWistia
}

export default useLoadWistia;
