import React, { useState } from "react"
import styled from "@emotion/styled"

import { trackEvent } from "../../helpers/tracker"
import CustomModal from "../ui/custom-modal"
import BBVA from "../ui/images/bbva-logo"
import BCP from "../ui/images/bcp-logo"
import IconToDisplay from "../ui/images/icon-to-display"
import IconToHide from "../ui/images/icon-to-hide"
import Scotiabank from "../ui/images/scotiabank-logo"
import BankModal from "./bank-modal"
import CarouselOptionsBanks from "./carousel-options-banks"
import PeruCreditCard from "../nuvei/peru/credit-card"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
`

const ContentTitleDescription = styled.div`
  display: inline-flex;
  height: 56px;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  margin-top: 15px;
`
const TitleDescription = styled.h4`
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
`

const ContainerText = styled.div`
  background-color: #fff;
  width: 100%;
  height: 12.875em;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  background-color: transparent;
  margin-bottom: 20px;
`

const Tab = styled.div`
  text-align: center;
  color: #1f2425;
  width: 217px;
  padding-bottom: 0.5rem;
  cursor: pointer;
`

const ContainerUpdatedCreditCard = styled.div`
  min-width: 600px;
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  padding: 25px;
`

const tabsList = {
  credit: "Crédito o Débito",
  transfer: "Transferencias",
}

const activeTab = {
  fontWeight: 600,
  borderBottom: "4px solid #30ab76",
}

const BCPAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BCP LPEPL",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "1941959486196",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "00219400195948619695",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "1941973495091",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "00219400197349509198",
  },
  bankCodeId: {
    label: "Código Identificación Bancaria",
    value: "93560477",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const BBVAacount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BCONPEPL",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0011-0123-01-00063521",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "011 123 000100063521 77",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "0011-0123-01-00063513",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "011 123 000100063513 74",
  },
  bankCodeId: {
    label: "Código Identificación Bancaria",
    value: "00025317926",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const scotiabankAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  ruc: {
    label: "Ruc",
    value: "9040061493",
  },
  swift: {
    label: "SWIFT",
    value: "BSUDPEPLXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0003977006",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "009 170 000003977006 20",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "0000451070",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "009 170 000000451070 27",
  },
}

type Props = {
  showSkip?: boolean
  onClick?: () => void
}

const CreditCardComponent = () => {

  const isPeruCreditCardEnabled = useFeatureIsOn("payment-peru")

  if (isPeruCreditCardEnabled)
    return <PeruCreditCard />

  return (
    <ContainerUpdatedCreditCard>
      Estamos actualizando para que tengas una mejor experiencia con Capitaria
    </ContainerUpdatedCreditCard>
  )
}


const PeruDeposit = ({ showSkip = true, onClick = () => { } }: Props) => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)
  const [showSection, setShowSection] = useState("credit")
  const [isExpandedTransfer, setIsExpandedTransfer] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = (selectedAccount: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setAccount(selectedAccount)
    setLogo(logo)
  }

  const clickAnimationEnd = () => {
    if (isClickButton) {
      onClick()
    }
  }

  const clickModal = () => {
    setIsModalOpen(false)
    setIsClickButton(true)
  }

  const onClickTab = (tab: string) => () => {
    trackEvent(`deposit:tab:${tab}`)
    setShowSection(tab)
  }

  const handleClickTransfer = () => {
    setIsExpandedTransfer(!isExpandedTransfer)
  }

  const banks = [
    {
      account: scotiabankAccount,
      isSolesAndDollarAccount: true,
      isDollarAccount: false,
      extraText: false,
      image: <Scotiabank />,
    },
    {
      account: BCPAccount,
      isSolesAndDollarAccount: true,
      isDollarAccount: false,
      extraText: false,
      image: <BCP />,
    }, {
      account: BBVAacount,
      isSolesAndDollarAccount: true,
      isDollarAccount: false,
      extraText: false,
      image: <BBVA />,
    },
  ]

  return (
    <>
      <Tabs>
        {Object.entries(tabsList).map(([key, title], idx: number) => (
          <Tab
            key={`idx-tabs-deposit-${idx}`}
            style={showSection === key ? activeTab : undefined}
            onClick={onClickTab(key)}
          >
            {title}
          </Tab>
        ))}
      </Tabs>
      {showSection === "credit" && <CreditCardComponent />}
      {showSection === "transfer" &&
        <>
          <BankTransference>
            <br />
            <CarouselOptionsBanks
              listBanks={banks}
              openModal={openModal}
            />
            <br />
            <ContentTitleDescription onClick={handleClickTransfer}>
              <TitleDescription>
                Condiciones transferencias bancarias cuentas Perú
              </TitleDescription>
              {isExpandedTransfer ? <IconToHide /> : <IconToDisplay />}
            </ContentTitleDescription>
            {isExpandedTransfer && (
              <ContainerText>
                <Text>
                  Los depósitos deben enviarse al email depositos@capitaria.com con
                  copia a su agente comercial e ir bajo el asunto KT.
                </Text>
                <Text>
                  Cabe señalar que{" "}
                  la cuenta del Citibank tiene un costo asociado por
                  ingreso y retiro.
                </Text>
                <Text>
                  NO SE ACEPTAN DEPÓSITOS PROVENIENTES DE CUENTAS CORRIENTES DE
                  TERCEROS.
                  Si esto sucede, todo depósito será reversado a la cuenta corriente
                  inscrita en el contrato.
                </Text>
              </ContainerText>)}
          </BankTransference>

          <CustomModal
            title="Información para transferencia"
            isOpen={isModalOpen}
            closeModal={closeModal}
            animationEnd={clickAnimationEnd}
          >
            <BankModal
              logo={logo}
              account={account}
              showSkip={showSkip}
              onClick={clickModal}
            />
          </CustomModal>
        </>
      }
    </>
  )
}

export default PeruDeposit
