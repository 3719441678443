import * as Sentry from "@sentry/browser"

/**
 * Guarda la información relevante en Sentry que permita tener un mayor
 * control respecto a los errores que son enviados a esta.
 * Se debe utilizar en información relevante que se necesite de los usuarios
 * justo despues del inicio de un metodo o función
 *
 * @param params Un objeto donde el index y el valor seran enviados a sentry como información adicional.
 */
export const setTagsSentry = (params: Params) => {
  Sentry.configureScope(scope => {
    for (let index in params) {
      scope.setExtra(index, params[index])
    }
  })
}
