type GeometryGMaps = {
  location: {
    lat: () => number
    lng: () => number
  }
}

type Information = {
  address: string
  streetNbr: string
  postalCode: string
  city: string
  region: string
  lat: number
  lng: number
}

export default function loadAddressInformation(result: any):Information {
  if (!result) throw new Error("Not found")
  const { formatted_address: formatedAddress, address_components } = result[0]
  const response: any = {}
  const geometry = result[0].geometry as GeometryGMaps
  if (geometry) {
    const { lat, lng } = geometry.location
    response.lat = lat()
    response.lng = lng()
  }
  if (!formatedAddress || !address_components) throw new Error("Not found")

  const firstComponent = address_components[0]
  const isTypeStreet = firstComponent.types.indexOf("street_number") !== -1

  if (isTypeStreet) {
    const streetNbr = parseInt(firstComponent.long_name)
    response.streetNbr = streetNbr
  }

  const format: string[] = formatedAddress.split(", ").reverse()
  let [region, area] = format.slice(1, 3)

  if (Number(area)) {
    response.postalCode = area
    area = format.slice(3, 4)[0]
  }

  const regExp = new RegExp(/\d+/ig)
  const numbersFound = area.match(regExp)

  if (numbersFound?.length && format.length > 3) {
    response.postalCode = numbersFound[0]
    area = area.replace(new RegExp(` ?${numbersFound[0]} ?`, "ig"), "")
  }

  const finalAddress = format.slice(3).reverse()
  response.address = finalAddress.join(", ")

  response.city = area
  response.region = region

  return response as Information
}
