/**
 * Todo cliente que tenga estado de Archivado o Prospecto
 * se debe mostrar el inicio del Onboarding, con la URL de Bienvenido,
 * Los demas se deben mostrar con la URL de Home
 */
export const getIndexPathFromUser = (user: User) => {
  const statusWithWelcomePath: (string | null)[] = ["Archivado", "Prospecto", null]

  return statusWithWelcomePath.includes(user.customerStatus)
    ? "/bienvenido" : "/home"
}
