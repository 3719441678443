import React, { useCallback, useEffect, useState } from "react"
import styled from "@emotion/styled"
import Slider from "react-slick"
import { API, graphqlOperation } from "aws-amplify"
import { DateTime } from "luxon"
import * as Sentry from "@sentry/browser"

import {
  getInstrumentsPrice,
  getOpportunities,
} from "../../../../graphql/queries"
import Skeleton from "./Skeleton"
import MarketInfo from "./MarketInfo"

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const MarketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  gap: 10px;
  @media (max-width: 812px) {
    flex-direction: column;
    display: none;
  }
`

const SliderResponsive = styled.div`
  display: block;
  margin-top: 10px;
  @media (min-width: 812px) {
    display: none;
  }
`

const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`

type Body = {
  instruments: Record<string, any>[]
  price_now: Record<string, any>[]
}

const generateRandom = (n: number): number =>
  Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32) * n)

const randomProperties = function (opportunities: any) {
  const keys = Object.keys(opportunities)
  const firstInstrument = keys[generateRandom(5 + generateRandom(3))]
  let secondInstrument = keys[generateRandom(10)]

  let i = 0
  while (secondInstrument === firstInstrument) {
    secondInstrument = keys[generateRandom(10)]
    i++
    if (i > 10) break
  }
  return [
    opportunities[firstInstrument],
    opportunities[secondInstrument],
  ]
}

const MAX_RETRIES = 3

export default function DisplayOpportunities(props: any) {
  const [markets, setMarkets] = useState<any>()
  const [history, setHistory] = useState<Record<string, number[]>>()
  const [retries, setRetries] = useState<number>(0)

  const loadOpportunities = useCallback(async () => {
    if (retries > MAX_RETRIES) return
    try {
      const startDate = DateTime.now()
        .minus({
          month: 1,
        })
        .toFormat("yyyy-MM-dd")
      const endDate = DateTime.now().toFormat("yyyy-MM-dd")
      const {
        data: { getOpportunities: response },
      } = await API.graphql(
        graphqlOperation(getOpportunities, {
          quantity: 20,
        })
      )
      const { body } = JSON.parse(response)
      const long = randomProperties(body.instruments.buy)
      const short = randomProperties(body.instruments.sell)
      const opportunities = long.concat(short)
      setMarkets(opportunities)
      const nemo: any = opportunities.map(({ instrument }: any) => instrument)
      const {
        data: { getInstrumentsPrice: prices },
      } = await API.graphql(
        graphqlOperation(getInstrumentsPrice, {
          nemo: nemo.join(","),
          start: startDate,
          end: endDate,
        })
      )
      const { body: _body } = JSON.parse(prices)
      const { instruments }: Body = _body
      const records: Record<string, number[]> = {}
      instruments.forEach((element: any) => {
        if (!records[element.instrument])
          records[element.instrument] = [element.close]
        else records[element.instrument].push(element.close)
      })
      setHistory(records)
    } catch (err) {
      Sentry.captureException(err)
      setRetries(retries + 1)
    }
  }, [retries])

  useEffect(() => {
    loadOpportunities()
  }, [loadOpportunities])

  if (!markets)
    return (
      <SkeletonWrapper>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonWrapper>
    )

  const opportunities = markets.map((market: Market, index: number) => (
    <MarketInfo
      {...market}
      key={index}
      prices={history && history[market.instrument]}
    />
  ))

  return (
    <div>
      <MarketsWrapper>{opportunities}</MarketsWrapper>
      <SliderResponsive>
        <Slider {...settings}>{opportunities}</Slider>
      </SliderResponsive>
    </div>
  )
}
