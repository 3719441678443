/** @jsx jsx */
import { jsx } from "@emotion/core"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import styled from "@emotion/styled"

import ErrorIcon from "../ui/images/error-icon"

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Label = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
  @media (max-width: 812px) {
    max-width: 300px;
  }
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

const CustomPhoneInput = (props: PropsCustomPhone) => {
  const othersProps: { tabIndex?: number } = {}

  if (props.tabIndex) othersProps.tabIndex = props.tabIndex

  return (
    <FormGroup>
      <LabelWrapper>
        <Label htmlFor={props.name} css={props.styleLabel}>
          {props.label}
        </Label>
        {props.sublabel}
      </LabelWrapper>
      <PhoneInput {...props} countryCodeEditable={false} />
      {props.hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{props.errors}</ErrorMessage>
        </Error>
      )}
    </FormGroup>
  )
}

export default CustomPhoneInput
