import React from "react"
import styled from "@emotion/styled"

import { useAuth } from "../../hooks/auth-context"

import TermsDetails from "./terms-details"

import CheckKycNextStep from "./check-kyc-step"
import KycLayout from "./kyc-layout"
import { termsChile, termsInternational } from "./terms-full"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 7rem;
  @media (max-width: 812px) {
    padding: 3rem 1.5rem 7rem;
  }
`

const CONTRACT_TERMS_KT = 'https://capitariaplatformmaster-master.s3.us-east-2.amazonaws.com/documents/contrato-inversion-kt-v30-Ago2023.pdf'

const CONTRACT_TERMS_CAPITARIA = 'https://capitariaplatformmaster-master.s3.us-east-2.amazonaws.com/documents/contrato-inversion-capitaria-latam-v1-3-Mar2021.pdf'


const Terms = () => {
  const { state } = useAuth()
  const user = state.user as User

  const terms = {
    detail: user.residenceCountry === "Chile" ? termsChile : termsInternational,
    contract:
      user.residenceCountry === "Chile"
        ? CONTRACT_TERMS_CAPITARIA
        : CONTRACT_TERMS_KT,
  }

  return (
    <CheckKycNextStep>
      <KycLayout title="">
        <Container>
          <TermsDetails terms={terms} />
        </Container>
      </KycLayout>
    </CheckKycNextStep>
  )
}

export default Terms
