import React from "react"

const GlobalSimbol = (style: any) => (

  <svg width={style.width} height={style.height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="original 3" clipPath="url(#clip0_891_269)">
      <g id="g10">
        <g id="g12">
          <g id="Clip path group">
            <g id="clipPath16">
              <path id="path18" d="M0 0H15V15H0V0Z" fill="white" />
            </g>
            <g mask="url(#mask0_891_269)">
              <g id="g14">
                <g id="g20">
                  <path id="path22" d="M10.5654 13.4158C10.9879 12.9225 11.3579 12.335 11.6567 11.6667H12.6996C12.12 12.3888 11.3938 12.9858 10.5654 13.4158ZM2.3 11.6667H3.34292C3.64125 12.335 4.01167 12.9225 4.43417 13.4158C3.60542 12.9858 2.87958 12.3888 2.3 11.6667ZM4.43417 1.58375C4.01167 2.07708 3.64125 2.66458 3.34292 3.33333H2.3C2.87958 2.61083 3.60583 2.01417 4.43417 1.58375ZM12.6996 3.33333H11.6567C11.3579 2.66458 10.9879 2.07708 10.5654 1.58375C11.3938 2.01375 12.12 2.61083 12.6996 3.33333ZM12.4858 7.08333C12.4475 6.03917 12.2683 5.05375 11.9742 4.16667H13.2671C13.77 5.03417 14.08 6.02458 14.1454 7.08333H12.4858ZM12.4858 7.91667H14.1454C14.08 8.97542 13.77 9.96542 13.2671 10.8333H11.9742C12.2683 9.94625 12.4475 8.96083 12.4858 7.91667ZM7.91667 10.8333V7.91667H11.6533C11.6125 8.97542 11.4187 9.96542 11.1046 10.8333H7.91667ZM7.91667 14.1329V11.6667H10.7496C10.0679 13.0267 9.06125 13.9508 7.91667 14.1329ZM7.08333 11.6667V14.1329C5.93833 13.9508 4.93167 13.0267 4.25 11.6667H7.08333ZM3.34625 7.91667H7.08333V10.8333H3.895C3.58083 9.96542 3.38708 8.97542 3.34625 7.91667ZM2.51375 7.08333H0.854167C0.92 6.02458 1.22958 5.03417 1.73208 4.16667H3.02583C2.73125 5.05375 2.5525 6.03917 2.51375 7.08333ZM7.08333 4.16667V7.08333H3.34625C3.38708 6.02458 3.58083 5.03458 3.895 4.16667H7.08333ZM7.08333 0.866668V3.33333H4.25C4.93208 1.97333 5.93833 1.04875 7.08333 0.866668ZM11.1046 4.16667C11.4187 5.03458 11.6125 6.02458 11.6533 7.08333H7.91667V4.16667H11.1046ZM7.91667 3.33333V0.866668C9.06125 1.04875 10.0675 1.97333 10.7492 3.33333H7.91667ZM0.854167 7.91667H2.51375C2.55208 8.96083 2.73125 9.94625 3.02583 10.8333H1.73208C1.22958 9.96542 0.92 8.97542 0.854167 7.91667ZM7.5 0C3.35792 0 0 3.35792 0 7.5C0 11.6421 3.35792 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35792 11.6421 0 7.5 0Z" fill="#3B88C3" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_891_269">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

GlobalSimbol.defaultProps = {
  width: "15",
  height: "15",
}

export default GlobalSimbol
