import React from "react"
import styled from "@emotion/styled"

import welcomeIconOn from "../../../images/icn-welcome-on.svg"

const WelcomeIcon = styled.div`
  background-image: url(${welcomeIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 32px;
`

const WelcomeIconOn = () => <WelcomeIcon />

export default WelcomeIconOn
