import { API, graphqlOperation } from "aws-amplify"
import { createEvent } from "../../../graphql/mutations"
import * as Sentry from "@sentry/browser"

type VideoEvent = {
  id: string
  event: {
    element: string
    column: string
    value: boolean
  }
}

export default async function trackEventPq(event: VideoEvent) {
  try {
    await API.graphql(graphqlOperation(createEvent, event))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}
