import React from "react"
import MexicoPage from "./mexico-page"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import styled from "@emotion/styled"

const ContainerUpdatedCreditCard = styled.div`
  min-width: 600px;
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  padding: 25px;
`

const MexicoDeposit = () => {

  const isMexicoCreditCardEnabled = useFeatureIsOn("payment-mexico")

  if (isMexicoCreditCardEnabled)
    return <MexicoPage />

  return (
    <ContainerUpdatedCreditCard>
      Estamos actualizando para que tengas una mejor experiencia con Capitaria
    </ContainerUpdatedCreditCard>
  )

}

export default MexicoDeposit
