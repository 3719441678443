import React from "react"
import styled from "@emotion/styled"

import PublicLayout from "../public-layout"
import SignUpContentV2 from "./sign-up-content/index-v2"
import SignUpFox from "../ui/images/sign-up-fox"

const Container = styled.section`
  display: flex;
  min-height: 100%;
`

const SignUpV2 = () => (
  <PublicLayout>
    <Container>
      <SignUpFox />
      <SignUpContentV2 />
    </Container>
  </PublicLayout>
)

export default SignUpV2
