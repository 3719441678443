import React from "react"
import styled from "@emotion/styled"
import PageLayout from "../page-layout"

import { useAuth } from "../../hooks/auth-context"

import successGif from "../../images/success.gif"

const ContainerStatus = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 600px;
`

const ContainerNuvei = styled.div`
  max-width: 600px;
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
`

const RowDetail = styled.div`
  display: flex;
  align-self: center;
  &>div:first-of-type {
    text-align: right;
    padding-right: 5px;
  }
  &>div:last-of-type {
    text-align: left;
    padding-left: 5px;
  }
  width: 100%;
`

const CellDetail = styled.div`
  width: 100%;
`

const DivWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 556px;
  width: 100%;
  & * {
    font-family: "Open Sans";
  }
`

const Title = styled.h1`
  font-family: "Roboto";
  color: black;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 0px;
  text-align: center;
`

const Info = styled.div`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #080F12;
  text-align: center;
`

type ApprovedComponentProps = {
  data: any
  amount: string
  currency: string
  orderId: string
  email: string
}

const ApprovedComponent = ({
  data,
  amount,
  currency,
  orderId,
  email,
}: ApprovedComponentProps) => {
  return (
    <>
      <img src={successGif} alt="Depósito Exitoso" width={150} />
      <Title>Depósito Exitoso</Title>
      <ContainerNuvei>
        <RowDetail>
          <CellDetail>N° Orden: </CellDetail>
          <CellDetail>{orderId}</CellDetail>
        </RowDetail>
        <RowDetail>
          <CellDetail>Fecha: </CellDetail>
          <CellDetail>{data.userPaymentOptionId}</CellDetail>
        </RowDetail>
        <RowDetail>
          <CellDetail>{currency} $: </CellDetail>
          <CellDetail>{amount}</CellDetail>
        </RowDetail>
        <RowDetail>
          <CellDetail>Tarjeta: </CellDetail>
          <CellDetail>{data.ccCardNumber}</CellDetail>
        </RowDetail>
      </ContainerNuvei>
      <Info>
        <p>
          Recuerde que se ha enviado automáticamente un comprobante de pago
          a su email <strong>{email}</strong>
        </p>
        <p>
          <strong>Importante:</strong> El monto depositado puede que demore
          un tiempo en verse reflejado en la plataforma. Ante cualquier duda
          puedes comunicarte con tu ejecutivo
        </p>
      </Info>
    </>
  )
}

type ErrorWithCreditCardProps = {
  orderId: string
  data: any
}

const ErrorWithCreditCard = ({
  orderId,
  data,
}: ErrorWithCreditCardProps) => {
  return (
    <>
      <Title>Ocurrio un error al procesar el deposito</Title>
      <ContainerNuvei>
        <RowDetail>
          <CellDetail>ID de la Transacción: </CellDetail>
          <CellDetail>{orderId}</CellDetail>
        </RowDetail>
        <RowDetail>
          <CellDetail>Codigo de Error: </CellDetail>
          <CellDetail>{data.errCode}</CellDetail>
        </RowDetail>
        <RowDetail>
          <CellDetail>Descripción del error: </CellDetail>
          <CellDetail>{data.errorDescription}</CellDetail>
        </RowDetail>
      </ContainerNuvei>
    </>
  )
}

const SuccessNuveiPage = ({
  data,
  amount,
  currency,
  orderId,
  paymentMethod,
}: any) => {
  const { state } = useAuth()
  const { email } = state.user as User

  return (
    <PageLayout>
      <ContainerStatus>
        <DivWrapper>
          {paymentMethod === 'creditcard' ?
            (
              data.result === 'APPROVED' ?
                <ApprovedComponent
                  data={data}
                  amount={amount}
                  currency={currency}
                  orderId={orderId}
                  email={email}
                /> :
                <ErrorWithCreditCard data={data} orderId={orderId} />
            ) :
            <>
              <Title>Transferencia en progreso</Title>
              <ContainerNuvei>
                <RowDetail>
                  Si ya realizaste tu transferencia, debes esperar unos minutos para que nos informe el banco.
                </RowDetail>
                <RowDetail>
                  Te llegara un correo con la información correspondiente.
                </RowDetail>
              </ContainerNuvei>
            </>
          }
        </DivWrapper>
      </ContainerStatus>
    </PageLayout>
  )
}

export default SuccessNuveiPage
