import React, { useState } from "react"
import styled from "@emotion/styled"

import Santander from "../ui/images/santander-logo"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import CarouselOptionsBanks from "./carousel-options-banks"
import IconToDisplay from "../ui/images/icon-to-display"
import IconToHide from "../ui/images/icon-to-hide"

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
`

const ContentTitleDescription = styled.div`
  display: inline-flex;
  height: 56px;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  margin-top: 15px;
`
const TitleDescription = styled.h4`
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
`

const ContainerText = styled.div`
  background-color: #fff;
  width: 100%;
  height: 23.875em;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 5px;
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  background-color: transparent;
  margin-bottom: 20px;
`

const Tab = styled.div`
  text-align: center;
  color: #1f2425;
  width: 217px;
  padding-bottom: 0.5rem;
  cursor: pointer;
`

const activeTab = {
  fontWeight: 600,
  borderBottom: "4px solid #30ab76",
}

const SantanderAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BSCHUYMMXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "5100566313",
  },
  sucursal: {
    label: "Sucursal",
    value: "04 - Ciudad Vieja",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const SantanderAccountOtherBanks: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BSCHUYMMXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0004005100566313",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const banks = [
  {
    account: SantanderAccount,
    isDollarAccount: true,
    extraText: true,
    image: <Santander />,
    text: "Desde mi cuenta Santander",
  },
  {
    account: SantanderAccountOtherBanks,
    isDollarAccount: true,
    extraText: true,
    image: <Santander />,
    text: "Desde otros bancos",
  },
]
type Props = {
  showSkip?: boolean
  onClick?: () => void
}

const UruguayDeposit = ({ showSkip = true, onClick = () => { } }: Props) => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClickButton, setIsClickButton] = useState(false)
  const [isExpandedTransfer, setIsExpandedTransfer] = useState(false)
  const [isExpandedDeposit, setIsExpandedDeposit] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = (selectedAccount: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setAccount(selectedAccount)
    setLogo(logo)
  }

  const clickModal = () => {
    setIsModalOpen(false)
    setIsClickButton(true)
  }

  const clickAnimationEnd = () => {
    if (isClickButton) {
      onClick()
    }
  }
  const handleClickTransfer = () => {
    setIsExpandedTransfer(!isExpandedTransfer)
  }
  const handleClickDeposit = () => {
    setIsExpandedDeposit(!isExpandedDeposit)
  }

  return (
    <>
      <Tabs>
        <Tab
          style={activeTab}
        >
          Transferencia
        </Tab>
      </Tabs>
        <BankTransference>
          <br />
          <CarouselOptionsBanks
            listBanks={banks}
            openModal={openModal}
          />
          <ContentTitleDescription onClick={handleClickTransfer}>
            <TitleDescription>
              Condiciones en caso de transferencia
            </TitleDescription>
            {isExpandedTransfer ? <IconToHide /> : <IconToDisplay />}
          </ContentTitleDescription>

          {isExpandedTransfer && (
            <ContainerText>
              <Text>
                La cuenta bancaria que el cliente brinde al momento de la apertura
                de su cuenta, para efectos de depósitos y rescates, deberá ser de la
                misma moneda en la que apertura la cuenta en KTFG. De no ser así,
                KTFG no se responsabilizará por la demora y rechazo de la operación
                de rescate que el cliente solicite, ya que operaciones de
                transferencia interbancaria con diferente moneda no son aceptadas
                por los bancos y estas son rechazadas a los días, retrasando así la
                operación de rescate del cliente.
              </Text>

              <Text>
                Se debe enviar comprobante de transferencia al email
                depositos@capitaria.com, con copia a su Ejecutivo de Inversiones
                y en el asunto debe ir KT depósito.
              </Text>

              <Text>
                Las transferencias de depósito deben de enviarse al email
                depositos@capitaria.com, con copia a su Ejecutivo de Inversiones y
                en el asunto debe ir KT depósito.
              </Text>

              <Text>
                NO SE ACEPTAN DEPÓSITOS PROVENIENTES DE CUENTAS DE TERCEROS.
                Dicha transferencia deberá ser de la cuenta bancaria que se
                encuentra ya registrada en el sistema. Si esto sucede, se realizará
                un retorno de operación de la transferencia, y se devolverá el dinero
                a la cuenta bancaria de la cual se realizó la transferencia.
              </Text>
            </ContainerText>
          )}
          <ContentTitleDescription onClick={handleClickDeposit}>
            <TitleDescription>
              Condiciones en caso de depósitos
            </TitleDescription>
            {isExpandedDeposit ? <IconToHide /> : <IconToDisplay />}
          </ContentTitleDescription>
          {isExpandedDeposit && (
            <ContainerText>
              <Text>
                NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO,
                todo depósito en efectivo será devuelto a la cuenta suscrita en el
                contrato de apertura.
              </Text>

              <Text>

                NO SE ACEPTARÁN DEPÓSITOS DE TERCEROS, ya sea en cheque o
                transferencias bancarias, o desde cuentas bancarias no
                suscritas al contrato de apertura.
              </Text>

              <Text>
                Se debe enviar foto del cheque del titular de la cuenta, previo
                depósito por buzonera y copia del depósito efectuado al agente
                comercial.
              </Text>

              <Text>
                Todo depósito tendrá hasta 72 horas hábiles para ser reflejado
                en la cuenta de trading.
              </Text>

              <Text>
                Toda transacción entre cuentas internacionales tiene un costo
                de transferencia asociado dependiendo del banco de origen del cliente.
              </Text>
            </ContainerText>
          )}
        </BankTransference>

      <CustomModal
        title="Información para transferencia"
        isOpen={isModalOpen}
        closeModal={closeModal}
        animationEnd={clickAnimationEnd}
      >
        <BankModal
          logo={logo}
          account={account}
          showSkip={showSkip}
          onClick={clickModal}
        />
      </CustomModal>
    </>
  )
}

export default UruguayDeposit
