import React, { useState } from "react"
import styled from "@emotion/styled"
import Select, { components } from 'react-select'

import AnswerCheckbox from "./answer-checkbox"
import { renderWithBreakLine } from "../../helpers/break-line-from-text"
import DropdownIndicatorIcon from "../ui/images/dropdown-indicator-icon"
import DropdownCheckbox from "./dropdown-checkbox"


const ContainerAnswer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

const QueryWrapper = styled.div`
  padding-top: 2rem;
  max-width: 100%;
  @media (max-width: 768px) {
    max-width: 750px;
  }
  @media (max-width: 425px) {
    max-width: 400px;
  }
`

const Query = styled.p`
  font-family: "Open Sans";
  font-weight: 600;
  line-height: 22px;
  color: #1f2425;
`

const Description = styled.p`
  font-family: "Open Sans";
  color: #1f2425;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const ContainerAnswersRadio = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-around;
`

const AnswerLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AnswerRadio = styled.input`
  margin-right: 5px;
  transform: scale(1.3);
  accent-color: #2ba770;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-left: 30px;
  @media (max-width: 700px) {
    margin-left: 15px;
  }
`

const AnswerText = styled.p`
  width: calc(100% - 60px);
`

type OptionChange = {
  answerId: string
  value?: boolean
}

type Props = {
  name: string
  query: string
  type: string
  description?: string
  answers: { id: string; text: string }[]
  onClick: (answerId: string, value?: boolean) => void
  multiSelectedOptionsChange?: (options: OptionChange[]) => void
}

const Question = ({
  name,
  query,
  type,
  description,
  answers,
  onClick,
  multiSelectedOptionsChange,
}: Props) => {
  const options = answers.map((answer) => ({
    value: answer.id,
    label: answer.text,
  }))

  const handleMultiOptionChange = (listSelectedOptions: { value: string, selected?: boolean }[]) => {
    if (multiSelectedOptionsChange)
      multiSelectedOptionsChange(listSelectedOptions.map(option => ({ answerId: option.value, value: option.selected })))
  }

  const handleChange = (selectedOption: any) => {
    if (type === "dropdown") {
      onClick(selectedOption.value, true)
    } else {
      console.log("No hay opciones seleccionadas")
    }
  }

  const onlyDropdownStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#44D091' : '#ffffff',
      color: state.isSelected ? '#ffff !important' : '#000000',
    }),
    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      padding: '0 10px',
      borderColor: '#919EAB',
      borderStyle: 'solid',
      borderWidth: '1px',
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  }

  const [answerSelectedCheckbox, setAnswerSelectedCheckbox] = useState<string | null>(null)

  const handleCheckboxChange = (id: string) => {
    if (id !== answerSelectedCheckbox) {
      onClick(id)
      setAnswerSelectedCheckbox(id)
    }
  }


  return (
    <QueryWrapper>
      <Query>{renderWithBreakLine(query)}</Query>
      <Description>{description}</Description>
      <ContainerAnswer>
        {type === "multi-checkbox" ? (
          <DropdownCheckbox
            options={options}
            onChange={handleMultiOptionChange}
            placeholder="Selecciona una opcion..."
          />
        ) : type === "dropdown" ? (
          <Select
            options={options}
            isMulti={false}
            placeholder="Selecciona una opcion..."
            hideSelectedOptions={false}
            isClearable={false}
            onChange={handleChange}
            styles={onlyDropdownStyles}
            components={{
              DropdownIndicator: ({ children, ...rest }) => (
                <components.DropdownIndicator {...rest}>
                  <DropdownIndicatorIcon />
                </components.DropdownIndicator>
              )
            }}
          />
        ) : type === "radio" ? (
          <ContainerAnswersRadio>
            {answers.map((answer) => (
              <FormGroup key={answer.id}>
                <AnswerLabel htmlFor={answer.id} onClick={() => onClick(answer.id)}>
                  <AnswerRadio
                    type="radio"
                    id={answer.id}
                    name={name}
                    value={answer.id}
                    onChange={() => onClick(answer.id)}
                  />
                  <AnswerText>{answer.text}</AnswerText>
                </AnswerLabel>
              </FormGroup>
            ))}
          </ContainerAnswersRadio>
        ) : (
          answers.map((answer, idx) => (
            <FormGroup key={`question-${idx}`}>
              <AnswerCheckbox
                key={answer.id}
                id={`${name}-${idx}`}
                name={name}
                label={answer.text}
                onClick={() => handleCheckboxChange(answer.id)}
                isSelected={answer.id === answerSelectedCheckbox}
              />
            </FormGroup>
          ))
        )}
      </ContainerAnswer>
    </QueryWrapper>
  )
}

export default Question
