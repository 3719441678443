import React from "react"
import styled from "@emotion/styled"

const SkeletonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Animation = styled.div`
  animation: color 1.2s infinite linear;
  background: #ccc;
  height: 20px;
  border-radius: 5px;
  margin-bottom: 7px;
`

const Title = styled(Animation)`
  width: 100%;
`

const Columns = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 7px;
`

const Nemo = styled(Animation)`
  width: 90px;
`

const Price = styled(Nemo)``

const Graph = styled(Animation)`
  width: 90px;
  height: 60px;
`

const ColumnsInstrument = styled(Columns)`
  display: block;
`

const Skeleton = () => (
  <SkeletonWrapper>
    <Title>&nbsp;</Title>
    <Columns>
      <ColumnsInstrument>
        <Nemo />
        <Price />
      </ColumnsInstrument>
      <Graph />
    </Columns>
  </SkeletonWrapper>
)

export default Skeleton
