import React from "react"
import styled from "@emotion/styled"

import loadingScreen from "../../../images/loading-screen.svg"

const LoadingScreen = styled.div`
  background-image: url(${loadingScreen});
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
`

const LoginScreenIcon = () => <LoadingScreen />

export default LoginScreenIcon
