import React, { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "@emotion/styled"

import CustomButton from "../ui/custom-button"
import CopyIcon from "../ui/images/copy-icon"
import CheckIcon from "../ui/images/check-icon"
import { trackEvent } from "../../helpers/tracker"

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 1rem;
`

const Content = styled.div`
  margin-top: 1rem;
  width: 100%;
`

const ModalField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.5rem 0;
`

const Label = styled.p`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 0.75em;
  line-height: 16px;
  margin: 0;
`

const Value = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
  line-height: 16px;
  margin: 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
`

const buttonStyle = {
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "0.625em",
  lineHeight: "14px",
  background: "linear-gradient(180deg, #30ab76 0%, #71c552 100%)",
  margin: "1rem 0",
  padding: "0 6px",
  height: "46px",
  width: "156px",
}

const DoItLater = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625em;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #1d1d1d;
  cursor: pointer;
`

const ImgWrapper = styled.div`
  cursor: pointer;
`

const fields: string[] = [
  "accountOwner",
  "rut",
  "accountType",
  "accountNumber",
  "ruc",
  "swift",
  "dollarAccount",
  "ciiDollarAccount",
  "solesAccount",
  "ciiSolesAccount",
  "bankCodeId",
  "intermediaryBank",
  "accountNBR",
  "abaRouting",
  "intermediarySwift",
  "sucursal",
  "email",
]

type Props = {
  logo: any
  account: BankAccount
  showSkip: boolean
  onClick: () => void
}

const BankModal = ({ logo, account, showSkip, onClick }: Props) => {
  const [copiedId, setCopiedId] = useState(-1)

  const bankAccount = account as {
    [key: string]: AccountField
  }

  return (
    <Modal>
      {logo}

      <Content>
        {fields.map((f, idx) => {
          if (bankAccount[f]) {
            return (
              <ModalField key={`modal-field${idx}`}>
                <div>
                  <Label>{bankAccount[f].label}</Label>
                  <Value>{bankAccount[f].value}</Value>
                </div>

                <CopyToClipboard
                  text={bankAccount[f].value}
                  onCopy={() => setCopiedId(idx)}
                >
                  <ImgWrapper>
                    {idx === copiedId ? <CheckIcon /> : <CopyIcon />}
                  </ImgWrapper>
                </CopyToClipboard>
              </ModalField>
            )
          }

          return null
        })}
      </Content>

      <Actions>
        {showSkip && (
          <DoItLater
            onClick={() => {
              onClick()
              trackEvent("kyc:deposit:skip")
            }}
          >
            Lo haré despues
          </DoItLater>
        )}
        <CustomButton
          style={showSkip ? buttonStyle : { ...buttonStyle, width: "100%" }}
          onClick={onClick}
        >
          ¡YA DEPOSITÉ!
        </CustomButton>
      </Actions>
    </Modal>
  )
}

export default BankModal
