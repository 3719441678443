import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import styled from "@emotion/styled"
import CursoIntermedio from '../ui/images/curso_intermedio_cover.png'
import CursoAvanzado from '../ui/images/curso_avanzado_cover.png'
import CursoBasico from '../ui/images/curso_basico_cover.png'


const ContainerCourse = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1024px;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 700px) {
    max-width: 500px;
  flex-direction: column;
  }
`


const ContainerSingleCourse = styled.div`
  width: 250px;
  height: 198px;
  padding: 0;
  margin: 0 8px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
  transition: box-shadow 1s;
  display:flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  @media (max-width: 700px) {
    margin: 10px ;
  }

  @media (max-width: 920px) {
    margin: 10px;
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 160px;
    height: 127px;
  }

  &:hover {
    img {
      filter: saturate(0.5);
    }
  }
  &.active {
    border: 1px solid #44d091;
    box-shadow: 0px 15px 30px #dde4ee;
    img {
      filter: none;
    }
  }
`

const Image = styled.img`
  width: 100%;
`

const Detail = styled.p`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1px;
  margin: 0;
  font-weight: 600;
  font-family: "Roboto";
  max-width: 180px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  @media (min-width: 921px) {
    font-size: 14px;
  }
`


type DataCourseProps = {
  image: string
  detail: string
}

const courses: DataCourseProps[] = [
  {
    image: CursoBasico,
    detail: "Curso Trading básico",
  },
  {
    image: CursoIntermedio,
    detail: "Curso Trading intermedio",
  },
  {
    image: CursoAvanzado,
    detail: "Curso Trading avanzado",
  },
]
type SingleCourseProps = {
  data: DataCourseProps
  active: boolean
  onClick?: any
}

const SingleCourse = ({ active, data, onClick }: SingleCourseProps) => {
  const className = active ? "active" : ""

  return (
    <ContainerSingleCourse className={`${className}`} onClick={onClick}>
      <Image src={data.image}></Image>
      <Detail>{data.detail}</Detail>
    </ContainerSingleCourse>
  )
}

type CourseProps = {
  setCourseSelected: Dispatch<SetStateAction<string>>
}

const Course = ({ setCourseSelected }: CourseProps) => {
  const [active, setActive] = useState(-1)

  useEffect(() => {
    if (active !== -1 && courses[active]?.detail !== undefined) {
      setCourseSelected(courses[active].detail)
    }
  }, [active, setCourseSelected])

  return (
    <ContainerCourse>
      {courses.map((data, idx) => (
        <SingleCourse active={idx === active} onClick={() => setActive(idx)} data={data} />
      ))}
    </ContainerCourse>
  )
}

export default Course
