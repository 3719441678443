import React, { useState } from "react"
import styled from "@emotion/styled"
import Course from "./courses"
import CustomButtonV2 from "../ui/custom-button-v2"

import KYC from "../../services/kyc"
import { useAuth } from "../../hooks/auth-context"
import { navigate } from "@reach/router"

const ContainerView = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h3`
  width: 303px;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  text-align: center;
`

const Description = styled.p`
  width: 297px;
  color: #1F2425;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`

const MessageBold = styled.p`
  color: #1F2425;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1px;
`

const OptionSkip = styled.a`
  text-decoration:none;
  color: #1F2425;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin-top: 5%;
`

const disabledButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 44px",
  fontSize: "16px",
  width: "260px",
  height: "50px",
}

const buttonCSS = {
  marginTop: "1rem",
  width: "260px",
  height: "50px",
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: "18px",
    lineHeight: "138.889%",
    textAlign: "center",
  },
}

const loadingButtonCss =  buttonCSS

const CouserSelection = () => {
  const { state } = useAuth()
  const user = state.user as User

  const [courseSelected, setCourseSelected] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = async () => {
    setIsLoading(true)

    const result = await KYC.selectCourse(user, "Sin curso seleccionado")
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  const onSubmit = async () => {
    setIsLoading(true)

    const result = await KYC.selectCourse(user, courseSelected)
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <ContainerView>
      <Title>Selecciona tu curso</Title>
      <Description>
        Como parte de los beneficios al crear tu cuenta, te regalamos los cursos gratis de nuestro Dojo Trading.
      </Description>
      <MessageBold>Selecciona el curso que mas se ajuste a tu nivel</MessageBold>
      <Course setCourseSelected={setCourseSelected} />
      <Description>Te enviaremos los accesos una vez creada tu cuenta</Description>
      <CustomButtonV2
        disabled={courseSelected === ""}
        loading={isLoading}
        onClick={onSubmit}
        style={buttonCSS}
        loadingStyle={loadingButtonCss}
        disabledStyle={disabledButtonStyle}
      >
        Continuar
      </CustomButtonV2>
      {!isLoading && <OptionSkip onClick={handleClick}>Omitir</OptionSkip>}
    </ContainerView>
  )
}
export default CouserSelection
