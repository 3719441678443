import React from "react"
import styled from "@emotion/styled"

import iconStanby from "../../../images/icon-standby-validation.svg"

const StanbyImage = styled.img`
	width: 83px;
	height: 83px;
`

const IconStanby = () => (
  <StanbyImage src={iconStanby} alt="validacion en espera" />
)

export default IconStanby
