import React, { useState } from "react"
import styled from "@emotion/styled"

import steps from "./steps"
import { trackEvent } from "../../../helpers/tracker"
import ModalWistia from "./steps/modal"
import trackEventPq from "./trackEventPq"
import { useAuth } from "../../../hooks/auth-context"

const lastStep = steps[steps.length - 1]

const Container = styled.div`
  width: 860px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px #00000014;
  box-shadow: 0px 3px 14px 0px #0000000a;
  box-shadow: 0px 8px 10px 0px #0000000f;
  border-radius: 5px;
  margin-bottom: 50px;
  @media (max-width: 812px) {
    flex-direction: column;
    width: 90%;
  }
`

const Steps = styled.div`
  display: none;
  @media (min-width: 812px) {
    display: block;
  }
`

const NotActiveStep = ({ onClick, title }: any) => {
  const NotActiveStep = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 4px solid transparent;
    background-color: #f4f6f8;
    border-right: 0.7px solid #c4cdd5;
    cursor: pointer;
    border-bottom: 0.7px solid
      ${title === lastStep.title ? "transparent" : "#C4CDD5"};
  `

  return <NotActiveStep onClick={onClick}>{title}</NotActiveStep>
}

const ActiveStep = ({ title }: any) => {
  const Active = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 4px solid #71c552;
    background-color: #fff;
    border-top: 0.7px solid transparent;
    cursor: pointer;
    border-bottom: 0.7px solid
      ${title === lastStep.title ? "transparent" : "#C4CDD5"};
  `

  return <Active>{title}</Active>
}

const RenderComponent = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const StepsMobile = styled.div`
  background: #f4f6f8;
  display: flex;
  font-size: 11.3px;
  @media (min-width: 812px) {
    display: none;
  }
`

const ActiveStepMobile = ({ title, onClick }: any) => {
  title = title.replace(/\d\./gi, "")
  const TitleMobile = styled.div`
    padding: 12px;
    background-color: #fff;
    border-top: 4px solid #71c552;
    border-right: 0.7px solid
      ${title === lastStep.title ? "transparent" : "#C4CDD5"};
  `

  return <TitleMobile onClick={onClick}>{title}</TitleMobile>
}

const NotActiveStepMobile = ({ title, onClick }: any) => {
  title = title.replace(/\d\./gi, "")
  const TitleMobile = styled.div`
    padding: 12px;
    background-color: #f4f6f8;
    border-top: 4px solid transparent;
    border-right: 0.7px solid
      ${title === lastStep.title ? "transparent" : "#C4CDD5"};
    border-bottom: 0.7px solid #c4cdd5;
  `

  return <TitleMobile onClick={onClick}>{title}</TitleMobile>
}

const CenterText = styled.div`
  text-align: center;
`

const Title = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 812px) {
    font-size: 22px;
  }
`

const TitleWrapper = styled.div`
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 32px;
  width: 860px;
  text-align: center;
  @media (max-width: 812px) {
    width: 90%;
  }
`

const SliderWrapper = styled.div`
  margin-top: 70px;
`
const TitleText = styled.h3`
  color: #1A2225;
  text-align: center;
  font-family: Work Sans;
  font-size: 85%;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -1px;
`

export default function Slider() {
  const { state } = useAuth()
  const user = state.user as User
  const [answer, setAnswer] = useState<number | null>(null)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [wistia, setWistia] = useState<any>("")
  const { component: Component, correctAnswer, question, options } = steps[
    currentStep
  ]
  const renderStep = ({ title }: StepSlider, index: number) =>
    index !== currentStep ? (
      <NotActiveStep
        onClick={() => {
          setAnswer(null)
          setCurrentStep(index)
          trackEvent("bienvenido:videos_pestaña" + (index + 1))
        }}
        title={title}
        key={index}
      />
    ) : (
      <ActiveStep title={title} key={index} />
    )

  const renderStepMobile = (step: StepSlider, index: number) =>
    index !== currentStep ? (
      <NotActiveStepMobile
        title={step.title}
        onClick={() => {
          setAnswer(null)
          setCurrentStep(index)
          trackEvent("bienvenido:videos_pestaña" + (index + 1))
        }}
        key={index}
      />
    ) : (
      <ActiveStepMobile title={step.title} key={index} />
    )

  return (
    <SliderWrapper>
      <Title>
        <TitleWrapper>
          <TitleText>
            Te invitamos a ver nuestros videos introductorios al mundo del trading
          </TitleText>
        </TitleWrapper>
      </Title>
      <Container>
        <CenterText>
          <StepsMobile>{steps.map(renderStepMobile)}</StepsMobile>
          <Steps>{steps.map(renderStep)}</Steps>
        </CenterText>
        <RenderComponent>
          <Component
            setShowModal={setShowModal}
            showModal={showModal}
            wistia={wistia}
            setWistia={setWistia}
            salesforceId={user.salesforceId || ""}
          />
        </RenderComponent>
      </Container>
      {showModal && (
        <ModalWistia
          onSkipClick={() => {
            trackEventPq({
              event: {
                element: `video${currentStep + 1}`,
                column: "omit",
                value: true
              },
              id: user.salesforceId || "",
            })
            trackEvent(`bienvenido:video${currentStep + 1}:omit`)
            setShowModal(false)
          }}
          onSubmitClick={() => {
            if (answer === null) return
            trackEvent(
              `bienvenido:video${currentStep + 1}:${answer === correctAnswer ? "correct" : "incorrect"
              }`
            )
            trackEventPq({
              event: {
                element: `video${currentStep + 1}`,
                column: "answer",
                value: answer === correctAnswer
              },
              id: user.salesforceId || "",
            })
            setShowModal(false)
          }}
          setAnswer={setAnswer}
          question={question}
          options={options}
        />
      )}
    </SliderWrapper>
  )
}
