import React from "react"
import styled from "@emotion/styled"

import santander from "../../../images/santander.png"

const Santander = styled.img`
  width: 103px;
  height: 60px;
`

const SantanderLogo = () => <Santander src={santander} alt="Santander" />

export default SantanderLogo
