import Webinars from "../../../images/img-webinars.png"
import EconomicCalendar from "../../../images/img-economic-calendar.png"
import Youtube from "../../../images/img-youtube.png"

const sourcesList: Source[] = [
  {
    icon: Webinars,
    title: "No te pierdas nuestros webinars",
    href: "https://www.blog.capitaria.com/eventos-trading-capitaria",
    label: "webinars",
  },
  {
    icon: EconomicCalendar,
    title: "Atento al calendario económico",
    href: "https://www.blog.capitaria.com/calendario-economico",
    label: "calendario",
  },
  {
    icon: Youtube,
    title: "Visita nuestro canal de Youtube",
    href: "https://www.youtube.com/c/Capitaria",
    label: "youtube",
  },
]

export default sourcesList
