import React from "react"
import styled from "@emotion/styled"

const ProgressBackground = styled.div`
  background: #dfe3e8;
  height: 8px;
  width: 100%;
`

const ProgressAnimation = styled.div`
  height: 8px;
  background-color: #44d091;
  width: 0;
`

type Props = {
  width?: number
}

const Progressbar = ({ width = 0 }: Props) => (
  <>
    <ProgressBackground>
      <ProgressAnimation style={{ width: `${width}%` }} />
    </ProgressBackground>
  </>
)

export default Progressbar
