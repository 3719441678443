import styled from "@emotion/styled"
import React, { useState } from "react"

import { useAuth } from "../../hooks/auth-context"
import KYC from "../../services/kyc"

import Mt5Logo from "../ui/images/mt5-logo"
import CustomButtonV2 from "../ui/custom-button-v2"
import { navigate } from "@reach/router"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h3`
  width: 308px;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  color: #1F2425;
  text-align: center;
`

const Text = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #1F2425;
  text-align: center;
  width: 209px;
`

const DownloadOption = styled.div`
  border-radius: 5px;
  border: 1px solid #E0E0E0;
  background: #fff;
  width: 141px;
  height: 141px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Link = styled.a``

const TextDownload = styled.a`
  width: 98px;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.5px;
  text-decoration: none;
  margin-top: 10px;
`
const InformativeText = styled.p`
  width: 284px;
  color: #1F2425;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`

const saveButtonStyle = {
  marginTop: "1.5rem",
  padding: "12px 48px",
  width: "260px",
  backgroundColor: "#2ba770",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)"
}

const loadingButtonStyle = saveButtonStyle

const DownloadMt5 = () => {
  const { state } = useAuth()
  const user = state.user as User

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)

    const result = await KYC.updatePlatform(user)
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }

  return (
    <Container>
      <Title>Habilitación de Plataforma</Title>
      <Text>
        Mientras validamos tu depósito, y te enviamos los accesos, puedes descargar la plataforma acá
      </Text>
      <DownloadOption>
        <Link
          target="_blank"
          href="https://capitaria.zendesk.com/hc/es/articles/360043623911--C%C3%B3mo-descargo-la-plataforma-Metatrader-5-"
        >
          <Mt5Logo />
        </Link>
      </DownloadOption>
      <TextDownload
        target="_blank"
        href="https://capitaria.zendesk.com/hc/es/articles/360043623911--C%C3%B3mo-descargo-la-plataforma-Metatrader-5-"
      >
        Descargar
      </TextDownload>
      <br />
      <InformativeText>
        Para habilitar tu plataforma, es necesario revisar tu perfil de riesgo, conocimientos e información personal
      </InformativeText>
      <CustomButtonV2
        type="button"
        loading={isLoading}
        style={saveButtonStyle}
        loadingStyle={loadingButtonStyle}
        onClick={onSubmit}
      >
        Continuar
      </CustomButtonV2>
    </Container>
  )
}

export default DownloadMt5
