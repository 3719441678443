import React from "react"
import styled from "@emotion/styled"

import loginSidebarBg from "../../images/login-sidebar-bg.png"
import DailyLogo from "../ui/images/daily-logo"
import WeeklyLogo from "../ui/images/weekly-logo"

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 384px;
  padding: 1.5rem 3rem 3rem;
  background-image: url(${loginSidebarBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 812px) {
    display: none;
  }
`

const Title = styled.h2`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 27.65px;
  line-height: 116%;
  color: #ffffff;
`
const Description = styled.p`
  margin: 0;
  width: 256px;
  font-size: 11.11px;
  line-height: 120%;
  color: #ffffff;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  margin-top: 1rem;
`

const Separator = styled.div`
  width: 2rem;
`

const ForgotPasswordSidebar = () => (
  <Sidebar>
    <Title>Tu dosis diaria de noticias financieras.</Title>

    <Description>
      Desde la última IPO hasta el nuevo tweet de Trump. Informativo, ingenioso
      y con todo lo que necesitas para comenzar tu día en solo 5 minutos.
    </Description>

    <LogoWrapper>
      <DailyLogo />
      <Separator />
      <WeeklyLogo />
    </LogoWrapper>
  </Sidebar>
)

export default ForgotPasswordSidebar
