import React from "react"
import styled from "@emotion/styled"

type Props = {
  message: JSX.Element
  isOpen: boolean
  onClick: () => void
}

const Tooltip = ({ message, isOpen, onClick }: Props) => {
  const Wrapper = styled.div`
    position: absolute;
    bottom: 22px;
    right: 0px;
    background-color: #303a3e;
    color: #fff;
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    width: 200px;
    text-align: center;
    line-height: 15px;
    font-family: Roboto;
    font-weight: 400;
  `

  return isOpen ? <Wrapper onClick={onClick}>{message}</Wrapper> : null
}

export default Tooltip
