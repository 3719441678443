import React from "react"

export const renderWithBreakLine = (query: string) => {
  return query.split("<br /><br />").reduce(reduceElement, [])
}

export const reduceElement = (
  acumulator: (string | JSX.Element)[],
  element: string
) => {
  if (!acumulator.length) {
    return [element]
  }
  return [...acumulator, <br key="br-1" />, <br key="br-2" />, element]
}
