import React from "react"
import styled from "@emotion/styled"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import DisplayOpportunities from "./DisplayOpportunities"
import Disclaimer from "./Disclaimer"

const Container = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 1px 18px rgba(0, 0, 0, 0.04),
    0px 3px 5px rgba(0, 0, 0, 0.08);
  margin-bottom: 40px;
  @media (max-width: 812px) {
    width: 350px;
  }
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  @media (max-width: 812px) {
    text-align: center;
    font-size: 20px;
  }
`

const SubtitleWrapper = styled.div`
  margin-top: 42px;
  line-height: 20px;
  font-weight: 400;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  text-align: center;
`

const Subtitle = styled.div`
  width: 532px;
  font-size: 16px;
  @media (max-width: 812px) {
    font-size: 12px;
  }
`

const Opportunities = () => (
  <Container>
    <Title>Oportunidades pasando en este momento</Title>
    <DisplayOpportunities />
    <SubtitleWrapper>
      <Subtitle>
        La gran ventaja de nuestro producto es que{" "}
        <b>
          puedes tomar oportunidades cuando el precio sube y tambien cuando el
          precio baja
        </b>
      </Subtitle>
    </SubtitleWrapper>
    <Disclaimer />
  </Container>
)

export default Opportunities
