import React, { useState } from "react"
import { useQueryString } from "@capitaria/capitaria-utms"

import { useAuth } from "../../hooks/auth-context"
import { changePassword, forgotPasswordSendCode } from "../../services/auth"

import SendCode from "./send-code"
import ChangePassword from "./change-password"

const ForgotPasswordContent = () => {
  const { state } = useAuth()
  const { parsedQueryString } = useQueryString()

  const [email, setEmail] = useState("")
  const [userNeedCodeValidation, setUserNeedCodeValidation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const sendCode = async (email: string) => {
    setLoading(true)

    const result = await forgotPasswordSendCode(email)

    setEmail(result.email)
    setUserNeedCodeValidation(result.userNeedCodeValidation)
    setLoading(result.loading)
    setError(result.error)
  }

  const changePasswordHandler = async (code: string, password: string) => {
    setLoading(true)

    const result = await changePassword(
      email,
      password,
      code,
      parsedQueryString.mc_origen
    )

    if (result.user) {
      state.setUser(result.user)
    } else {
      setEmail(result.email)
      setLoading(result.loading)
      setError(result.error)
    }
  }

  if (userNeedCodeValidation) {
    return (
      <ChangePassword
        email={email}
        loading={loading}
        onSubmit={changePasswordHandler}
        submitError={error}
      />
    )
  } else {
    return (
      <SendCode loading={loading} onSubmit={sendCode} submitError={error} />
    )
  }
}

export default ForgotPasswordContent
