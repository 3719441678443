import React from "react"
import styled from "@emotion/styled"

import warningIcon from "../../../images/warning-icon.svg"

const WarningIconDiv = styled.div`
  background-image: url(${warningIcon});
  background-repeat: no-repeat;
  background-size: cover;
  width: 39px;
  height: 33.79px;
`

const WarningIcon = () => <WarningIconDiv />

export default WarningIcon
