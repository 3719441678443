import React from "react"
import styled from "@emotion/styled"

import operativesAspectsIconOn from "../../../images/icn-operatives-aspects-on.svg"

const OperativesAspectsIconOn = styled.div`
  background-image: url(${operativesAspectsIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const OperativesAspectsIcon = () => <OperativesAspectsIconOn />

export default OperativesAspectsIcon
