import React from "react"
import styled from "@emotion/styled"

import PublicLayout from "../public-layout"
import ForgotPasswordSidebar from "./forgot-password-sidebar"
import ForgotPasswordContent from "./forgot-password-content"

const Container = styled.section`
  display: flex;
  min-height: 100%;
`

const ForgotPassword = () => (
  <PublicLayout>
    <Container>
      <ForgotPasswordSidebar />
      <ForgotPasswordContent />
    </Container>
  </PublicLayout>
)

export default ForgotPassword
