import React, { KeyboardEvent, useRef, useState } from "react"
import { navigate } from "@reach/router"
import { Formik } from "formik"
import styled from "@emotion/styled"

import { useAuth } from "../../../hooks/auth-context"
import KYC from "../../../services/kyc"
import HelpPopover from "../../ui/help-popover"
import CheckKycNextStep from "../check-kyc-step"
import KycLayout from "../kyc-layout"
import PersonalSchema from "../schemas/personal-info"
import fields from "../personal-info/fields"
import mapComponents from "../personal-info/mapComponents"
import ResultsFound from "./helpers/results-found"
import FindingResults from "./helpers/finding-results"
import ErrorIcon from "../../ui/images/error-icon"
import loadPredictions from "./loadPredictions"
import {
  buttonStyle,
  CheckboxWrapper,
  Error,
  ErrorMessage,
  Form,
  InputWrapper,
  loadingButtonStyle,
  Label,
  AddressWrapper,
  LabelFlex,
  AddressLabel
} from "./styles"

import "./styles.css"
import ModalGoogleMaps from "./modal"
import CustomButtonV2 from "../../ui/custom-button-v2"

type Personal = {
  residenceCountry: string
  city?: string
  address: string
  job: string
  isPEP: string
  occupation: string
  addressExtension: string
  postalCode?: string
  latitude?: number
  longitude?: number
}

const ManualAddressEntry = styled.div`
  background: #000;
  color: #FFF;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.015;
  line-height: 20px;
`

const QuestionPep = styled.p`
  font-family: Nunito;
  font-size: 13.33px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-right: 20px;
`

const QuestionPepWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
`

const BoxAnswerPep = styled.button`
  width: 102px;
  height: 64px;
  border-radius: 5px;
  border: 1px solid #C4CDD5;
  margin-right: 20px;
  cursor: pointer;
`

interface ButtonToggleProps {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}

const ButtonToggle = styled(BoxAnswerPep)<ButtonToggleProps>`
  opacity: 0.7;
  ${({ active }) =>
    active &&
    `
    background-color: #fff;
    border-color: #2ba770;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08), 0px 3px 14px 0px rgba(0, 0, 0, 0.04), 0px 8px 10px 0px rgba(0, 0, 0, 0.06);
    opacity: 1;
  `}
`

const ContainerBoxAnswerPep = styled.div`
  display: flex;
  flex-direction: row;
`

const answersOfPep = {"Si": "true", "No": "false"}
type ToggleGroupProps = {
  value: string
  hasError?: boolean | ""
  errorMessage?: string
  onClick: (event: string) => void
}

const PersonalInfo = () => {
  const { state } = useAuth()
  const user = state.user as User
  const [isLoading, setIsLoading] = useState(false)
  const [address, setAddress] = useState<string>("")
  const [streetNumber, setStreetNumber] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [postalCode, setPostalCode] = useState<string>("")
  const [countryCode, setCountryCode] = useState<string>("CL")
  const [latitude, setLatitude] = useState<number>(0)
  const [longitude, setLongitude] = useState<number>(0)
  const [notSelected, setNotSelected] = useState<boolean>(true)
  const [gmapsResults, setGmapResults] = useState<any>([])
  const [addressError, setAddressError] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const timeoutRef = useRef<number>()

  function ToggleGroup({
    value,
    hasError,
    errorMessage,
    onClick,
  }: ToggleGroupProps) {
    const values = Object.entries(answersOfPep)
    return (
      <div>
        {values.map(([key, label]) => (
          <ButtonToggle active={value === label} onClick={()=>onClick(label)}>
            {key}
          </ButtonToggle>
        ))}
        {hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </Error>
      )}
      </div>
    )
  }

  const formikInitialValues: Personal = {
    city: "",
    residenceCountry: "",
    address: "",
    job: "",
    occupation: "",
    isPEP: "",
    addressExtension: "",
    postalCode: "",
  }

  const onSubmit = async (values: Personal) => {
    if (!streetNumber) return
    setIsLoading(true)
    const result = await KYC.updatePersonalInfo(user, {
      ...values,
      address: address,
      city,
      postalCode,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    })
    state.setUser(result.user)
    setIsLoading(false)
    navigate(`/${result.user.kycNextStep}`)
  }
  const evaluate = (condA: any, condB: any) => condA && condB

  return (
    <CheckKycNextStep>
      <KycLayout title="Información Personal" description="">
        <Formik
          initialValues={formikInitialValues}
          validationSchema={PersonalSchema}
          onSubmit={onSubmit}
        >
          {props => {
            const {
              values,
              setFieldValue,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props
            return (
              <Form onSubmit={values => handleSubmit(values)} autoComplete="off">
                <InputWrapper>
                  {fields.map((field: FieldPersonalInfo, index: number) => {
                    const name = field.name as keyof typeof values
                    if (field.dependsOf && !field.dependsOf(values.job))
                      return null
                    const Component = mapComponents[field.type]
                    const commonProps = {
                      ...field,
                      key: index,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      errors: errors[name],
                      value: values[name],
                      errorMessage: errors[name],
                      type: "text",
                      autocomplete: "off",
                      hasError: evaluate(errors[name], touched[name]),
                      onKeyDown: (
                        event: KeyboardEvent<HTMLInputElement>
                      ) => { },
                    }
                    if (name === "address") {
                      delete commonProps.value
                      commonProps.onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event)
                        setAddressError("")
                        if (timeoutRef.current) {
                          clearTimeout(timeoutRef.current)
                        }
                        timeoutRef.current = window.setTimeout(async () => {
                          if (address.length > 3 && notSelected) {
                            const { result, error } = await loadPredictions({
                              countryCode,
                              value: event.target.value,
                            })
                            if (!error) {
                              setGmapResults(result)
                              setError(false)
                            } else {
                              setError(true)
                            }
                            if (!/\d/.test(event.target.value)) {
                              setError(true)
                            }
                          }
                        }, 3000)
                      }
                      commonProps.onKeyDown = (
                        event: KeyboardEvent<HTMLInputElement>
                      ) => {
                        if ([91, 32].includes(event.keyCode)) return
                        setStreetNumber("")
                        setGmapResults([])
                        setNotSelected(true)
                        setAddress(event.currentTarget.value)
                        setError(false)
                        setCity("")
                        setFieldValue("address", event.currentTarget.value)
                      }
                      commonProps.hasError =
                        evaluate(touched[name], !streetNumber) ||
                        commonProps.hasError
                      commonProps.errorMessage = evaluate(
                        touched[name],
                        !streetNumber
                      )
                        ? "Selecciona una dirección con número"
                        : errors[name]
                    }
                    if (["select", "country", "date"].includes(field.type)) {
                      commonProps.onChange = (event: any) => {
                        if (field.type === "country") {
                          setCountryCode(event.code)
                          setFieldValue(name, event.value)
                          return
                        }
                        setFieldValue(name, event)
                      }
                    }
                    if (name === "address") {
                      return (
                        <AddressWrapper key={name}>
                          {showModal && (
                            <ModalGoogleMaps
                              setShowModal={setShowModal}
                              setFieldValue={setFieldValue}
                              address={address}
                              city={city}
                              setCity={setCity}
                              setAddress={setAddress}
                              values={values}
                              streetNumber={streetNumber}
                              setStreetNumber={setStreetNumber}
                              setError={setError}
                              setGmapResults={setGmapResults}
                              setAddressError={setAddressError}
                            />
                          )}
                          <Label>
                            <LabelFlex>
                              <AddressLabel>
                                Dirección{" "}
                              </AddressLabel>
                              <div>
                                {addressError && (
                                  <Error>
                                    {<ErrorIcon />}{" "}
                                    <ErrorMessage>{addressError}</ErrorMessage>
                                  </Error>
                                )}
                              </div>
                            </LabelFlex>
                          </Label>
                          <Component {...commonProps} value={values[name]} />
                          {gmapsResults.length === 0 &&
                            city.length === 0 &&
                            values.address.length > 0 &&
                            notSelected &&
                            !error ? (
                            <FindingResults>Buscando...</FindingResults>
                          ) : (
                            <div className="wrapper-results">
                              <ResultsFound
                                key={index}
                                results={gmapsResults}
                                setter={{
                                  setAddressError,
                                  setFieldValue,
                                  setGmapResults,
                                  setNotSelected,
                                  setAddress,
                                  setLatitude,
                                  setLongitude,
                                  setPostalCode,
                                  setStreetNumber,
                                  setCity,
                                  setShowModal,
                                  setError,
                                }}
                              />
                            </div>
                          )}
                          {values.address.length > 0 && error && (
                            // gmapsResults.length >= 0 && (
                            <ManualAddressEntry
                              onClick={() => setShowModal(true)}
                            >
                              Ingresa tu dirección manualmente
                            </ManualAddressEntry>
                          )}
                        </AddressWrapper>
                      )
                    }
                    return (
                      <Component
                        {...commonProps}
                        key={index}
                        value={values[name]}
                        autocomplete="off"
                      />
                    )
                  })}
                  <CheckboxWrapper>
                    <QuestionPepWrapper>
                      <QuestionPep>¿Eres una persona políticamente expuesta?</QuestionPep>
                        <HelpPopover
                        text="Los chilenos o extranjeros que desempeñan o hayan desempeñado
                        funciones públicas destacadas en un país, así como sus cónyuges y
                        parientes hasta el segundo grado de consanguinidad y quienes hayan
                        celebrado negocios en conjunto."
                      />
                    </QuestionPepWrapper>
                    <ContainerBoxAnswerPep>
                      <ToggleGroup
                        value={values.isPEP}
                        hasError={evaluate(errors.isPEP, touched.isPEP)}
                        errorMessage={errors.isPEP}
                        onClick={(value:any) => setFieldValue("isPEP", value)}
                      />
                    </ContainerBoxAnswerPep>
                  </CheckboxWrapper>
                </InputWrapper>

                <CustomButtonV2
                  style={buttonStyle}
                  type="submit"
                  loading={isLoading}
                  loadingStyle={loadingButtonStyle}
                >
                  CONTINUAR
                </CustomButtonV2>
              </Form>
            )
          }}
        </Formik>
      </KycLayout>
    </CheckKycNextStep>
  )
}

export default PersonalInfo
