import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import PageLayout from "../page-layout"
import { MexicoBankTransfer } from "./mexico-bank-transfer"

import { API, graphqlOperation } from "aws-amplify"

import { useAuth } from "../../hooks/auth-context"
import { getTokenNuvei } from "../../graphql/queries"

import LoadingScreenIcon from "../ui/images/loading-screen"
import SuccessNuveiPage from "./success-page"
import TypePayments from "./type-payment"
import { MexicoCreditCard } from "./mexico-credit-card"

const CreditCard = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 600px;
`

const LabelPayment = styled.div`
  background-color: #DDF2FA;
  width: 600px;
  color: black;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  justify-content: center;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
      type: "text/javascript",
      defer: true,
      src,
      onerror: (e: any) => {
        reject(e)
      }
    })
    scriptElem.onload = () => {
      resolve()
    }
    document.body.appendChild(scriptElem)
  })

type PropsPaymentMethod = {
  [key: string]: any
}

const PaymentMethod: PropsPaymentMethod = {
  creditcard: MexicoCreditCard,
  banktransfer: MexicoBankTransfer,
}

const MexicoPage = ({ currency = 'MXN' }: any) => {

  const [safeCharge, setSafeCharge] = useState(null)
  const [amount, setAmount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [modulo, setModulo] = useState(false)
  const [nuveiInfo, setNuveiInfo] = useState({} as any)
  const [paymentMethod, setPaymentMethod] = useState('banktransfer')

  const { state } = useAuth()
  const user = state.user as User

  const [success, setSuccess] = useState(false)
  const [dataSuccess, setDataSuccess] = useState({})

  useEffect(() => {
    const loadSafecharge = async () => {
      await loadScript(
        "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js" //production
      )
      // @ts-ignore
      if (window.SafeCharge) {
        setSafeCharge(
          // @ts-ignore
          window.SafeCharge({
            env: "prod",
            merchantId: "6989725270836596280",
            merchantSiteId: "234498"
          })
        )
      }
    }

    loadSafecharge()
  }, [])

  const modulodepago = async () => {
    if (loading) return
    setLoading(true)
    const response = await API.graphql(
      graphqlOperation(
        getTokenNuvei,
        {
          cognitoId: user.cognitoId,
          amount: amount,
          currency: currency,
          country: "mexico",
        }
      )
    )
    setNuveiInfo(JSON.parse(response.data.getTokenNuvei))
    setLoading(false)
    setModulo(true)
  }

  const onSuccess = (data: any) => {
    setDataSuccess(data)
    setSuccess(true)
  }

  const ComponentPayment: any = PaymentMethod[paymentMethod]

  return (
    <PageLayout>
      <CreditCard>
        {!modulo && !loading && (
          <TypePayments
            currency={currency}
            amount={amount}
            setAmount={setAmount}
            onClickPay={modulodepago}
            setPaymentMethod={setPaymentMethod}
          />
        )}
        {modulo && <LabelPayment>
          Monto a depositar: {currency} ${amount}
        </LabelPayment>}
      </CreditCard>
      {loading && <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>}
      {modulo && !success &&
        <ComponentPayment
          safeCharge={safeCharge}
          nuveiInfo={nuveiInfo}
          onSuccess={onSuccess}
          country="MX"
        />
      }
      {success && <SuccessNuveiPage
        data={dataSuccess}
        amount={amount}
        currency={currency}
        orderId={nuveiInfo?.success?.clientUniqueId}
        paymentMethod={paymentMethod}
      />}
    </PageLayout>
  )
}

export default MexicoPage
