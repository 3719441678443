import * as Sentry from "@sentry/browser"

interface CountryType {
  [key: string]: string
}

const COUNTRIES: CountryType = {
  CL: "Chile",
  PE: "Peru",
  UY: "Uruguay",
  MX: "Mexico",
}

export const fetchCountry = async () => {
  let country = "Uruguay"

  if (process.env.REACT_APP_ENV === "development") return country

  const url = process.env.REACT_APP_URL_IPINFO || ""

  try {
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        Authorization: 'JhbGciOiJIUzI',
      },
    })
    const json: { countryCode: string } = await response.json()

    if (COUNTRIES[json.countryCode]) {
      country = COUNTRIES[json.countryCode]
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return country
}
