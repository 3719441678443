
import React from "react"

const IconToHide = () => (
  <svg width="20" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Plus">
    <path id="Vector 5" d="M2.31689 12.5L11.3901 12.5" stroke="#14142B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
)

export default IconToHide
