import React from "react"
import styled from "@emotion/styled"

import verifyCard from "../../../images/verify-card-icon.svg"

const VerifyCard = styled.div`
  background-image: url(${verifyCard});
  background-repeat: no-repeat;
  background-size: cover;
  width: 115px;
  height: 78px;
`

const VerifyCardIcon = () => <VerifyCard />

export default VerifyCardIcon
