import React, { MouseEvent } from "react"
import styled from "@emotion/styled"
import { Link } from "@reach/router"

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
  text-decoration: none;
`

const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
  text-decoration: none;
`

const Item = styled.li`
  border-bottom: 1px solid #e0e0e0;
  padding: 0.75rem 1.375rem;
`

const LinkText = styled.span`
  margin-left: 10px;
`

type Props = {
  location: Location
  path: string
  iconOn: any
  iconOff: any
  text: string
  external?: boolean
  onClick: (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const MobileNavbarItem = ({
  location,
  path,
  iconOn,
  iconOff,
  text,
  external,
  onClick,
}: Props) => (
  <Item>
    {external ? (
      <ExternalLink
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e: any) => onClick(e)}
      >
        {iconOff}
        <LinkText>{text}</LinkText>
      </ExternalLink>
    ) : (
      <StyledLink to={path} onClick={(e: any) => onClick(e)}>
        {location.pathname === path ? iconOn : iconOff}
        <LinkText>{text}</LinkText>
      </StyledLink>
    )}
  </Item>
)

export default MobileNavbarItem
