import AWS from "aws-sdk"
import { Auth, API, graphqlOperation } from "aws-amplify"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { info } from "../graphql/queries"
import { updateIdentityId } from "../graphql/mutations"

import errorAws from "../helpers/errors-aws"
import { fetchCountry } from "../helpers/country"
import { setTagsSentry } from "../helpers/sentry"
import { trackUserId, trackUserEvent, trackEvent } from "../helpers/tracker"

export const fetchCapitariaUser = async (cognitoUser: any) => {
  const userInfoResponse = await API.graphql(
    graphqlOperation(info, { cognitoId: cognitoUser.attributes.sub })
  )

  let capitariaUser: User = userInfoResponse.data.info

  if (!capitariaUser.identityId) {
    const currentCredentials = await Auth.currentCredentials()
    const response = await API.graphql(
      graphqlOperation(updateIdentityId, {
        id: capitariaUser.id,
        identityId: currentCredentials.identityId,
      })
    )

    capitariaUser = response.data.updateIdentityId
  }

  return capitariaUser
}

type LogoutResponse = {
  user: null
}

export const logout = async (): Promise<LogoutResponse> => {
  await Auth.signOut()

  return { user: null }
}

type SignUpResponse = {
  email: string
  password: string
  userNeedEmailValidation: boolean
  loading: boolean
  error: string
}

type SignUpType = {
  values: UserSignUp
  parsedQueryString: {
    mc_origen?: string
    utm_medium?: string
    utm_source?: string
    utm_campaign?: string
    utm_content?: string
    utm_term?: string
    utm_id?: string
    SUBID?: string
  }
}

export const signUp = async ({
  values,
  parsedQueryString,
}: SignUpType): Promise<SignUpResponse> => {
  setTagsSentry({ ...values, ...(parsedQueryString as Params) })

  const {
    utm_id,
    SUBID,
    utm_medium,
    utm_source,
    utm_campaign,
    utm_content,
    utm_term,
    mc_origen,
  } = parsedQueryString

  const country = await fetchCountry()
  const email = values.email.toLocaleLowerCase()
  const password = values.password

  trackEvent("sign-up:create-account")

  try {
    const user = await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email,
        given_name: values.givenName,
        family_name: values.familyName,
        "custom:signUpCountry": country || "Uruguay",
        "custom:phone": "",
        "custom:acceptPrivacyTerms": values.acceptPrivacyTerms,
        "custom:mcOrigen": mc_origen || "",
        "custom:utmId": utm_id || "",
        "custom:investingSubId": SUBID || "",
        "custom:utmMedium": utm_medium?.substring(0, 50) || "",
        "custom:utmSource": utm_source?.substring(0, 50) || "",
        "custom:utmCampaign": utm_campaign?.substring(0, 50) || "",
        "custom:utmContent": utm_content?.substring(0, 255) || "",
        "custom:utmTerms": utm_term?.substring(0, 255) || "",
      },
    })

    trackUserId(user.userSub)

    trackUserEvent("sign-up:create-account:success", {
      email,
      givenName: values.givenName,
      familyName: values.familyName,
      mc_origen,
      utm_medium,
      utm_source,
      utm_campaign,
      utm_content,
      utm_term,
    })

    return {
      email,
      password,
      userNeedEmailValidation: true,
      loading: false,
      error: "",
    }
  } catch (error) {
    let errorMessage = ""

    if (error.code === "UsernameExistsException") {
      errorMessage = "Una cuenta con el email ingresado ya existe"

      trackEvent("sign-up:create-account:error:account-exist")
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException(error)
      trackEvent("sign-up:create-account:error:generic")
      toast.error(errorMessage)
    }

    return {
      email,
      password,
      userNeedEmailValidation: false,
      loading: false,
      error: errorMessage,
    }
  }
}

type LoginResponse = {
  email: string
  password: string
  user: User | null
  userNeedEmailValidation: boolean
  error: string
}

export const login = async (
  email: string,
  password: string,
  mcOrigen: string
): Promise<LoginResponse> => {
  setTagsSentry({ email, password })
  trackEvent("login:sign-in")

  try {
    const cognitoUser = await Auth.signIn(email, password)
    const capitariaUser = await fetchCapitariaUser(cognitoUser)

    trackUserId(capitariaUser.cognitoId)
    trackUserEvent(
      "login:sign-in:success",
      {
        email: capitariaUser.email,
        givenName: capitariaUser.givenName,
        familyName: capitariaUser.familyName,
        phone: capitariaUser.phone,
      },
      {
        mc_origen: mcOrigen,
      }
    )

    return {
      email,
      password,
      user: capitariaUser,
      userNeedEmailValidation: false,
      error: "",
    }
  } catch (error) {
    let errorMessage = "Email o contraseña incorrecta"
    let userNeedEmailValidation = false

    if (
      error.code === "UserNotFoundException" ||
      error.code === "NotAuthorizedException"
    ) {
      errorMessage = "Email o contraseña incorrecta"

      trackEvent("login:sign-in:error:invalid-credentials")
    } else if (error.code === "UserNotConfirmedException") {
      errorMessage = "Debe validar su correo antes de continuar"
      userNeedEmailValidation = true

      trackEvent("login:sign-in:error:email-not-validated")
      toast.warn(errorMessage)
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

      trackEvent("login:sign-in:error:generic")
      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException(error)
      toast.error(errorMessage)
    }

    await Auth.signOut()

    return {
      email,
      password,
      user: null,
      userNeedEmailValidation,
      error: errorMessage,
    }
  }
}

export const autoLogin = async (): Promise<User | null> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()

    if (cognitoUser) {
      // Fix clockDrift error: https://github.com/aws-amplify/amplify-js/pull/4251#issuecomment-568915659
      AWS.config = new AWS.Config({
        systemClockOffset: -(cognitoUser.signInUserSession.clockDrift * 1000),
      })
    }

    const capitariaUser = await fetchCapitariaUser(cognitoUser)

    return capitariaUser
  } catch (error) {
    console.log(error)

    return null
  }
}

type ChangePasswordResponse = {
  user: User | null
  email: string
  loading: boolean
  error: string
}

export const changePassword = async (
  email: string,
  password: string,
  code: string,
  mcOrigen: string
): Promise<ChangePasswordResponse> => {
  trackEvent("forgot-password:change-password")

  try {
    await Auth.forgotPasswordSubmit(email, code, password)

    const cognitoUser = await Auth.signIn(email, password)
    const capitariaUser = await fetchCapitariaUser(cognitoUser)

    trackUserId(cognitoUser.attributes.sub)
    trackUserEvent(
      "forgot-password:change-password:success",
      {
        email: capitariaUser.email,
        givenName: capitariaUser.givenName,
        familyName: capitariaUser.familyName,
        phone: capitariaUser.phone,
      },
      { mc_origen: mcOrigen }
    )

    return {
      user: capitariaUser,
      email,
      loading: false,
      error: "",
    }
  } catch (error) {
    let errorMessage = ""

    if (
      error.code === "CodeMismatchException" ||
      error.code === "NotAuthorizedException"
    ) {
      errorMessage = errorAws[error.code]
      trackEvent("forgot-password:change-password:error:invalid-code")
    } else if (errorAws[error.code]) {
      errorMessage = errorAws[error.code]

      trackEvent("forgot-password:change-password:error:catch-error", {
        error: error.code,
      })
      toast.error(errorAws[error.code])
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

      trackEvent("forgot-password:change-password:error:generic")
      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException(error)
      toast.error(errorMessage)
    }

    return {
      user: null,
      email,
      loading: false,
      error: errorMessage,
    }
  }
}

type ForgotPasswordResponse = {
  email: string
  userNeedCodeValidation: boolean
  loading: boolean
  error: string
}

export const forgotPasswordSendCode = async (
  email: string
): Promise<ForgotPasswordResponse> => {
  trackEvent("forgot-password:send-code")

  try {
    await Auth.forgotPassword(email)

    trackEvent("forgot-password:send-code:success")

    return { email, userNeedCodeValidation: true, loading: false, error: "" }
  } catch (error) {
    let errorMessage = ""

    if (
      error.code === "UserNotFoundException" ||
      error.code === "NotAuthorizedException"
    ) {
      errorMessage = "No existe una cuenta correo con ese email"

      trackEvent("forgot-password:send-code:error:account-not-exist")
    } else if (error.code === "InvalidParameterException") {
      errorMessage =
        "No se puede recuperar la contraseña. Por favor comunicate a hzurita@capitaria.com explicando este error"

      const params = {
        code: error.code,
        message: error.message,
      }
      trackUserEvent(
        "forgot-password:send-code:error:email-not-validated",
        {
          email,
        },
        params
      )
    } else if (errorAws[error.code]) {
      errorMessage = errorAws[error.code]

      trackEvent("forgot-password:send-code:error:catch-error", {
        error: error.code,
      })
      toast.error(errorAws[error.code])
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

      trackEvent("forgot-password:send-code:error:generic")
      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException(error)
      toast.error(errorMessage)
    }

    return {
      email,
      userNeedCodeValidation: false,
      loading: false,
      error: errorMessage,
    }
  }
}

type confirmSignUpAndSignInResponse = {
  user: User | null
  loading: boolean
  error: string
}

export const confirmSignUpAndSignIn = async (
  email: string,
  password: string,
  code: string
): Promise<confirmSignUpAndSignInResponse> => {
  try {
    await Auth.confirmSignUp(email, code)
    const cognitoUser = await Auth.signIn(email, password)
    const capitariaUser = await fetchCapitariaUser(cognitoUser)

    return {
      user: capitariaUser,
      loading: false,
      error: "",
    }
  } catch (error) {
    let errorMessage = ""

    if (
      error.code === "CodeMismatchException" ||
      error.code === "NotAuthorizedException"
    ) {
      errorMessage = errorAws[error.code]

      trackEvent("validate-email:error:invalid-code")
    } else if (errorAws[error.code]) {
      errorMessage = errorAws[error.code]

      trackEvent("validate-email:error:catch-error", {
        error: error.code,
      })

      toast.error(errorMessage)
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException(error)
      trackEvent("validate-email:error:generic")
      toast.error(errorMessage)
    }

    return {
      user: null,
      loading: false,
      error: errorMessage,
    }
  }
}

type ResendValidateCodeResponse = {
  loading: boolean
  error: string
}

export const resendValidateCode = async (
  email: string
): Promise<ResendValidateCodeResponse> => {
  try {
    trackEvent("validate-email:resend-code")

    await Auth.resendSignUp(email)
    toast.success("Se ha enviado un nuevo código a su correo")

    return {
      loading: false,
      error: "",
    }
  } catch (error) {
    let errorMessage = ""

    if (errorAws[error.code]) {
      errorMessage = errorAws[error.code]

      trackEvent("validate-email:error:catch-error", {
        error: error.code,
      })
      toast.error(errorMessage)
    } else {
      errorMessage =
        "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"

      Sentry.setExtra("error", JSON.stringify(error))
      Sentry.captureException("Error al enviar el código nuevamente")
      trackEvent("validate-email:error:generic")
      toast.error(errorMessage)
    }

    return {
      loading: false,
      error: errorMessage,
    }
  }
}
