import React from "react"
import styled from "@emotion/styled"

import MapPinIcon from "../../../ui/images/map-pin-icon"
import loadAddressInformation from "../loadAddressInformation"

type GmapsResult = {
  description: string
  terms: Record<string, any>[]
  structured_formatting: Record<string, string>
}

type ResultsType = {
  results: GmapsResult[]
  setter: {
    setAddressError: (addressError: string) => void
    setFieldValue: (field: string, value: string) => void
    setGmapResults: (gmapsResults: any) => void
    setNotSelected: (notSelected: boolean) => void
    setAddress: (address: string) => void
    setLatitude: (latitude: number) => void
    setLongitude: (longitude: number) => void
    setPostalCode: (postalCode: string) => void
    setStreetNumber: (streetNumber: string) => void
    setCity: (city: string) => void
    setShowModal: (showModal: boolean) => void
    setError: (error: boolean) => void
  }
}

const IconWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`

const Wrapper = styled.div`
  background: white;
  position: relative;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  letter-spacing: 0.015;
  line-height: 20px;
  display: flex;
  align-items: center;
  z-index: 10;
`

const ResultText = styled.div`
  padding-left: 5px;
  padding-right: 10px;
  font-size: 12px;
`

const ResultsFound = ({ results, setter }: ResultsType) => (
  <div>
    {results.map((result: GmapsResult, index: number) => (
      <div key={index}>
        <Wrapper
          onClick={async () => {
            const principalAddress = result.structured_formatting.main_text
            if (!principalAddress.match(/\d/)) {
              setter.setAddressError("Falta el número de calle")
              // setter.setShowModal(true)
              return
            }
            const direccion = result.description
            setter.setFieldValue("address", direccion)
            setter.setGmapResults([])
            setter.setNotSelected(false)
            setter.setAddressError("")
            setter.setError(false)
            var geocoder = new google.maps.Geocoder()
            geocoder.geocode({ address: direccion }, function (results, status) {
              const result = loadAddressInformation(results)
              setter.setAddress(direccion)
              setter.setLatitude(result.lat)
              setter.setLongitude(result.lng)
              setter.setPostalCode(result.postalCode)
              if (!result.streetNbr) {
                const regexNumber = principalAddress.match(/\d+/)
                if (regexNumber) {
                  setter.setStreetNumber(regexNumber[0])
                }
              } else {
                setter.setStreetNumber(result.streetNbr)
              }
              setter.setCity(result.city)
            })
          }}
        >
          <IconWrapper>
            <MapPinIcon />
          </IconWrapper>
          <span>
            <strong>{`${result.structured_formatting.main_text}`}</strong>
            <ResultText>
              {result.structured_formatting.secondary_text}
            </ResultText>
          </span>
        </Wrapper>
      </div>
    ))}
  </div>
)

export default ResultsFound
