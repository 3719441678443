import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import awsConfig from "./aws-exports"
import AWS from "aws-sdk"
import Amplify from "aws-amplify"
import * as Sentry from "@sentry/browser"
import TagManager from "react-gtm-module"
import ReactGA from "react-ga"
import amplitude from "amplitude-js"
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react"

import "./index.css"
import App from "./app"
import * as serviceWorker from "./serviceWorker"
import { removeLastSlash } from "./helpers/clean-pathname"

import { AuthProvider } from "./hooks/auth-context"

const environment = process.env.REACT_APP_ENV || "development"
const dsnSentry =
  process.env.REACT_APP_DSN_SENTRY ||
  "https://a38f1cc5292240e7a4a5d75739e21b84@o363665.ingest.sentry.io/5201574"
/*
El DSN por defecto de Sentry es un proyecto llamado develop-project
que se utilizara para los ambientes locales
*/

if (environment !== "development") {
  Sentry.init({
    dsn: dsnSentry,
    environment: environment,
    release: `${environment}@${process.env.REACT_APP_COMMIT_ID}`,
    tunnel: 'https://huc6h09v4j.execute-api.us-east-2.amazonaws.com/sentry'
  })
}

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  }

  TagManager.initialize(tagManagerArgs)
}

const pathname = removeLastSlash(window.location.pathname)

/*
Initialize Google Analytics
*/
if (process.env.REACT_APP_GA_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID)
  ReactGA.pageview(pathname + window.location.search)
}

Amplify.configure(awsConfig)

/**
 * Inicialize Amplitude
 */
let amplitudeApiKey =
  process.env.REACT_APP_AMPLITUDE || "5de9bb797d6f2a9b4e10ca55ecf9ae0b"

if (process.env.REACT_APP_ENV !== "production") {
  amplitudeApiKey = "e96257973c17ff25ae00b237308e71f1"
}

amplitude.getInstance().init(
  amplitudeApiKey,
  undefined,
  {
    includeGclid: true,
    includeReferrer: true,
    includeUtm: true,
    apiEndpoint: 'g5ra0wepsa.execute-api.us-east-2.amazonaws.com/',
  },
  () => {
    let mainDomain = ".capitaria.com"
    if (process.env.REACT_APP_ENV !== "production") {
      mainDomain = ".d281xtybzl4c9i.amplifyapp.com"
    }
    amplitude.getInstance().setDomain(mainDomain)
  }
)

// Fix clockDrift error: https://github.com/aws-amplify/amplify-js/pull/4251#issuecomment-568915659
AWS.config.correctClockSkew = true

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-vBTpmY8EiAyNeX3",
  subscribeToChanges: true
})

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    growthbook.loadFeatures()
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AuthProvider>{children}</AuthProvider>
    </GrowthBookProvider>
  )
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
