import React from "react"
import styled from "@emotion/styled"

import operativesAspectsIconOff from "../../../images/icn-operatives-aspects-off.svg"

const OperativesAspectsIconOff = styled.div`
  background-image: url(${operativesAspectsIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const OperativesAspectsIcon = () => <OperativesAspectsIconOff />

export default OperativesAspectsIcon
