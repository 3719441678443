import React from "react"
import styled from "@emotion/styled"

import gmailLogo from "../../../images/gmail.svg"

const GmailImage = styled.img`
  width: 27px;
  height: 27px;
`

const GmailLogo = () => (
  <GmailImage src={gmailLogo} alt="Gmail Logo" />
)

export default GmailLogo
