import styled from "@emotion/styled"
import { Formik } from "formik"
import React from "react"
import * as Yup from "yup"

import { trackEvent } from "../../helpers/tracker"
import CustomButtonV2 from "../ui/custom-button-v2"
import GmailLogo from "../ui/images/gmail-logo"
import OtpInput from "../ui/opt-input"
import ResendCode from "./resend-code"
import ValidateEmailLayout from "./validate-email-layout"

const Title = styled.h1`
  max-width: 60%;
  font-family: Open Sans;
  font-size: 27.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  margin: 0;
  color: #1f2425;
  text-align: center;
  @media (max-width: 425px) {
    max-width: 308px;
    text-align: center;
  }
`

const Description = styled.p`
  max-width: 62%;
  font-size: 12px;
  line-height: 125%;
  color: #1f2425;
  margin: 1rem 0 0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  @media (max-width: 425px) {
    max-width: 257px;
  }
`

const Form = styled.form`
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const buttonCSS = {
  marginTop: "1rem",
  height: "48px",
  width: "213px",
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: "18px",
    lineHeight: "138.889%",
    textAlign: "center",
  },
}

const loadingButtonCss = buttonCSS

const labelCSS = {
  fontSize: "20px",
  width: "100%",
  textAlign: "center",
  marginBottom: "13px",
  fontFamily: "Roboto",
  fontWeight: "500"
}

const Error = styled.p`
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  max-width: 213px;
`

const GmailButton = styled.div`
  margin-top: 20px;
  border: 0px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
`

const FlexAbTesting = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const ContainerReasons = styled.div`
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  @media (max-width: 425px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 60%;
  }
  @media (min-width: 1024px) {
    max-width: 30%;
  }
`

const Reason = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto;
  text-align: center;
`

const WhyQuestion = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  font-weight: 600;
  font-family: Roboto;
`

const ValidateCodeSchema = Yup.object().shape({
  code: Yup.string().required("Este campo es requerido"),
})

type Props = {
  email: string
  loading: boolean
  onSubmit: (code: string) => void
  resendCode: () => void
  submitError: string
}

const ValidateCode = ({
  email,
  loading,
  onSubmit,
  resendCode,
  submitError,
}: Props) => {
  return (
    <ValidateEmailLayout>
      <FlexAbTesting>
        <Title>Ingresa el código enviado a tu correo</Title>
        <Description>
          Revisa la bandeja de entrada de tu correo{" "}
          <strong>{email}</strong> y copia el código enviado acá abajo
        </Description>
        {email.match(/gmail.com/i) &&
          !window.navigator.userAgent.match(
            /android|iphone|ipad|windows phone/i
          ) && (
            <GmailButton
              onClick={() => {
                trackEvent("validate-email:button-gmail")
                // @ts-ignore
                gtag('event', 'click_btn_sniper')
                window.open(
                  `https://mail.google.com/mail/u/${email}/#search/from%3Anoreply%40capitaria.com+in%3Aanywhere+newer_than%3A1d`
                )
              }}
            >
              <GmailLogo /> Abrir Gmail
            </GmailButton>
          )}
      </FlexAbTesting>
      <Formik
        initialValues={{ code: "" }}
        validationSchema={ValidateCodeSchema}
        onSubmit={values => onSubmit(values.code.toString().trim())}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleSubmit,
            setFieldValue,
          } = props
          return (
            <Form onSubmit={handleSubmit}>

              <OtpInput
                name="code"
                label="Ingresa tu código acá"
                styleLabel={labelCSS}
                value={values.code}
                valueLength={6}
                onChange={(value) => { setFieldValue("code", value) }}
                hasError={errors.code && touched.code}
                errorMessage={errors.code}
              />

              <CustomButtonV2
                style={buttonCSS}
                loading={loading}
                type="submit"
                loadingStyle={loadingButtonCss}
              >
                CONTINUAR
              </CustomButtonV2>

              {submitError.length > 0 && <Error>{submitError}</Error>}
            </Form>
          )
        }}
      </Formik>
      <ResendCode resendCode={resendCode} />
      <ContainerReasons>
        <WhyQuestion>
          ¿Porque necesitamos validar tu correo?
        </WhyQuestion>
        <Reason>
          Tu correo será el nombre de usuario para ingresar a tu cuenta a través de este sitio web por lo que es importante que tu correo esté bien escrito.
        </Reason>
      </ContainerReasons>
    </ValidateEmailLayout>
  )
}

export default ValidateCode
