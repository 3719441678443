import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #2BA770;
  text-align: center;
  @media (max-width: 812px) {
    font-size: 17px;
  }
`

const SubTitle = styled.div`
  width: 344px;
  text-align: center;
  font-family: Roboto;
  margin-top: 20px;
  font-size: 13px;
  @media (max-width: 812px) {
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 20px;
    padding-right: 35px;
    padding-left: 35px;
  }
`

export { Container, Title, SubTitle }
