import React from "react"
import { FocusEvent } from "react"
import styled from "@emotion/styled"
import Select, { OptionTypeBase } from "react-select"
import { Country, countries } from "countries-list"

import ErrorIcon from "../ui/images/error-icon"

const FormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Label = styled.label`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1f2425;
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
  `

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

export type DefaultValue = OptionType | OptionTypeBase

export interface OptionType extends OptionTypeBase {
  code: string
}

const suggestedCountries: OptionType | OptionTypeBase[] = []
const othersCountries: OptionType | OptionTypeBase[] = []

const formattedCountryData = (code: string, data: Country) => ({
  label: `${data.emoji} ${data.name}`,
  value: data.name,
  code: code
})

Object.entries(countries).forEach(country => {
  const [code, data] = country

  if (["CL", "PE", "UY", "MX"].includes(code)) {
    suggestedCountries.push(formattedCountryData(code, data))
  } else {
    othersCountries.push(formattedCountryData(code, data))
  }
})

const groupedCountries = [
  {
    label: "Sugeridos",
    options: suggestedCountries,
  },
  {
    label: "Otros",
    options: othersCountries,
  },
]

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
}

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)


type Props = {
  label: string
  defaultValue?: string
  placeholder: string
  width?: number
  onChange?: (value: OptionType) => void
  onSelectDefaultValue?: (value: OptionType | undefined) => void
  onBlur?: (event: FocusEvent<any>) => void
  hasError?: boolean | ""
  errorMessage?: string
}

const CustomCountryPicker = ({
  label,
  defaultValue,
  placeholder,
  width,
  onChange,
  onSelectDefaultValue,
  onBlur,
  hasError,
  errorMessage,
}: Props) => {
  const countriesStyles = {
    control: (provided: any) => ({
      ...provided,
      width: width || 352,
      height: 46,
      borderColor: hasError ? "#e76a6a" : "#e8e8e8",
      "@media (maxWidth: 812px)": {
        width: "327px",
      },
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#172425",
      fontWeight: 400,
    })
  }

  let defaultFormattedCountry: OptionTypeBase | undefined = undefined

  if (defaultValue) {
    const findCountry = Object.entries(countries).find(([code, data]) =>
      data.name === defaultValue
    )

    if (findCountry) {
      const [codeCountry, dataCountry] = findCountry
      defaultFormattedCountry = formattedCountryData(codeCountry, dataCountry)
    }
  }

  if (onSelectDefaultValue) {
    onSelectDefaultValue(defaultFormattedCountry as OptionType)
  }

  return (
    <FormGroup>
      <Label>{label}</Label>

      <Select
        value={defaultFormattedCountry}
        styles={countriesStyles}
        placeholder={placeholder}
        options={groupedCountries}
        formatGroupLabel={formatGroupLabel}
        onChange={(selectedCountry: OptionType) => onChange!(selectedCountry)}
        onBlur={onBlur}
      />

      {hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </Error>
      )}
    </FormGroup>
  )
}

export default CustomCountryPicker
