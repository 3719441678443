import React, {
  useState,
  useEffect,
  FunctionComponent,
  useCallback,
} from "react"
import {
  useLocation,
  Router,
  RouteComponentProps,
  globalHistory,
  navigate,
} from "@reach/router"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactGA from "react-ga"

import { useAuth } from "./hooks/auth-context"

import Login from "./components/login/login"
import ForgotPassword from "./components/forgot-password/forgot-password"
import Logout from "./components/login/logout"

import Layout from "./components/layout"
import PersonalInfo from "./components/kyc/personal-info"
import RiskProfile from "./components/kyc/risk-profile"
import KnowledgeProfile from "./components/kyc/knowledge-profile"
import Terms from "./components/kyc/terms"

import HelpPage from "./components/help/help-page"
import ValidateBankCard from "./components/verify-documents/validate-bank-card"

import "normalize.css"
import "./app.css"
import { QueryStringProvider, useQueryString } from "@capitaria/capitaria-utms"
import { trackPage, trackUserId, trackEvent } from "./helpers/tracker"
import { removeLastSlash } from "./helpers/clean-pathname"
import ThankYou from "./components/typ/typ"
import SignUpV2 from "./components/sign-up/sign-up-v2"
import ValidateEmailV2 from "./components/sign-up/validate-email-v2"
import CouserSelection from "./components/course-selection/course-selection"
import Deposit from "./components/sign-up/deposit"
import ValidationOnHold from "./components/verify-documents/validation-on-hold"
import DownloadMt5 from "./components/sign-up/download-mt5"
import BadTraderProfile from "./components/home/bad-trader-profile-v2"
import HomeView from "./components/home/home-view"
import ValidatePhoneKyc from "./components/verify-documents-v2/validate-phone-kyc"
import ValidatePhoneWithoutKyc from "./components/verify-documents-v2/validate-phone-without-kyc"
import listSteps from "./helpers/list-steps"
import ValidateDocumentsKyc from "./components/verify-documents/validate-documents-kyc"
import ValidateDocumentsWithoutKyc from "./components/verify-documents/validate-documents-without-kyc"
import ValidateLivenessWithoutKyc from "./components/metamap/validate-liveness-without-kyc"
import ValidateLivenessKyc from "./components/metamap/validate-liveness-kyc"
import DepositPage from "./components/deposit/deposit-page"

type Props = { component: FunctionComponent } & RouteComponentProps


globalHistory.listen(({ location }) => {
  const pathname = removeLastSlash(location.pathname)
  ReactGA.pageview(pathname + location.search)
})

const Route: FunctionComponent<Props> = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const [trackPageView, setTrackPageView] = useState(false)
  const { parsedQueryString } = useQueryString()

  if (!trackPageView && parsedQueryString.mc_origen) {
    const pathname = removeLastSlash(location.pathname)
    trackPage(pathname, parsedQueryString.mc_origen, true)

    setTrackPageView(true)
  }

  useEffect(() => {
    const redirect = () => {
      if (location.pathname === "/") {
        window.location.href = `/login${location.search}`
      }
    }

    redirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Component {...rest} />
}

const PrivateRoute: FunctionComponent<Props> = ({
  component: Component,
  ...rest
}) => {
  const { state } = useAuth()
  const user = state.user as User
  const location = useLocation()
  const [tracked, setTracked] = useState<boolean>(false)

  const trackUserPage = useCallback(async () => {
    const { pathname } = location
    const pathnameWithoutSlash = removeLastSlash(pathname)
    if (!tracked) {
      trackUserId(user.cognitoId)
      trackPage(pathnameWithoutSlash)
      setTracked(true)
    }

    if (user.kycNextStep !== "home") {
      if (location.pathname !== `/${user.kycNextStep}`) {
        navigate(`/${user.kycNextStep}`)
      }
    } else if (listSteps.includes(location.pathname.slice(1))) {
      console.log('testing')
      navigate(`/home`)
    }

  }, [location, tracked, user.cognitoId, user.kycNextStep])

  useEffect(() => {
    trackUserPage()
  }, [trackUserPage])

  return <Component {...rest} />
}

const LogoutRoute: FunctionComponent<Props> = ({
  component: Component,
  ...rest
}) => {
  useEffect(() => {
    trackEvent("logout:pageview")
  }, [])

  return <Component {...rest} />
}

toast.configure({
  autoClose: 3000,
  hideProgressBar: true,
})

type ParamRouteType = {
  component: FunctionComponent
  default?: boolean
}

type PrivateRouteType = {
  [key: string]: ParamRouteType
}

const listPrivateRoute: PrivateRouteType = {
  "/home": {
    component: HomeView,
    default: true
  },
  "/deposit": {
    component: DepositPage
  },
  "/documentos": {
    component: HomeView
  },
  "/bienvenido": {
    component: HomeView
  },
  "/help": {
    component: HelpPage
  },
  "/phone-validation-status": {
    component: ValidatePhoneWithoutKyc
  },
  "/documents-validation-status": {
    component: ValidateDocumentsWithoutKyc
  },
  "/liveness-validation-status": {
    component: ValidateLivenessWithoutKyc
  },
  "/kyc/validate-phone": {
    component: ValidatePhoneKyc
  },
  "/kyc/validate-documents": {
    component: ValidateDocumentsKyc
  },
  "/kyc/validate-liveness": {
    component: ValidateLivenessKyc
  },
  "/kyc/select-course": {
    component: CouserSelection
  },
  "/kyc/personal-info": {
    component: PersonalInfo
  },
  "/kyc/deposit": {
    component: Deposit
  },
  "/kyc/document-credit-card": {
    component: ValidateBankCard
  },
  "/kyc/validate-deposit": {
    component: ValidationOnHold
  },
  "/kyc/platform": {
    component: DownloadMt5
  },
  "/kyc/risk-profile": {
    component: RiskProfile
  },
  "/kyc/knowledge-profile": {
    component: KnowledgeProfile
  },
  "/kyc/bad-trader-profile": {
    component: BadTraderProfile
  },
  "/kyc/terms": {
    component: Terms
  },
  "/kyc/good-trader-profile": {
    component: Logout
  },
  "/loading": {
    component: ThankYou
  },
}

const AuthenticatedApp = () => (
  <Layout>
    <Router>
      {Object.entries(listPrivateRoute).map(
        ([path, { component: Component, default: isDefault }]) => (
          <PrivateRoute
            key={path}
            path={path}
            component={Component}
            default={isDefault}
          />
        )
      )}
      <Route path="/undefined" component={Logout} />
      <LogoutRoute path="/logout" component={Logout} />
    </Router>
  </Layout>
)

const UnauthenticatedApp = () => (
  <QueryStringProvider>
    <Router>
      <Route path="/login" component={Login} default />
      <Route path="/sign-up" component={SignUpV2} />
      <Route path="/validate-email" component={ValidateEmailV2} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/undefined" component={Logout} />
      <LogoutRoute path="/logout" component={Logout} />
    </Router>
  </QueryStringProvider>
)

const App = () => {
  const {
    state: { user, userIsLoading },
  } = useAuth()

  if (userIsLoading) {
    return <></>
  }

  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default App
