import React from "react"
import styled from "@emotion/styled"

import checkIcon from "../../../images/check-icon.png"

const Check = styled.img`
  width: 18px;
  height: 14px;
`

const CheckIcon = () => <Check src={checkIcon} alt="check" />

export default CheckIcon
