import React, { useState } from "react"
import styled from "@emotion/styled"

import ModalWebpayMexico from "./modal-webpay-mexico"

const Box = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1rem 0;
  margin: 0 0 2rem;
  width: 217px;
  min-height: 100px;
  cursor: pointer;
`

const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const ContainerTax = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #363f41;
  font-weight: bold;
`

type Props = {
  children: any
}

const WebpayBoxMexico = ({ children }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  const openModal = () => setIsModalOpen(true)

  return (
    <>
      <Box onClick={openModal}>
        <BoxContent>{children}</BoxContent>
        <ContainerTax>*Tarifa 3.5%</ContainerTax>
      </Box>
      <ModalWebpayMexico
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  )
}

export default WebpayBoxMexico
