import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"

import footerBackground from "../../images/footer-background.svg"
import BackArrowIcon from "../ui/images/back-arrow-icon"
import CapitariaLogoBlack from "../ui/images/capitaria-logo-black"


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  background-image: url(${footerBackground});
  background-repeat: no-repeat;
	background-size: 100% 188.835%;
	mix-blend-mode: multiply;
  @media (max-width: 425px) {
    padding-bottom: 2rem;
    background-size: 168% 120.835%;
    background-position: -148px 275.713px;
  }
`

const NavBar = styled.nav`
  padding-top: 2em;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 812px) {
    justify-content: space-between;
    padding: 2.55rem 1.25rem 0;
  }
`

const Content = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 812px) {
    padding: 4rem 0 7rem;
  }
`

const LogoWrapper = styled.div`
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
`

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 13.33px;
  font-weight: 600;
  text-decoration: none;
  line-height: 140%;
  color: #1f2425;
  padding: 0 2rem;
  @media (max-width: 812px) {
    padding: 0;
  }
`

const LinkText = styled.span`
  padding-left: 0.5rem;
`

type Props = {
  children: React.ReactNode
}

const ValidateEmailLayout = ({ children }: Props) => {
  const queryStringParams = useQueryString()

  return (
    <Container>
      <NavBar>
        <LogoWrapper>
          <CapitariaLogoBlack />
        </LogoWrapper>

        <StyledLink href={`/login${queryStringParams.queryString}`}>
          <BackArrowIcon /> <LinkText>Volver</LinkText>
        </StyledLink>
      </NavBar>
      <Content>{children}</Content>
    </Container>
  )
}

export default ValidateEmailLayout
