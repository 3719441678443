import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { useAuth } from "../../hooks/auth-context"

import { Brokers } from "../../services/brokers"

import { API, graphqlOperation } from "aws-amplify"
import { getUserBroker } from "../../graphql/queries"

import LoadingScreenIcon from "../ui/images/loading-screen"
import PageLayout from "../page-layout"
import UserCountryDepositOnboarding from "../deposit/user-country-deposit-onboarding"


const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`


const Deposit = () => {
  const [broker, setBroker] = useState("loading")
  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    const getExecutivesTeamName = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(getUserBroker, { cognitoId: user.cognitoId })
        )
        const userBroker = JSON.parse(response.data.getUserBroker)

        if (
          userBroker.hasOwnProperty("sf_Broker__c") &&
          Brokers.includes(userBroker.sf_Broker__c)
        ) {
          setBroker(userBroker.sf_Broker__c)
        } else {
          setBroker("")
        }
      } catch (error) {
        Sentry.setExtra("error", JSON.stringify(error))
        Sentry.captureException(error)
        toast.error(
          "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
        )

        setBroker("")
      }
    }

    getExecutivesTeamName()
  }, [user.cognitoId])


  if (broker === "loading") {
    return (
      <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>
    )
  }

  return (
    <PageLayout>
      <UserCountryDepositOnboarding broker={broker} showSkip />
    </PageLayout>
  )
}

export default Deposit
