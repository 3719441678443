import React from "react"
import styled from "@emotion/styled"

import webpayLogo from "../../../images/webpay.png"

const Webpay = styled.img`
  width: 165px;
  height: 72px;
`

const WebpayLogo = () => <Webpay src={webpayLogo} alt="webpay" />

export default WebpayLogo
