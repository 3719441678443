import React from "react"
import styled from "@emotion/styled"

import depositIconOn from "../../../images/icn-deposit-on.svg"

const DepositIcon = styled.div`
  background-image: url(${depositIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
`

const DepositIconOn = () => <DepositIcon />

export default DepositIconOn
