import React from "react"

const UploadFailedIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="36" fill="#E76A6A" />
    <circle cx="35.9992" cy="36.0001" r="32.5714" fill="white" />
    <line
      x1="22.3338"
      y1="47.3377"
      x2="46.8389"
      y2="22.8326"
      stroke="#E76A6A"
      strokeWidth="4"
    />
    <line
      x1="24.4142"
      y1="22.5858"
      x2="48.9193"
      y2="47.0909"
      stroke="#E76A6A"
      strokeWidth="4"
    />
  </svg>
)

export default UploadFailedIcon
