import React from "react"
import styled from "@emotion/styled"

import verifyIconOn from "../../../images/icn-verify-documents-on.svg"

const VerifyIcon = styled.div`
  background-image: url(${verifyIconOn});
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
`

const VerifyIconOn = () => <VerifyIcon />

export default VerifyIconOn
