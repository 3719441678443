import React from "react"
import styled from "@emotion/styled"

import bcp from "../../../images/bcp.png"

const BCP = styled.img`
  width: 98px;
  height: 45px;
`

const BCPLogo = () => <BCP src={bcp} alt="BCP" />

export default BCPLogo
