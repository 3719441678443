import React, { ReactNode } from "react"
import styled from "@emotion/styled"

const Alert = styled.div`
  font-family: "Open Sans";
  font-size: 0.825em;
  line-height: 14px;
  color: #363f41;
  background-color: #ddf2fa;
  border-radius: 5px;
  padding: 1rem 1.5rem;
`

type Props = {
  children: ReactNode
}

const AlertBanner = ({ children }: Props) => <Alert>{children}</Alert>

export default AlertBanner
