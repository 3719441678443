import React from "react"
import styled from "@emotion/styled"

import uploadArrow from "../../../images/upload-arrow.svg"

const UploadArrow = styled.div`
  background-image: url(${uploadArrow});
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 24px;
`

const UploadArrowIcon = () => <UploadArrow />

export default UploadArrowIcon
