type AddressType = {
  countryCode: string
  value: string
}

const loadPredictions = async ({countryCode, value}: AddressType) :Promise<PredictionsResults> => {
  const request = {
    fields: ["name", "geometry", "formatted_address", "address_components"],
    componentRestrictions: { country: countryCode },
  }
  const service = new window.google.maps.places.AutocompleteService()
  const result = await service.getPlacePredictions({
    input: value,
    componentRestrictions: request.componentRestrictions,
  })
  return {
    result: result?.predictions || [],
    error: !result?.predictions?.length,
  }
}

export default loadPredictions
