import React from "react"
import styled from "@emotion/styled"

import scotiabank from "../../../images/scotiabank.png"

const Scotiabank = styled.img`
  width: 101px;
  height: 22px;
`

const ScotiabankLogo = () => <Scotiabank src={scotiabank} alt="Scotiabank" />

export default ScotiabankLogo
