import React from "react"
import styled from "@emotion/styled"
import { Link } from "@reach/router"
import { useAuth } from "../../hooks/auth-context"

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.625em;
  line-height: 110%;
  color: #1f2425;
  text-decoration: none;
`
const StyledLinkWhite = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.625em;
  line-height: 110%;
  color: #ffff;
  text-decoration: none;
`

const EmptyLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  line-height: 110%;
  color: #1f2425;
  text-decoration: none;
`

const Item = styled.li`
  text-align: center;
  padding: 0.25rem 0;
  margin-left: 2rem;
  cursor: pointer;
`

const Text = styled.span`
  font-family: "Open Sans";
  font-size: 0.625em;
  line-height: 14px;
  color: #ffffff;
  @media (max-width: 812px) {
    display: none;
  }
`

const IconWrapper = styled.div``

type Props = {
  location: Location
  path?: string
  iconOn: any
  iconOff: any
  text: string
  onClick?: () => void
}

const HeaderItem = ({
  location,
  path,
  iconOn,
  iconOff,
  text,
  onClick,
}: Props) =>{
  const { state } = useAuth()
  const user = state.user as User

  const StyledLinkComponent = user.kycNextStep === "home" ? StyledLinkWhite : StyledLink
  return (
    <Item>
      {path ? (
        <StyledLinkComponent to={path}>
          {location.pathname === path ? iconOn : iconOff}
          {text}
        </StyledLinkComponent>
      ) : (
        <EmptyLink onClick={onClick ? () => onClick() : undefined}>
          <IconWrapper>{iconOff}</IconWrapper>
          <Text>{text}</Text>
        </EmptyLink>
      )}
    </Item>
  )
}

export default HeaderItem
