import { addressInputStyle, inputStyle } from "./styles"
import { jobsCategory, otherJobs } from "../../../helpers/jobs-category"
import occupation from "../../../helpers/occupation"

const fields: FieldPersonalInfo[] = [
  {
    name: "residenceCountry",
    label: "País de residencia",
    placeholder: "Seleccionar país",
    style: inputStyle,
    type: "country",
    width: 219,
  },
  {
    name: "address",
    label: "Dirección",
    placeholder: "Calle y número",
    type: "maps",
    id: "inputAddress",
  },
  {
    name: "addressExtension",
    label: "Block/Depto/Casa (opcional)",
    style: addressInputStyle,
    type: "text",
  },
  {
    name: "job",
    label: "Área de trabajo",
    placeholder: "Agricultura",
    type: "select",
    width: 219,
    listOptions: jobsCategory,
  },
  {
    name: "otherJob",
    label: "Otra área",
    style: inputStyle,
    type: "text",
    width: 219,
    dependsOf: (jobSelected: any) => {
      return jobSelected === otherJobs
    },
  },
  {
    name: "occupation",
    label: "Profesión / ocupación / oficio",
    placeholder: "Gerente",
    type: "select",
    width: 219,
    listOptions: occupation,
  },
]

export default fields
