import React from "react"
import styled from "@emotion/styled"

import signUpFox from "../../../images/sidebar-fox.png"

const SignUpFoxBox = styled.div`
  background-image: url(${signUpFox});
  background-repeat: no-repeat;
  background-size: cover;
  width: 27vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 850px) {
    display: none;
  }
`

const ContainerTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  height: 15%;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 40px;
  @media (min-width: 1900px) {
    height: 20%;
  }
`

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 55px;
`

const FirstText = styled.h1`
  font-family: Montserrat;
  font-weight: 900;
  color: #fff;
  margin: 0px 5px;
  text-shadow: 6px 4px 10px black;
  font-size: 3.2vw;
`

const SecondText = styled.h1`
  font-family: Montserrat;
  font-weight: 900;
  color: #43b649;
  font-size: 3.2vw;
  margin: 0px;
  text-shadow: 6px 4px 10px black;
`

const ContentSubtitle = styled.div`
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.h3`
  font-family: Roboto;
  font-size: 1.7vw;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin: 0px;
`

const SubtitleBold = styled.h3`
  font-family: Roboto;
  font-size: 1.7vw;
  font-weight: 950;
  letter-spacing: -0.03em;
  text-align: center;
  color: #fff;
  margin: 0px;
`

const SignUpFox = () => (
  <>
  <SignUpFoxBox>
    <ContainerTitleAndSubtitle>
      <ContentTitle>
        <FirstText>VIEJO </FirstText><SecondText> ZORRO</SecondText>
      </ContentTitle>
      <ContentSubtitle>
        <Subtitle>
          Si te sientes vivo en ambientes de riesgo,
        </Subtitle>
        <SubtitleBold>eres de los nuestros.</SubtitleBold>
      </ContentSubtitle>
    </ContainerTitleAndSubtitle>
    </SignUpFoxBox>
  </>
)

export default SignUpFox
