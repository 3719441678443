import React from "react"

const FlagUruguay = (style:any) => (

  <svg width={style.width} height={style.height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="g10">
		<g id="g12">
		<g id="Clip path group">
		<g id="clipPath16">
		<path id="path18" d="M0 0H15V15H0V0Z" fill="white"/>
		</g>
		{/* </mask> */}
		<g mask="url(#mask0_825_287)">
		<g id="g14">
		<g id="g20">
		<path id="path22" d="M0 11.2503C0 12.1707 0.74625 12.917 1.66667 12.917H13.3333C14.2537 12.917 15 12.1707 15 11.2503V10.8337H0V11.2503Z" fill="#0038A8"/>
		</g>
		<path id="path24" d="M0 9.5835H15V7.91683H0V9.5835Z" fill="#0038A8"/>
		<path id="path26" d="M0 10.8335H15V9.5835H0V10.8335Z" fill="#EEEEEE"/>
		<g id="g28">
		<path id="path30" d="M14.9998 3.75C14.9998 2.82958 14.2536 2.08333 13.3332 2.08333H6.6665V3.75H14.9998Z" fill="#0038A8"/>
		</g>
		<path id="path32" d="M6.6665 6.66699H14.9998V5.00033H6.6665V6.66699Z" fill="#0038A8"/>
		<g id="g34">
		<path id="path36" d="M6.66667 3.75033V2.08366H6.45833H1.66667C0.74625 2.08366 0 2.82991 0 3.75033V7.60449V7.91699H6.66667H15V6.66699H6.66667V5.00033H15V3.75033H6.66667Z" fill="#EEEEEE"/>
		</g>
		<g id="g38">
		<path id="path40" d="M5.4166 5.00016C5.4166 3.84933 4.4841 2.91683 3.33326 2.91683C2.18243 2.91683 1.24951 3.84933 1.24951 5.00016C1.24951 6.15058 2.18243 7.0835 3.33326 7.0835C4.4841 7.0835 5.4166 6.15058 5.4166 5.00016Z" fill="#FFD983"/>
		</g>
		<g id="g42">
		<path id="path44" d="M5.00025 5.00033C5.00025 4.07991 4.25442 3.33366 3.33359 3.33366C2.41275 3.33366 1.6665 4.07991 1.6665 5.00033C1.6665 5.92033 2.41275 6.66699 3.33359 6.66699C4.25442 6.66699 5.00025 5.92033 5.00025 5.00033Z" fill="#FFCC4D"/>
		</g>
		<g id="g46">
		<path id="path48" d="M3.33344 2.33221L3.59719 4.01596L4.66761 2.68971L4.05386 4.27971L5.64386 3.66637L4.31761 4.73679L6.00136 5.00012L4.31761 5.26387L5.64386 6.33429L4.05386 5.72096L4.66761 7.31096L3.59719 5.98471L3.33344 7.66846L3.06969 5.98471L1.99928 7.31096L2.61303 5.72096L1.02303 6.33429L2.34928 5.26387L0.665527 5.00012L2.34928 4.73679L1.02303 3.66637L2.61303 4.27971L1.99928 2.68971L3.06969 4.01596L3.33344 2.33221Z" fill="#FFAC33"/>
		</g>
		<g id="g50">
		<path id="path52" d="M4.58294 5C4.58294 4.30958 4.02335 3.75 3.33294 3.75C2.6421 3.75 2.08252 4.30958 2.08252 5C2.08252 5.69 2.6421 6.25 3.33294 6.25C4.02335 6.25 4.58294 5.69 4.58294 5Z" fill="#C1694F"/>
		</g>
		<g id="g54">
		<path id="path56" d="M2.43066 5.00177C2.43066 4.50594 2.83233 4.10427 3.32775 4.10427C3.82358 4.10427 4.22525 4.50594 4.22525 5.00177C4.22525 5.49677 3.82358 5.89844 3.32775 5.89844C2.83233 5.89844 2.43066 5.49677 2.43066 5.00177Z" fill="#FFAC33"/>
		</g>
		</g>
		</g>
		</g>
		</g>
		</g>
	</svg>
)

FlagUruguay.defaultProps = {
	width: "15",
	height: "15",
}

export default FlagUruguay
