import React from "react"
import styled from "@emotion/styled"

import mapPin from "../../../images/pin-map-icon.svg"

const Image = styled.img`
  width: 15px;
  height: 15px;
`

const MapPinIcon = () => (
  <Image src={mapPin} alt="map pin" />
)

export default MapPinIcon
