export const otherJobs: string = "Otras"

export const listJobsCategory: string[] = [
  "Agricultura",
  "Alimentos",
  "Bienes Raíces",
  "Científica/Técnica",
  "Comunicación",
  "Construcción",
  "Educación",
  "Finanzas",
  "Gestión",
  "Información",
  "Manufactura",
  "Mayorista",
  "Minera",
  "Residuos",
  "Sector Público",
  "Retail",
  "Salud",
  "Seguros",
  "Servicios",
  "Transporte",
  otherJobs,
]

export const jobsCategory = listJobsCategory.map(jobCategory => ({label: jobCategory, value: jobCategory}))
