import CustomInput from "../../ui/custom-form-input"
import CustomButton from "../../ui/custom-button"
import CustomDatePicker from "../../ui/custom-date-picker"
import CustomCountryPicker from "../../ui/custom-country-picker"
import CustomCheckbox from "../../ui/custom-checkbox"
import CustomSelectPicker from "../../ui/custom-select"
import styled from "@emotion/styled"

const Input = styled.input`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  height: 48px;
  width: 458px;
  @media (max-width: 812px) {
    width: 327px;
  }
`

const components: Record<string, React.ElementType> = {
  maps: Input,
  text: CustomInput,
  button: CustomButton,
  date: CustomDatePicker,
  country: CustomCountryPicker,
  checkbox: CustomCheckbox,
  select: CustomSelectPicker,
}

export default components
