const timeframes: Option[] = [
  {
    value: "1d",
    text: "Hace 1 día"
  },
  {
    value: "3d",
    text: "Hace 3 días"
  },
  {
    value: "1w",
    text: "Hace 1 semana"
  },
  {
    value: "2w",
    text: "Hace 2 semana"
  },
  {
    value: "1m",
    text: "Hace 1 mes"
  },
]

export default timeframes
