import React from "react"
import styled from "@emotion/styled"

import WithdrawIcon from "../ui/images/withdraw-icon"

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  @media (max-width: 812px) {
    padding: 3rem 1.5rem 5rem;
  }
`

const Content = styled.div`
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  max-width: 440px;
  font-size: 14px;
  line-height: 140%;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
`

const Title = styled.h4`
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #1f2425;
  margin: 0.25rem 0 1.5rem;
`

const Withdraw = () => (
  <Container>
    <Content>
      <Logo>
        <WithdrawIcon />
      </Logo>
      <Title>¿Cómo hacer un retiro?</Title>
      <p>
        Para realizar un retiro, escríbenos a{" "}
        <strong>rescates@capitaria.com</strong>, con la siguiente información:
      </p>

      <ul>
        <li>Usuario de MT5</li>
        <li>Nombre</li>
        <li>RUT</li>
        <li>Datos cuenta Corriente</li>
        <li>Monto a rescatar</li>
      </ul>

      <p>
        <strong>*Importante:</strong> El email debe ser enviado desde el mismo
        correo con el que se creó la cuenta.
        <br />
        Más del 90% de los retiros se realizan en menos de 4 horas.
        <br />
        Lo máximo que nos podemos demorar son 2 días hábiles.
        <br />
      </p>
    </Content>
  </Container>
)

export default Withdraw
