import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "@emotion/styled"

import mastercardImage from "../../images/mastercard.png"
import visaImage from "../../images/visa.png"
import CreditCardImage from "../../images/credit-card"
import BankTransferImage from "../../images/bank-transfer"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"


const ContainerNuvei = styled.div`
  min-width: 600px;
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    max-width: 414px;
  }
`

const ContainerSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 414px;
  margin-bottom: 20px;
  width: 100%;
`

const ContainerCreditCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
`

const ImgCreditCard = styled.img`
  width: 44px;
  height: 44px;
  margin: 0 10px;
`

const Selector = styled.div`
  width: 200px;
  font-size: 14px;
  line-height: 18px;
  color: #1F2425;
  background-color: #F9FAFB;
  padding: 9px 14px;
  border: 1px solid #C4CDD5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &.active {
    background-color: #FFFFFF;
    border-color: #2BA770;
    color: #2BA770;
  }
`

const PrefixSpan = styled.span`
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: black;
  text-align: center;
  border: 1px solid #919eab;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  border-right: 0;
  border-radius: 8px 0px 0px 8px;
`

const ContainerInput = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
`

const WrapperInput = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const InputDeposit = styled.input`
  border: 1px solid #919eab;
  border-radius: 8px;
  width: 358px;
  height: 56px;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
`

const ButtonDeposit = styled.button`
  background-color: #2BA770;
  color: white;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 50px;
`

const ContainerButton = styled(WrapperInput)`
  margin-top: 15px;
`

const ImageSelector = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

const TextSelector = styled.div``

const MessageContainer = styled.div`
  background-color: #fffbe6;
  border: 1px solid #ffd666;
  padding: 0 15px;
  border-radius: 5px;
  max-width: 377px;
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 25px;
  @media (max-width: 450px) {
    width: 97%;
  }
`

const Message = styled.p`
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
`

type Props = {
  currency: string
  amount: number
  setAmount: Dispatch<SetStateAction<number>>
  onClickPay: () => Promise<void>
  setPaymentMethod: Dispatch<SetStateAction<string>>
}

const defaultStateActive = {
  creditcard: false,
  banktransfer: false,
}

const TypePayments = ({ currency, amount, setAmount, onClickPay, setPaymentMethod }: Props) => {

  const [activePayments, setActivePayments] = useState({ creditcard: false, banktransfer: true })

  const onActivePayment = (type: string) => () => {
    setPaymentMethod(type)
    setActivePayments({
      ...defaultStateActive,
      [type]: true,
    })
  }

  const displayMessageWarning = useFeatureIsOn("payment-bank-transfer-mexico-problem")
  const messageWarning = useFeatureValue("payment-bank-transfer-mexico-message", "Intermitencia al realizar depósitos")

  return (
    <ContainerNuvei>
      {displayMessageWarning && (
        <MessageContainer>
          <Message>
            {messageWarning.split('<br/>').reduce((acc: any, value: string) => ([...acc, value, <br />]), []).slice(0, -1)}
          </Message>
        </MessageContainer>
      )}
      <ContainerSelector>
        <Selector
          className={activePayments.banktransfer ? "active" : ""}
          onClick={onActivePayment('banktransfer')}
        >
          <ImageSelector>
            <BankTransferImage active={activePayments.banktransfer} />
          </ImageSelector>
          <TextSelector>Transferencia Bancaria</TextSelector>
        </Selector>
        <Selector
          className={activePayments.creditcard ? "active" : ""}
          onClick={onActivePayment('creditcard')}
        >
          <ImageSelector>
            <CreditCardImage active={activePayments.creditcard} />
          </ImageSelector>
          <TextSelector>Tarjeta de Crédito o Débito</TextSelector>
        </Selector>
      </ContainerSelector>
      <WrapperInput>
        <ContainerInput>
          <PrefixSpan>{currency}</PrefixSpan>
          <InputDeposit
            id="sessionField"
            placeholder="Monto a depositar"
            type="text"
            value={amount || ""}
            onChange={(e) => setAmount(parseInt(e.target.value, 10))}
          />
        </ContainerInput>
      </WrapperInput>
      <ContainerButton>
        <ButtonDeposit className="pay-button" onClick={onClickPay}>
          Depositar
        </ButtonDeposit>
      </ContainerButton>
      <ContainerCreditCard>
        <ImgCreditCard src={visaImage} alt="visa" />
        <ImgCreditCard src={mastercardImage} alt="mastercard" />
      </ContainerCreditCard>
    </ContainerNuvei>
  )
}

export default TypePayments
