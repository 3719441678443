import { navigate } from "@reach/router"
import React, { useState } from "react"
import { trackEvent } from "../../helpers/tracker"

import { useAuth } from "../../hooks/auth-context"
import { confirmSignUpAndSignIn, resendValidateCode } from "../../services/auth"

import ValidateCode from "./validate-code"

type Props = {
  email: string
  password: string
}

const ValidateEmailContent = ({ email, password }: Props) => {
  const {
    state: { setUser, setFirstLoad },
  } = useAuth()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const validateEmail = async (code: string) => {
    setLoading(true)

    const result = await confirmSignUpAndSignIn(email, password, code)

    if (result.user) {
      setUser(result.user)
      setFirstLoad(true)
      trackEvent("validate-email:success")
      await navigate("/loading")
    }

    setError(result.error)
    setLoading(false)
  }

  const resendCode = async () => {
    setLoading(true)

    const result = await resendValidateCode(email)

    setError(result.error)
    setLoading(false)
  }

  return (
    <ValidateCode
      email={email}
      resendCode={resendCode}
      onSubmit={validateEmail}
      loading={loading}
      submitError={error}
    />
  )
}

export default ValidateEmailContent
