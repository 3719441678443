import React from "react"
import { Global, css } from "@emotion/core"

const PublicLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <Global
      styles={css`
        #root > div {
          height: 100%;
        }
      `}
    />
    {children}
  </>
)

export default PublicLayout
