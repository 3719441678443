/** @jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { jsx, SerializedStyles } from "@emotion/core"

const ButtonContainer = styled.a`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding: 16px 38px;
  width: 240px;
  text-decoration: none;
  text-align: center;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
`

type Props = {
  children: React.ReactNode
  className?: string
  href: string
  onClick?: () => void
  styleCSS?: SerializedStyles
  target?: string
}

const Button = ({
  children,
  className,
  href,
  onClick = () => {},
  styleCSS,
  target = "_self",
}: Props) => (
  <ButtonContainer
    css={styleCSS}
    className={className}
    href={href}
    onClick={onClick}
    target={target}
  >
    {children}
  </ButtonContainer>
)

export default Button
