import React, { useContext, useState } from "react"

type Simulator = {
  nemo: string
  direction: string
  size: string
  date: string
}

type ContextValues = {
  values: Simulator
  setValues: (name: any, content: any) => void
  response: PricesApiResponse
  setResponse: React.Dispatch<React.SetStateAction<PricesApiResponse>>
  tooltipIsOpen: string
  setTooltipIsOpen: React.Dispatch<React.SetStateAction<string>>
}

const initialForm: ContextValues = {
  values: {
    nemo: "",
    direction: "",
    size: "",
    date: "",
  },
  setValues: () => {},
  response: {
    instruments: [],
    price_now: []
  },
  setResponse: () => {},
  tooltipIsOpen: "",
  setTooltipIsOpen: () => {},
}

const Context = React.createContext(initialForm)

function ContextProvider({ children }: any) {
  const [values, _setValues] = useState({
    nemo: "#AAPL",
    direction: "ask",
    size: "0.1",
    date: "1d",
  })
  const [response, setResponse] = useState<PricesApiResponse>({})
  const setValues = (name: any, content: any) =>
    _setValues({
      ...values,
      [name]: content,
    })
  const [tooltipIsOpen, setTooltipIsOpen] = useState<string>("")

  return (
    <Context.Provider
      value={{
          values,
          setValues,
          response,
          setResponse,
          tooltipIsOpen,
          setTooltipIsOpen,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useSimulator = () => useContext(Context)

export { ContextProvider, Context, useSimulator }
