import React from "react"
import styled from "@emotion/styled"

import Spinner from "../../images/loader-simulator.svg"

const Button = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;  
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  margin-top: 25px;
  background: #30AB76;
  color: #FFF;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 65px;
  padding-left: 65px;
  border-radius: 5px;
  text-align: center;
  width: 284px;
  cursor: pointer;
  @media (max-width: 812px) {
    margin-top: 10px;
    width: 200px;
    gap: 0px;
  }
`

const ButtonWrapper = styled.div`
  width: 58%;
  @media (max-width: 812px) {
    position: relative;
    right: 0px;
    width: 155px;
  }
`

type Button = {
  text: string
  loading: boolean
  onClick: () => void
}

const SpinnerIcon = () => <img src={Spinner} width="25" height="25" alt="" />
const SpinnerWrapper = ({ children }: any) => {
  const Wrapper = styled.div`
    height: 25px;
    width: 18%;
    padding-left: 10px;
    @media (max-width: 812px) {
      padding-left: 0px;
      width: auto;
      position: absolute;
      left: 40px;
    }
  `
  return <Wrapper>{children}</Wrapper>
}

export default function ButtonSimulator({ text, loading, onClick }: Button) {
  return (
    <Button onClick={onClick}>
      <SpinnerWrapper>{loading && <SpinnerIcon />}</SpinnerWrapper>
      <ButtonWrapper>{text}</ButtonWrapper>
    </Button>
  )
} 