import React from 'react';
import styled from '@emotion/styled';

import { trackEvent } from '../../../helpers/tracker';

const Wrapper = styled.div`
  grid-row: 1;
  transition: width 3s, height 3s, transform 3s;
  height: 197px;
  width: 277px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px 0px #0000001A;
  cursor: pointer;
  @media (max-width: 812px) {
    width: auto;
    margin-bottom: 20px;
    margin-left: 20px;
    justify-content: center;
  }
`

const Icon = styled.img`
  height: 90px;
`

const CenterIcon = styled.div`
  margin-left: auto;
  margin-right: auto;
`

const SourceLink = styled.a`
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-decoration: none;
`

const Title = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

export default function Source(props: Source) {
  return (
    <Wrapper
      onClick={() => {
        trackEvent(`homeprospecto:links:${props.label}`)
        window.open(props.href, '_blank');
      }}
    >
      <CenterIcon>
        <Icon src={props.icon} />
      </CenterIcon>
      <Title>
        <SourceLink>{props.title}</SourceLink>
      </Title>
    </Wrapper>
  )
}
