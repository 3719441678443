import React from "react"
import styled from "@emotion/styled"
import { Link } from "@reach/router"

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.625em;
  line-height: 110%;
  color: #1f2425;
  text-decoration: none;
`

const ExternalLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 0.625em;
  line-height: 110%;
  color: #1f2425;
  text-decoration: none;
`

const Item = styled.li`
  text-align: center;
  padding: 0.5rem 0.5rem 1rem;
  border-left: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
`

const activeItem = {
  borderLeft: "4px solid #30ab76",
}

type Props = {
  location: Location
  path: string
  iconOn: any
  iconOff: any
  text: string
  external?: boolean
  alias?: string[]
}

const SidebarItem = ({
  location,
  path,
  iconOn,
  iconOff,
  text,
  external,
  alias
}: Props) => {
  let { pathname } = location
  const isActive = alias ? alias.includes(pathname) : path === pathname

  return (
    <Item style={isActive ? activeItem : undefined}>
      {external ? (
        <ExternalLink href={path} target="_blank" rel="noopener noreferrer">
          {iconOff}
          {text}
        </ExternalLink>
      ) : (
        <StyledLink to={path}>
          {pathname === path ? iconOn : iconOff}
          {text}
        </StyledLink>
      )}
    </Item>
  )
}

export default SidebarItem
