import React from "react"
import styled from "@emotion/styled"

import helpIconOff from "../../../images/icn-help-off.svg"

const HelpIcon = styled.div`
  background-image: url(${helpIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
`

const HelpIconOff = () => <HelpIcon />

export default HelpIconOff
