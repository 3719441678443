import amplitude from "amplitude-js"

type Params = {
  [key: string]: string
}

/**
 * Esta función estara temporalmente aquí, ya que el paquete de
 * capitaria/capitaria-utms tiene la misma funcionalidad
 */
export const getQueryParams = (qs: string) => {
  qs = qs.split("+").join(" ")

  const params: Params = {}
  let tokens
  const re = /\butm_([^=]+)=([^&]+)/g

  while ((tokens = re.exec(qs))) {
    params["utm_" + decodeURIComponent(tokens[1])] = decodeURIComponent(
      tokens[2]
    )
  }

  return params
}

export const pathnameToEventName = (pathname: string) =>
  pathname.slice(1).replace(/\//g, ":")

export const trackPage = (
  pathname: string,
  mcOrigen?: string,
  withParams?: boolean
) => {
  if (pathname !== "/") {
    let properties: any = { pageview: pathname }
    if (mcOrigen) properties.mc_origen = mcOrigen

    const utm_params = getQueryParams(window.location.search)

    if (withParams && Object.keys(utm_params).length) {
      properties = { ...utm_params, ...properties }
    }

    if (pathname === "/home") pathname = "/profile"

    trackEvent(`${pathnameToEventName(pathname)}:pageview`, properties)
  }
}

export const trackEvent = (event: string, properties?: any) => {
  amplitude.getInstance().logEvent(event, properties)
}

export const trackUserData = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties)
}

export const trackUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId)
}

export const trackUserEvent = (
  event: string,
  userProperties: any,
  eventProperties?: any
) => {
  trackUserData(userProperties)
  trackEvent(event, eventProperties)
}

export const logoutTrackUser = () => {
  amplitude.getInstance().clearUserProperties()
}

export const trackEventMetamap = (event: string, properties?: any) => {
  trackEvent(`metamap-v3:${event}`, properties)
}
