import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

const ResendText = styled.span`
  font-size: 12px;
  font-family: Roboto;
  margin-top: 18px;
  margin-bottom: 8px;
  line-height: 140%;
  color: #363f41;
`

const ResendLink = styled.a`
  font-size: 12px;
  text-decoration: underline;
  line-height: 140%;
  font-weight: bold;
  cursor: pointer;
  font-family: Roboto;
  &.disabled {
    color: #363f41;
    cursor: not-allowed;
    text-decoration: none;
  }
`

type Props = {
  resendCode: () => void
}

const TIMER = 60

const ResendCode = ({ resendCode }: Props) => {
  const [seconds, setSeconds] = useState(TIMER)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    let interval: any = null

    if (isActive) {
      if (seconds === 0) {
        clearInterval(interval)
        setIsActive(false)
        setSeconds(TIMER)
      } else {
        interval = setInterval(() => setSeconds(seconds => seconds - 1), 1000)
      }
    }
    return () => clearInterval(interval)
  }, [isActive, seconds])

  const onClick = () => {
    if (!isActive) {
      setIsActive(true)
      resendCode()
    }
  }

  return (
    <>
      <ResendText>¿No has recibido ningún código?</ResendText>
      <ResendLink className={isActive ? "disabled" : ""} onClick={onClick}>
        {!isActive
          ? "Reenviar Código"
          : `Puedes volver a enviar tu código en ${seconds} segundo${
              seconds !== 1 ? "s" : ""
            }`}
      </ResendLink>
    </>
  )
}

export default ResendCode
