import React from "react"
import styled from "@emotion/styled"

import bbva from "../../../images/bbva.png"

const BBVA = styled.img`
  width: 95px;
  height: 54px;
`

const BBVALogo = () => <BBVA src={bbva} alt="BBVA" />

export default BBVALogo
