const PlansList: Array<Plan> = [
  {
    name: "Pro",
    amountUSD: "6.000",
    amountCLP: "5.000.000",
    description: "Todo lo necesario para no perderte ninguna oportunidad",
    footer: [
      "+450 instrumentos",
      "Apalancamiento x100",
      "Opera desde 0,1 lotes",
      "Sin costo de mantención",
    ],
    recommended: true,
  },
  {
    name: "Premium",
    amountUSD: "30.000",
    amountCLP: "25.000.000",
    description: "Una cuenta para los traders más exigentes",
    footer: [
      "+450 instrumentos",
      "Apalancamiento x100",
      "Opera desde 0,5 lotes",
      "Sin costo de mantención",
      "Menor Spread",
    ],
    recommended: false,
  },
]

export default PlansList
