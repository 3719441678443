import React, { useEffect } from "react"
import { navigate, useLocation } from "@reach/router"

import { useAuth } from "../../hooks/auth-context"

type Props = {
  children: React.ReactNode
}

const CheckKycNextStep = ({ children }: Props) => {
  const { state } = useAuth()
  const location = useLocation()

  useEffect(() => {
    const checkKycStep = async () => {
      const user = state.user as User
      if (location.pathname !== `/${user.kycNextStep}`) {
        await navigate(`/${user.kycNextStep}`, { replace: true })
      }
    }

    checkKycStep()
  }, [location, state])

  return <>{children}</>
}

export default CheckKycNextStep
