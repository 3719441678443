import React, { CSSProperties } from "react"
import { Modal } from "react-responsive-modal"
import styled from "@emotion/styled"
import { SerializedStyles } from "@emotion/core"

import "react-responsive-modal/styles.css"

import X from "../ui/images/x-icon"

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-weight: 600;
  border-radius: 2px;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  color: #ffffff;
  padding: 1rem;
`

type Props = {
  title: string
  children: any
  isOpen: boolean
  closeModal: () => void
  animationEnd?: () => void
  styleCSS?: SerializedStyles
  styleObj?: CSSProperties
  closeOnEsc?: boolean
  closeOnOverlayClick?: boolean
  showCloseIcon?: boolean
}

const CustomModal = ({
  title,
  children,
  isOpen,
  closeModal,
  animationEnd = () => { },
  styleCSS,
  styleObj,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  showCloseIcon = true,
}: Props) => (
  <Modal
    open={isOpen}
    onClose={closeModal}
    classNames={{
      overlay: "custom-modal-overlay-display",
      modal: "custom-modal",
    }}
    closeIcon={<X />}
    onAnimationEnd={animationEnd}
    closeOnEsc={closeOnEsc}
    closeOnOverlayClick={closeOnOverlayClick}
    showCloseIcon={showCloseIcon}
  >
    <Header css={styleCSS} style={styleObj}>{title}</Header>
    {children}
  </Modal>
)

export default CustomModal
