import styled from "@emotion/styled"
import React from "react"

const Wrapper = styled.div`
  background: white;
  position: relative;
  width: 457px;
  padding: 10px;
  font-size: 13px;
  z-index: 10;
  border-bottom: 1px solid #ccc;
  @media (max-width: 812px) {
    position: relative;
    width: 327px;
  }
`

const FindingResults = ({ children }: any) => (
  <Wrapper>
    {children}
  </Wrapper>
)

export default FindingResults