import React from "react"
import styled from "@emotion/styled"

import loginFox from "../../../images/login-sidebar-fox.png"

const LoginFoxBox = styled.div`
  background-image: url(${loginFox});
  background-repeat: no-repeat;
  background-size: cover;;
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 850px) {
    display: none;
  }
`

const ContainerBothTexts = styled.div`
  display: flex;
  flex-direction: column;
  height: 15%;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 10px 40px;
  @media (min-width: 1900px) {
    height: 15%;
  }
`

const WelcomeText = styled.h1`
  font-family: Montserrat;
  font-weight: 700;
  color: #fff;
  margin: 0px 5px;
  text-shadow: 6px 4px 10px black;
  font-size: 2.7vw;
`

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 85px;
  margin: 0 10px 40px;
`

const FirstText = styled.h1`
  font-family: Montserrat;
  font-weight: 900;
  color: #fff;
  margin: 0px 5px;
  text-shadow: 6px 4px 10px black;
  font-size: 3.2vw;
`

const SecondText = styled.h1`
  font-family: Montserrat;
  font-weight: 900;
  color: #43b649;
  font-size: 3.2vw;
  margin: 0px;
  text-shadow: 6px 4px 10px black;
`

const LoginFox = () => (
  <>
    <LoginFoxBox>
      <ContainerBothTexts>
        <WelcomeText>BIENVENIDO</WelcomeText>
        <ContentTitle>
          <FirstText>VIEJO </FirstText><SecondText> ZORRO</SecondText>
        </ContentTitle>
      </ContainerBothTexts>
    </LoginFoxBox>
  </>
)
export default LoginFox
