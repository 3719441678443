import React from "react"
import { FocusEvent, ChangeEvent } from "react"
import styled from "@emotion/styled"
import Select, { OptionTypeBase } from "react-select"

import ErrorIcon from "../ui/images/error-icon"

const FormGroup = styled.div`
  margin-bottom: 1rem;
`

const Label = styled.label`
  font-family: Nunito;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
`

const Error = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  padding: 0.5rem 0;
  max-width: 352px;
`

const ErrorMessage = styled.span`
  padding-top: 3px;
  margin-left: 0.5rem;
`

type Options = {
  value: string
  label: string
}

type Props = {
  label: string
  placeholder: string
  width?: number
  onChange?: (event: string | ChangeEvent<any>) => void
  onBlur?: (event: FocusEvent<any>) => void
  hasError?: boolean | ""
  errorMessage?: string
  listOptions: Options[]
}

const CustomSelectPicker = ({
  label,
  placeholder,
  width,
  onChange,
  onBlur,
  hasError,
  errorMessage,
  listOptions,
}: Props) => {
  const optionsStyles = {
    control: (provided: any) => ({
      ...provided,
      width: width || 352,
      height: 46,
      borderColor: hasError ? "#e76a6a" : "#e8e8e8",
      "@media (maxWidth: 812px)": {
        width: "327px",
      },
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
  }

  return (
    <FormGroup>
      <Label>{label}</Label>

      <Select
        styles={optionsStyles}
        placeholder={placeholder}
        options={listOptions}
        onChange={selectedOption => {
          const option = selectedOption as OptionTypeBase
          if (onChange) onChange(option.value)
        }}
        onBlur={onBlur}
      />

      {hasError && (
        <Error>
          {<ErrorIcon />} <ErrorMessage>{errorMessage}</ErrorMessage>
        </Error>
      )}
    </FormGroup>
  )
}

export default CustomSelectPicker
