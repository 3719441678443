import React from "react"
import styled from "@emotion/styled"

import metaTrader from "../../../images/mt5-logo.png"

const LogoMt5 = styled.img`
  width: 68px;
  height: 84px;
`

const Mt5Logo = () => (
  <LogoMt5 src={metaTrader} alt="MetaTrader 5" />
)

export default Mt5Logo
