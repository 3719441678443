import React, { useState } from "react"
import styled from "@emotion/styled"

import { useQueryString } from "@capitaria/capitaria-utms"

import { useAuth } from "../../hooks/auth-context"
import { login } from "../../services/auth"

import PublicLayout from "../public-layout"
import LoginContent from "./login-content"
import ValidateEmailContent from "../validate-email/validate-email-content"
import LoginFox from "../ui/images/login-fox"

const Container = styled.div`
  display: flex;
  min-height: 100%;
`

const Login = () => {
  const {
    state: { setUser },
  } = useAuth()
  const { parsedQueryString } = useQueryString()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [userNeedEmailValidation, setUserNeedEmailValidation] = useState(false)
  const [error, setError] = useState("")

  const onSubmit = async (emailValue: string, passwordValue: string) => {
    setLoading(true)

    const result = await login(
      emailValue.toLowerCase(),
      passwordValue,
      parsedQueryString.mc_origen
    )

    setEmail(emailValue.toLowerCase())
    setPassword(passwordValue)

    if (result.user) {
      setUser(result.user)
    } else {
      setUserNeedEmailValidation(result.userNeedEmailValidation)
      setError(result.error)

      setLoading(false)
    }
  }

  return (
    <PublicLayout>
      <Container>
        <LoginFox />
        {!userNeedEmailValidation ? (
          <LoginContent
            loading={loading}
            onSubmit={onSubmit}
            submitError={error}
          />
        ) : (
          <ValidateEmailContent email={email} password={password} />
        )}
      </Container>
    </PublicLayout>
  )
}

export default Login
