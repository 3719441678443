import React from "react"
import styled from "@emotion/styled"

import PageLayout from "../page-layout"

import VerifyCard from "./verify-card"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  padding: 2rem 0;
`


const ValidateBankCard = () => {

  return (
    <PageLayout>
      <Container>
        <Content>
          <VerifyCard kyc />
        </Content>
      </Container>
    </PageLayout>
  )
}

export default ValidateBankCard
