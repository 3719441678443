import React from "react"
import styled from "@emotion/styled"

import dropdownIndicatorIcon from "../../../images/dropdown-indicator-icon.svg"

const Icon = styled.img`
  width: 13.679px;
  height: 13.679px;
`

const DropdownIndicatorIcon = () => <Icon src={dropdownIndicatorIcon} alt="check" />

export default DropdownIndicatorIcon
