import React, { useEffect } from "react"
import styled from "@emotion/styled"

import Progressbar from "../ui/progressbar"
import LoaderGif from "../ui/images/loader"
import { useAuth } from "../../hooks/auth-context"
import { getIndexPathFromUser } from "../../helpers/index-path-user"
import { navigate } from "@reach/router"


const Content = styled.div`
  display: flex;
  align-items: center;
  height: 90vh;
  justify-content: center;
`

const Gif = styled.div`
  text-align: center;
`

const Text = styled.div`
  color: #000000;
  font-weight: 700;
`


const ThankYou = () => {
  const { state } = useAuth()
  const user = state.user as User
  const setFirstLoad = state.setFirstLoad

  useEffect(() => {
    let timerId: NodeJS.Timeout = setTimeout(async () => {
      setFirstLoad(false)

      await navigate(getIndexPathFromUser(user), { replace: true })
    }, 4 * 1000)

    return () => {
      clearTimeout(timerId)
    }
  }, [user, setFirstLoad])

  return <>
      <Progressbar />

      <Content>
        <Gif>
          <LoaderGif />
          <Text>PREPARANDO TODO<br />PARA INGRESAR A TU CUENTA</Text>
          </Gif>
      </Content>
    </>
}

export default ThankYou
