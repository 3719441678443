import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import VideoRecorder from '@capitaria/react-video-recorder'
import { Storage } from "aws-amplify"
import { Global, css } from '@emotion/core'
import * as Sentry from "@sentry/browser"
import { toast } from "react-toastify"
import { navigate } from "@reach/router"
import { isFirefox, isMobile } from "react-device-detect"

import CustomModal from '../ui/custom-modal'
import { useAuth } from '../../hooks/auth-context'
import { updateLivenessFileFlag } from "../../services/verify-documents"
import IconComplete from '../verify-documents/images/complete'
import LivenessIcon from '../verify-documents/images/liveness'
import { trackEventMetamap } from '../../helpers/tracker'
import getErrorMessage from '../../helpers/get-message-from-error'

import imageQR from './images/qr-onboarding.png'
import CustomButtonV2 from '../ui/custom-button-v2'

const LoadingMessage = styled.div`
  font-family: Arial;
`

const customStyles = {
}

const TitleModalCSS = {
  display: "none"
}

const InstructionVideoCSS = css`
  width: 339px;
  height: 411px;
  flex-shrink: 0;
  background-color: #f4f6f8;
`

const VideoContainer = styled.div`
  width: 500px;
  height: 888.88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #080F12;
  justify-content: center;
  @media (max-width: 450px) {
    width: 360px;
    height: 640px;
  }
`

const acceptButtonStyle = css`
  font-size: 14px;
  padding: 14px 12px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 19px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 182px;
`

const cancelButtonStyle = css`
  border: 1px solid #2BA770;
  background: #F7F7F7;
  color: #454F5B;
  width: 182px;
  font-size: 14px;
  padding: 14px 12px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 19px;
  margin-top: 10px;
  margin-bottom: 20px;
`

const HowToDo = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h3`
  width: 308px;
  font-family: "Open Sans";
  font-size: 27.65px;
  font-weight: 700;
  line-height: 116%;
  margin: 0 0 10px;
  color: #1f2425;
  text-align: center;
`
const SubTitle = styled.h4`
  width: 254px;
  color: #1F2425;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin: 0;
`
const Description = styled.p`
  max-width: 490px;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 16px;
  color: #1f2425;
  text-align: center;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1.5rem;
`

const PermissionDenied = styled.div`
  color: #fff;
`

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 40px 0 50px 0;
`

const VideoDemo = styled.video`
  border-radius: 50%;
  width: 180px;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
  @media (max-width: 425px) {
    width: 145.12px;
    height: 145.008px;
    flex-shrink: 0;
  }
`

const LinkQR = styled.a`
  font-size: 12px;
  text-decoration: underline;
  line-height: 140%;
  font-weight: bold;
  padding-top: 10px;
  cursor: pointer;
`

const WrapperQR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`

const ContainerTextQR = styled.div`
  font-family: 'Nunito';
  margin: 30px 40px;
`

const ContainerImageQR = styled.img`
  width: 300px;
  margin-bottom: 30px;
`

const ContainerLiveness = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
`

const MessageImportant = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #1F2425;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 260px;
`
const MessageImportantModal = styled.p`
  width: 214px;
  color: #1F2425;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
`

const DescriptionModal = styled.p`
  width: 280px;
  color: #1F2425;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
  margin: 5px 0 0;
`

const SuccessMessage = styled.p`
  width: 173.039px;
  color: #1F2425;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin-bottom: 50px;
`

const ContainerIcon = styled.div`
  margin-top: 45px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    max-width: calc(100% - 100px);
  }
`

const buttonCSS = {
  marginTop: "1rem",
  width: "260px",
  height: "50px",
  "@media (max-width: 425px)": {
    width: "213px",
    fontSize: "18px",
    lineHeight: "138.889%",
    textAlign: "center",
  },
}

const loadingButtonCss = buttonCSS

const LoadingView = () => <LoadingMessage>Cargando permisos y la camara</LoadingMessage>


type VideoWrapperProps = {
  authorizedVideo: boolean
  loading: boolean
  setRecordedVideo: Dispatch<SetStateAction<boolean>>
  recordedVideo: boolean
  setExtensionValue: Dispatch<SetStateAction<string>>
  setVideoBlob: Dispatch<any>
  uploadVideo: () => Promise<void>
  closeVideo: () => void
  uploadingVideo: boolean
}

type ActionsVideoProps = {
  showCancelButton: boolean
  closeVideo: () => void
  recordedVideo: boolean
  uploadVideo: () => Promise<void>
  uploadingVideo: boolean
}

type DiccionaryTextProps = {
  [key: string]: string
}

const translateText = (text: string) => {
  const diccionaryText: DiccionaryTextProps = {
    "Use another video": "Rehacer grabación",
  }
  return text in diccionaryText ? diccionaryText[text] : text
}

const renderActionsVideo = ({
  showCancelButton,
  closeVideo,
  recordedVideo,
  uploadVideo,
  uploadingVideo,
}: ActionsVideoProps) => {

  const listActions = []

  if (showCancelButton) {
    listActions.push(
      <CustomButtonV2
        key="cancel-button-video"
        onClick={() => {
          trackEventMetamap('cancel-video')
          closeVideo()
        }}
        style={cancelButtonStyle}
      >
        Cancelar grabación
      </CustomButtonV2>
    )
  }

  if (!showCancelButton && recordedVideo) {
    listActions.push(
      <CustomButtonV2
        key="accept-button-video"
        onClick={() => {
          trackEventMetamap('upload-video')
          uploadVideo()
        }}
        style={acceptButtonStyle}
        loadingStyle={acceptButtonStyle}
        loading={uploadingVideo}
      >
        Guardar grabación
      </CustomButtonV2>
    )
  }

  return listActions
}

const VideoWrapper = ({
  authorizedVideo,
  loading,
  setRecordedVideo,
  recordedVideo,
  setExtensionValue,
  setVideoBlob,
  uploadVideo,
  closeVideo,
  uploadingVideo,
}: VideoWrapperProps) => {

  const [showCancelButton, setShowCancelButton] = useState(true)

  const constraintsVideoConfig: any = {
    facingMode: 'user',
    width: { ideal: 720 },
    height: { ideal: 1280 },
  }

  if (!isFirefox) {
    constraintsVideoConfig['aspectRatio'] = 9 / 16
  }
  if (isMobile) {
    constraintsVideoConfig['width']['ideal'] = 1280
    constraintsVideoConfig['height']['ideal'] = 720
  }

  return (
    <VideoContainer>
      {loading ? (
        <LoadingView />
      ) : authorizedVideo ? (
        <>
          {/* @ts-ignore */}
          <VideoRecorder
            t={translateText}
            styles={customStyles}
            auto={true}
            countdownTime={2000}
            constraints={{
              audio: false,
              video: constraintsVideoConfig,
            }}
            isOnInitially
            onError={(error: Error) => {
              Sentry.captureException(error)
              trackEventMetamap("open-video-error", { "message-error": getErrorMessage(error) })
            }}
            isCameraSwitcherDisabled={true}
            onOpenVideoInput={() => { console.error('abierto'); return }}
            onStartRecording={() => {
              trackEventMetamap('start-recording')
              setShowCancelButton(false)
              setRecordedVideo(false)
            }}
            onStopReplaying={() => {
              trackEventMetamap('replay-record')
              setRecordedVideo(false)
              setExtensionValue("")
              setVideoBlob(null)
              setShowCancelButton(true)
            }}
            onStopRecording={() => {
              trackEventMetamap('stop-recording')
              setRecordedVideo(true)
            }}
            onSwitchCamera={() => trackEventMetamap('switch-camera')}
            onCameraOn={() => trackEventMetamap('camera-on')}
            onRecordingComplete={(videoBlob: any) => {
              setRecordedVideo(true)
              trackEventMetamap('recorded-video')

              if (Object.prototype.toString.call(videoBlob).slice(8, -1) === "File") {
                setShowCancelButton(false)
                const castFileToBlob = new Blob([videoBlob], { type: videoBlob.type })
                setVideoBlob(castFileToBlob)
              } else {
                setVideoBlob(videoBlob)
              }
              setExtensionValue(videoBlob.type)
            }}
            renderLoadingView={() => <LoadingView />}
            timeLimit={12000} />
          {renderActionsVideo({ showCancelButton, closeVideo, recordedVideo, uploadVideo, uploadingVideo })}
        </>
      ) :
        <>
          <PermissionDenied>Se requiere de permisos para acceder a la camara</PermissionDenied>
          <CustomButtonV2
            key="close-denied-button-video"
            onClick={() => {
              trackEventMetamap('close-denied-video')
              closeVideo()
            }}
            style={cancelButtonStyle}
          >
            Cerrar
          </CustomButtonV2>
        </>
      }
    </VideoContainer>
  )
}

type VideoProps = {
  kyc: boolean
  closeModal: () => void
}

const Video = ({ kyc, closeModal }: VideoProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const [loading, setLoading] = useState(true)
  const [uploadingVideo, setUploadingVideo] = useState(false)
  const [authorizedVideo, setAuthorizedVideo] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [recordedVideo, setRecordedVideo] = useState(false)
  const [videoBlob, setVideoBlob] = useState<any>(null)
  const [extensionValue, setExtensionValue] = useState("")
  const [showLinkQR, setShowLinkQR] = useState(false)
  const [waitingStatus, setWaitingStatus] = useState(true)

  const { state } = useAuth()
  const user = state.user as User

  type FileTypes = {
    [key: string]: string
  }

  const fileTypes: FileTypes = {
    'video/quicktime': '.mov',
    'video/webm': '.webm',
    'video/xmatroska': '.mkv',
  }
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1025px)")

    const handleMediaChange = (e: any) => {
      setShowLinkQR(e.matches)
    }

    handleMediaChange(mediaQuery)

    mediaQuery.addEventListener("change", handleMediaChange)

    setTimeout(() => {
      setWaitingStatus(false)
    }, 5000)

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange)
    }
  }, [])

  useEffect(() => {
    const getPermission = async () => {
      try {
        if (navigator && navigator.permissions && navigator.permissions.query) {
          // @ts-ignore
          const permissionStatus = await navigator.permissions.query({ 'name': 'camera' })
          setLoading(false)
          setAuthorizedVideo(permissionStatus.state !== 'denied')

          trackEventMetamap(
            "permission-video",
            {
              // @ts-ignore
              name: permissionStatus.name,
              state: permissionStatus.state,
            },
          )
        } else {
          setAuthorizedVideo(true)
          setLoading(false)
          trackEventMetamap("whitout-request-permission-video")
        }

      } catch (error) {
        console.error(error)
        trackEventMetamap(
          "error-permission-video",
          {
            "message-error": getErrorMessage(error),
          },
        )
      }
    }

    getPermission()
  }, [])

  const uploadVideo = async () => {
    try {
      setUploadingVideo(true)
      const extension = !fileTypes.hasOwnProperty(extensionValue) ? '.mp4' : fileTypes[extensionValue]
      const fileName = "liveness"
      await Storage.put(`${fileName}${extension}`, videoBlob, {
        level: "private",
        contentType: extensionValue,
        progressCallback: (progress: any) => {
          console.log(
            Math.round((progress.loaded / progress.total) * 100)
          )
        },
      })
      await uploadComplete()
      setUploadingVideo(false)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      trackEventMetamap(
        "error-uploaded-video",
        {
          "message-error": getErrorMessage(error),
        },
      )
    }
  }

  const closeVideo = () => setShowVideo(false)

  const uploadComplete = async () => {
    try {
      const result = await updateLivenessFileFlag(user, kyc)

      if (result.user) {
        state.setUser(result.user)
      }

      closeVideo()
      if (!kyc) {
        closeModal()
      } else {
        if (result.user?.kycNextStep) {
          navigate(`/${result.user.kycNextStep}`)
        }
      }
    } catch (error) {
      toast.error(
        "No se pudo subir el video. Inténtelo nuevamente más tarde."
      )
      Sentry.captureException(error)
      trackEventMetamap(
        "error-uploaded-video",
        {
          "message-error": getErrorMessage(error),
        },
      )
    }
  }

  const onClickQR = () => {
    setShowQR(true)
    trackEventMetamap('open-QR-Option-liveness')
  }

  return (
    <>
      <ContainerWrapper>
        <HowToDo>
          <SubTitle>Enciende tu cámara para poder validar tu identidad</SubTitle>
          <DescriptionModal>
            Es importante que no uses ningún accesorio que pueda
            dificultar tu validación de rostro
          </DescriptionModal>
          <VideoDemo
            src="https://capitaria-logos.s3-accelerate.amazonaws.com/liveness-test.mp4"
            autoPlay
            playsInline
            controls={false}
            loop
          />
          <MessageImportantModal>
            Enciende la cámara y mueve tu cabeza como este ejemplo para validar que eres una persona real
          </MessageImportantModal>
          <CustomButtonV2
            loading={waitingStatus}
            style={buttonCSS}
            loadingStyle={loadingButtonCss}
            onClick={() => setShowVideo(true)}
          >
            Encender cámara
          </CustomButtonV2>
          {showLinkQR && (
            <LinkQR onClick={onClickQR}>
              Continuar en el telefono
            </LinkQR>
          )}
        </HowToDo>
        <CustomModal
          isOpen={showQR}
          styleObj={TitleModalCSS}
          closeModal={closeVideo}
          title=''
        >
          <WrapperQR>
            <ContainerTextQR>
              Para continuar desde tu telefono movil, debes escanear el siguiente QR
            </ContainerTextQR>
            <ContainerImageQR src={imageQR} alt="" />
            <CustomButtonV2
              style={buttonCSS}
              loadingStyle={loadingButtonCss}
              onClick={() => {
                window.location.reload()
                trackEventMetamap('press-reload-liveness')
              }}
            >
              Terminé!
            </CustomButtonV2>
          </WrapperQR>
        </CustomModal>
      </ContainerWrapper>
      {showVideo && !user.wasLivenessFileUploaded && (
        <CustomModal
          isOpen
          styleObj={TitleModalCSS}
          closeModal={closeVideo}
          title=''
        >
          <VideoWrapper
            authorizedVideo={authorizedVideo}
            loading={loading}
            setRecordedVideo={setRecordedVideo}
            recordedVideo={recordedVideo}
            setExtensionValue={setExtensionValue}
            setVideoBlob={setVideoBlob}
            uploadVideo={uploadVideo}
            closeVideo={closeVideo}
            uploadingVideo={uploadingVideo}
          />
        </CustomModal>
      )}
    </>
  )
}

type Props = {
  kyc: boolean
}

const ValidateLiveness = ({ kyc }: Props) => {
  const { state } = useAuth()
  const user = state.user as User
  const [showVideoModal, setShowVideoModal] = useState(false)

  useEffect(() => {
    const getPermission = async () => {
      try {
        if (navigator && navigator.permissions && navigator.permissions.query) {
          // @ts-ignore
          const permissionStatus = await navigator.permissions.query({ 'name': 'camera' })

          if (permissionStatus.state !== 'denied') {
            const medias = await navigator.mediaDevices.getUserMedia({
              video: {
                facingMode: 'user',
              }
            })
            if (medias.active) {
              const listTracks = medias.getTracks()
              listTracks.forEach(track => track.stop())
            }
          }

          trackEventMetamap(
            "permission-video",
            {
              // @ts-ignore
              name: permissionStatus.name,
              state: permissionStatus.state,
            },
          )
        } else {
          trackEventMetamap("whitout-request-permission-video")
        }

      } catch (error) {
        console.error(error)
        trackEventMetamap(
          "error-permission-video",
          {
            "message-error": getErrorMessage(error),
          },
        )
      }
    }

    getPermission()
  }, [])

  const closeModal = () => setShowVideoModal(false)

  return (
    <ContainerLiveness>
      <Global
        styles={css`
        .custom-modal-overlay-display {
          display: flex;
          align-items: center;
        }
      `}
      />
      <Container>
        <Title>Verificación Visual</Title>
        <Description>
          Por temas de seguridad debes realizar una validación biométrica para asegurarnos que realmente eres tú y asi proteger tu inversión
        </Description>
        {user.wasLivenessFileUploaded ? (
          <>
            <ContainerIcon><IconComplete /></ContainerIcon>
            <SuccessMessage>Grabación enviada exitosamente</SuccessMessage>
            <CustomButtonV2
              style={buttonCSS}
              loadingStyle={loadingButtonCss}
              onClick={() => navigate('/home')}
            >
              Volver
            </CustomButtonV2>
          </>
        ) : (
          <>
            <MessageImportant>
              Es importante que no uses ningún accesorio que pueda dificultar tu validación de rostro
            </MessageImportant>
            <IconWrapper>
              <LivenessIcon />
            </IconWrapper>
            <CustomButtonV2
              style={buttonCSS}
              loadingStyle={loadingButtonCss}
              onClick={() => setShowVideoModal(true)}
            >
              Verificar identidad
            </CustomButtonV2>
          </>
        )}
      </Container>
      {showVideoModal && (
        <CustomModal
          isOpen
          styleCSS={InstructionVideoCSS}
          styleObj={TitleModalCSS}
          closeModal={closeModal}
          title=""
        >
          <Video kyc={kyc} closeModal={closeModal} />
        </CustomModal>
      )}
    </ContainerLiveness>

  )
}

export default ValidateLiveness
