import React from "react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, registerables } from "chart.js"

ChartJS.register(...registerables)

type TypeGraph = {
  prices: number[]
  borderColor: string
}

export default function Graph({ prices, borderColor }: TypeGraph) {
  const datasets = [
    {
      data: prices,
      tension: 0.5,
      label: "",
      borderColor,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      borderWidth: 1,
    },
  ]

  const data = {
    labels: new Array(prices.length).fill(" "),
    datasets,
  }

  return (
    <div className="slds-p-top--medium">
      <div>
        <Line
          width={90}
          height={40}
          data={data}
          options={{
            events: [],
            animation: false,
            scales: {
              x: {
                display: false,
                ticks: {
                  stepSize: 1,
                },
                grid: {
                  display: false,
                  borderColor: "transparent",
                  drawBorder: false,
                  drawTicks: false,
                },
              },
              y: {
                display: false,
                ticks: {
                  stepSize: 1,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                  drawTicks: false,
                },
              },
            },
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </div>
  )
}
