const instruments: Option[] = [
  {
    text: "Amazon.com Inc.",
    value: "#AMZN",
  },
  {
    text: "Apple Inc.",
    value: "#AAPL",
  },
  {
    text: "Alibaba Group Holding Limited",
    value: "#BABA",
  },
  {
    text: "Dolar Americano vs Peso Chileno",
    value: "USDCLP",
  },
  {
    text: "Dólar Americano vs Peso Mexicano",
    value: "USDMXN",
  },
  {
    text: "Dólar Americano vs Yen Japonés",
    value: "USDJPY",
  },
  {
    text: "Euro vs Dólar Americano",
    value: "EURUSD",
  },
  {
    text: "Google",
    value: "#GOOGL",
  },
  {
    text: "Facebook Inc.",
    value: "#META",
  },
  {
    text: "Microsoft Corporation",
    value: "#MSFT",
  },
  {
    text: "Netflix Inc.",
    value: "#NFLX",
  },
  {
    text: "NIO Limited",
    value: "#NIO",
  },
  {
    text: "NVidia Corp.",
    value: "#NVDA",
  },
  {
    text: "XAU vs USD (Oro)",
    value: "XAUUSD",
  },
  {
    text: "S&P 500 INDEX CFD",
    value: "SP",
  },
  {
    text: "Soquimich",
    value: "CLP.SQM",
  },
  {
    text: "Tesla Motors Inc",
    value: "#TSLA",
  },
  {
    text: "Petróleo (WTI)",
    value: "WTI",
  },
]


export default instruments
