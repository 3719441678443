import React from "react"
import styled from "@emotion/styled"

import Source from "./source"
import sourcesList from "./sources-list"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: row;
  @media (max-width: 812px) {
    flex-direction: column;
  }
`


const DesktopCarousel = styled.div`
  display: grid;
  gap: 11px;
  grid-auto-columns: min-content;
  @media (max-width: 812px) {
    display: flow;
  }
`


export default function DisplaySources() {
  return (
    <Wrapper>
      <DesktopCarousel>
        {sourcesList.map((source: Source, index: number) => <Source {...source} key={index} />)}
      </DesktopCarousel>
    </Wrapper>
  )
}
