import React from "react"

import ScrollTop from "../helpers/scroll-top"

type Props = {
  children: React.ReactNode
}

const PageLayout = ({ children }: Props) => (
  <>
    <ScrollTop />
    {children}
  </>
)

export default PageLayout
