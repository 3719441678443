/** @jsx jsx */
import { jsx } from "@emotion/core"
import { MouseEvent } from "react"
import styled from "@emotion/styled"

import SpinnerIcon from "../ui/images/spinner-icon"

const Button = styled.button`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  height: 48px;
  padding: 12px 64px;
  color: #ffffff;
  text-transform: none;
  cursor: pointer;
  border: 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
`

const FakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  padding: 12px 64px;
  color: #ffffff;
  border: 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
`

const DisabledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: #c2c2c2;
  border-radius: 5px;
  padding: 12px 64px;
  color: #ffffff;
  border: 0;
`

type Props = {
  loading?: boolean
  disabled?: boolean
  type?: "button" | "reset" | "submit" | undefined
  style?: any
  disabledStyle?: any
  loadingStyle?: any
  children: any
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const ButtonCustom = ({
  loading = false,
  disabled = false,
  type = "button",
  style,
  disabledStyle,
  loadingStyle,
  children,
  onClick,
  className
}: Props) => {
  if (loading) {
    return (
      <FakeButton css={loadingStyle}>
        <SpinnerIcon />
      </FakeButton>
    )
  }

  if (disabled) {
    return <DisabledButton css={disabledStyle}>{children}</DisabledButton>
  }

  return (
    <Button type={type} css={style} onClick={onClick} className={className}>
      {children}
    </Button>
  )
}

export default ButtonCustom
