import React from "react"
import styled from "@emotion/styled"
import { Formik } from "formik"
import * as Yup from "yup"

import CustomInput from "../ui/custom-form-input"
import CustomButton from "../ui/custom-button"
import ForgotPasswordLayout from "./forgot-password-layout"

const Title = styled.h1`
  font-family: Open Sans;
  font-size: 27.65px;
  line-height: 116%;
  margin: 0;
  color: #1f2425;
  @media (max-width: 812px) {
    max-width: 185px;
    text-align: center;
  }
`

const Description = styled.p`
  font-family: Nunito;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
  margin: 1rem 0 0;
  text-align: center;
  max-width: 380px;
  @media (max-width: 812px) {
    max-width: 198px;
  }
`

const Form = styled.form`
  padding-top: 2em;
`

const buttonCSS = {
  marginTop: "1rem",
  height: "48px",
  width: "350px",
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const loadingButtonCss = { ...buttonCSS, marginTop: "2rem" }

const inputCSS = {
  width: "350px",
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const Error = styled.p`
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  max-width: 300px;
`

const SendCodeSchema = Yup.object().shape({
  email: Yup.string()
    .email("El email ingresado no es válido")
    .required("Este campo es requerido"),
})

type Props = {
  loading: boolean
  onSubmit: (email: string) => void
  submitError: string
}

const SendCode = ({ loading, onSubmit, submitError }: Props) => (
  <ForgotPasswordLayout>
    <Title>¿Olvidaste tu contraseña?</Title>
    <Description>
      ¡No te preocupes! Ingresa el correo con el que creaste tu cuenta y te
      enviaremos un link para que crees una nueva contraseña
    </Description>

    <Formik
      initialValues={{ email: "" }}
      validationSchema={SendCodeSchema}
      onSubmit={values => {
        onSubmit(values.email.toLowerCase())
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props
        return (
          <Form onSubmit={handleSubmit}>
            <CustomInput
              name="email"
              label="Email"
              style={inputCSS}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={errors.email && touched.email}
              errorMessage={errors.email}
            />

            <CustomButton
              style={buttonCSS}
              loading={loading}
              type="submit"
              loadingStyle={loadingButtonCss}
            >
              ENVIAR
            </CustomButton>

            {submitError.length > 0 && <Error>{submitError}</Error>}
          </Form>
        )
      }}
    </Formik>
  </ForgotPasswordLayout>
)

export default SendCode
