import React from "react"

const FlagUsa = () => (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6917 1.11111C19.3067 0.449445 18.5984 0 17.7778 0H10.0001V1.11111H19.6917Z" fill="#B22334"/>
    <path d="M0 12.2222H20V11.1111H0V12.2222Z" fill="#B22334"/>
    <path d="M10 7.77778H20V6.66667H10V7.77778Z" fill="#B22334"/>
    <path d="M10 5.55556H20V4.44444H10V5.55556Z" fill="#B22334"/>
    <path d="M0 10H20V8.88889H0V10Z" fill="#B22334"/>
    <path d="M2.22222 14.4444H17.7778C18.5983 14.4444 19.3067 13.995 19.6917 13.3333H0.308333C0.693333 13.995 1.40167 14.4444 2.22222 14.4444Z" fill="#B22334"/>
    <path d="M10 3.33333H20V2.22222H10V3.33333Z" fill="#B22334"/>
    <path d="M0.0380556 12.5993C0.0475 12.651 0.0580555 12.7027 0.0708333 12.7532C0.0852778 12.8093 0.103611 12.8638 0.121944 12.9177C0.171389 13.0616 0.231389 13.2004 0.306944 13.3304C0.3075 13.3316 0.307778 13.3328 0.308333 13.3333H19.6917C19.6922 13.3328 19.6925 13.3316 19.6931 13.331C19.7681 13.2016 19.8281 13.0627 19.8775 12.9193C19.8964 12.8643 19.9147 12.8093 19.9292 12.7527C19.9425 12.7021 19.9525 12.651 19.9619 12.5999C19.9842 12.4771 20 12.3522 20 12.2222H0C0 12.3517 0.0158333 12.4766 0.0380556 12.5993Z" fill="#EEEEEE"/>
    <path d="M0 11.1111H20V10H0V11.1111Z" fill="#EEEEEE"/>
    <path d="M0 7.77778V8.88889H20V7.77778H10H0Z" fill="#EEEEEE"/>
    <path d="M10 6.66667H20V5.55556H10V6.66667Z" fill="#EEEEEE"/>
    <path d="M10 4.44444H20V3.33333H10V4.44444Z" fill="#EEEEEE"/>
    <path d="M0 2.22222C0 2.09278 0.0166667 1.96833 0.0377778 1.845C0.0155556 1.96778 0 2.09278 0 2.22222Z" fill="#EEEEEE"/>
    <path d="M10 2.22222H20C20 2.09278 19.9844 1.96722 19.9617 1.84444C19.9528 1.79333 19.9422 1.74222 19.9294 1.69222C19.9144 1.635 19.8961 1.57944 19.8772 1.52444C19.8278 1.38111 19.7683 1.24278 19.6928 1.11333C19.6928 1.11278 19.6923 1.11167 19.6917 1.11111H10.0001L10 2.22222Z" fill="#EEEEEE"/>
    <path d="M10.0001 0L2.22222 1.19209e-07C0.995 1.19209e-07 0 0.995 0 2.22222V7.77778H10L10.0001 0Z" fill="#3C3B6E"/>
    <path d="M1.11194 1.51422L1.45528 1.76367L1.32417 2.167L1.66694 1.91755L2.01028 2.167L1.87861 1.76367L2.2225 1.51422H1.79806L1.66694 1.11089L1.53639 1.51422H1.11194Z" fill="white"/>
    <path d="M2.22306 2.62533L2.56639 2.87478L2.43472 3.27811L2.77806 3.02867L3.12139 3.27811L2.98972 2.87478L3.33361 2.62533H2.90917L2.77806 2.222L2.6475 2.62533H2.22306Z" fill="white"/>
    <path d="M4.44528 2.62533L4.78861 2.87478L4.65694 3.27811L5.00028 3.02867L5.34361 3.27811L5.21194 2.87478L5.55583 2.62533H5.13139L5.00028 2.222L4.86972 2.62533H4.44528Z" fill="white"/>
    <path d="M6.6675 2.62533L7.01083 2.87478L6.87917 3.27811L7.2225 3.02867L7.56583 3.27811L7.43417 2.87478L7.77806 2.62533H7.35361L7.2225 2.222L7.09194 2.62533H6.6675Z" fill="white"/>
    <path d="M2.22306 4.84755L2.56639 5.097L2.43472 5.50033L2.77806 5.25089L3.12139 5.50033L2.98972 5.097L3.33361 4.84755H2.90917L2.77806 4.44422L2.6475 4.84755H2.22306Z" fill="white"/>
    <path d="M4.44528 4.84755L4.78861 5.097L4.65694 5.50033L5.00028 5.25089L5.34361 5.50033L5.21194 5.097L5.55583 4.84755H5.13139L5.00028 4.44422L4.86972 4.84755H4.44528Z" fill="white"/>
    <path d="M6.6675 4.84755L7.01083 5.097L6.87917 5.50033L7.2225 5.25089L7.56583 5.50033L7.43417 5.097L7.77806 4.84755H7.35361L7.2225 4.44422L7.09194 4.84755H6.6675Z" fill="white"/>
    <path d="M3.33417 1.51422L3.6775 1.76367L3.54583 2.167L3.88917 1.91755L4.2325 2.167L4.10083 1.76367L4.44472 1.51422H4.02028L3.88917 1.11089L3.75861 1.51422H3.33417Z" fill="white"/>
    <path d="M5.55639 1.51422L5.89972 1.76367L5.76805 2.167L6.11139 1.91755L6.45472 2.167L6.32306 1.76367L6.66694 1.51422H6.2425L6.11139 1.11089L5.98083 1.51422H5.55639Z" fill="white"/>
    <path d="M7.77861 1.51422L8.12194 1.76367L7.99028 2.167L8.33361 1.91755L8.67694 2.167L8.54528 1.76367L8.88917 1.51422H8.46472L8.33361 1.11089L8.20306 1.51422H7.77861Z" fill="white"/>
    <path d="M1.11194 3.73644L1.45528 3.98589L1.32417 4.38922L1.66694 4.13978L2.01028 4.38922L1.87861 3.98589L2.2225 3.73644H1.79806L1.66694 3.33311L1.53639 3.73644H1.11194Z" fill="white"/>
    <path d="M3.546 4.38911L3.88933 4.13967L4.23211 4.38911L4.101 3.98578L4.44433 3.73633H4.02044L3.88933 3.33356L3.75822 3.73633H3.33433L3.67711 3.98578L3.546 4.38911Z" fill="white"/>
    <path d="M5.55639 3.73644L5.89972 3.98589L5.76805 4.38922L6.11139 4.13978L6.45472 4.38922L6.32306 3.98589L6.66694 3.73644H6.2425L6.11139 3.33311L5.98083 3.73644H5.55639Z" fill="white"/>
    <path d="M7.77861 3.73644L8.12194 3.98589L7.99028 4.38922L8.33361 4.13978L8.67694 4.38922L8.54528 3.98589L8.88917 3.73644H8.46472L8.33361 3.33311L8.20306 3.73644H7.77861Z" fill="white"/>
    <path d="M1.11194 5.95867L1.45528 6.20811L1.32417 6.61144L1.66694 6.362L2.01028 6.61144L1.87861 6.20811L2.2225 5.95867H1.79806L1.66694 5.55533L1.53639 5.95867H1.11194Z" fill="white"/>
    <path d="M3.546 6.61133L3.88933 6.36189L4.23211 6.61133L4.101 6.208L4.44433 5.95856H4.02044L3.88933 5.55578L3.75822 5.95856H3.33433L3.67711 6.208L3.546 6.61133Z" fill="white"/>
    <path d="M5.55639 5.95867L5.89972 6.20811L5.76805 6.61144L6.11139 6.362L6.45472 6.61144L6.32306 6.20811L6.66694 5.95867H6.2425L6.11139 5.55533L5.98083 5.95867H5.55639Z" fill="white"/>
    <path d="M7.77861 5.95867L8.12194 6.20811L7.99028 6.61144L8.33361 6.362L8.67694 6.61144L8.54528 6.20811L8.88917 5.95867H8.46472L8.33361 5.55533L8.20306 5.95867H7.77861Z" fill="white"/>
    </svg>
)

export default FlagUsa
