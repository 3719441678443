import React from "react"
import styled from "@emotion/styled"

import QuestionIcon from "../../../../images/icon-question.svg"
import Tooltip from "../../../ui/tooltip"
import { useSimulator } from "./context"
import { trackEvent } from "../../../../helpers/tracker"

const Wrapper = styled.div`
  flex-basis: 100%;
  margin-right: 7px;
  @media (max-width: 812px) {
    margin-bottom: 20px;
  }
`

const Label = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  font-family: Roboto;
  align-items: center;
`

const Select = styled.select`
  font-family: "Open Sans";
  font-size: 16px;
  padding: 8px;
  width: 95%;
  border-radius: 4px;
  height: 45px;
  border: 0;
  @media (max-width: 812px) {
    width: 200px;
  }
`

const Input = styled.input`
  font-family: "Open Sans";
  font-size: 16px;
  padding: 8px;
  width: 95%;
  border-radius: 4px;
  height: 45px;
  border: 0;
  @media (max-width: 812px) {
    width: 200px;
  }
`

const WrapperTooltip = styled.div`
  position: absolute;
  right: 10px;
`

export default function Parameter(props: Field) {
  const { setIsActive } = props
  const simulator = useSimulator()
  const propName = props.name as keyof typeof simulator.values
  const CustomTooltip = () =>
    props.tooltip ? (
      <>
        <WrapperTooltip
          onClick={() => {
            const label = props.label.toLocaleLowerCase().replace(/ /g, "-")
            trackEvent(`homeprospecto:simulador:${label}`)
            simulator.setTooltipIsOpen(
              simulator.tooltipIsOpen === propName ? "" : propName
            )
          }}
        >
          <img src={QuestionIcon} alt="question" />
        </WrapperTooltip>
        <Tooltip
          message={props.tooltip}
          isOpen={simulator.tooltipIsOpen === propName}
          onClick={() => {
            simulator.setTooltipIsOpen("")
            return true
          }}
        />
      </>
    ) : null

  return (
    <Wrapper>
      <Label>
        {props.label}
        <CustomTooltip />
      </Label>
      {props.type === "select" ? (
        <Select
          {...props}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            simulator.setValues(propName, event.target.value)
            setIsActive(false)
          }}
        >
          {props.options.map((option: Option, index: number) => (
            <option {...option} key={index}>
              {option.text}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          {...props}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue: string = event.target.value
            let value: number = 0.1
            if (props.validate && props.validate(inputValue))
              value = parseFloat(inputValue)
            simulator.setValues(propName, value)
            setIsActive(false)
          }}
          value={simulator.values[propName]}
          onBlur={(event: any) => {
            const inputValue: string = event.target.value
            if (!inputValue || inputValue === "0")
              simulator.setValues(propName, 0.1)
          }}
        />
      )}
    </Wrapper>
  )
}
