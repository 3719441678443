import React from "react"
import styled from "@emotion/styled"

import withdraw from "../../../images/withdraw.svg"

const Withdraw = styled.div`
  background-image: url(${withdraw});
  background-repeat: no-repeat;
  background-size: cover;
  width: 93px;
  height: 65px;
`

const WithdrawIcon = () => <Withdraw />

export default WithdrawIcon
