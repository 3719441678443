import React, { useState } from "react"
import Popover, { ArrowContainer } from "react-tiny-popover"
import styled from "@emotion/styled"

import HelpPopoverIcon from "./images/help-popover-icon"

const Content = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  font-size: 10px;
  line-height: 12px;
  max-width: 250px;
`

type Props = {
  text: string
  position?: "right" | "left" | "top" | "bottom"
}

const HelpPopover = ({ text, position = "top" }: Props) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <Popover
      isOpen={isOpened}
      position={position}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
        >
          <Content>{text}</Content>
        </ArrowContainer>
      )}
    >
      <HelpPopoverIcon
        onClick={() => setIsOpened(!isOpened)}
        onMouseOver={() => setIsOpened(true)}
        onMouseLeave={() => setIsOpened(false)}
      />
    </Popover>
  )
}

export default HelpPopover
