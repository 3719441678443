import React from 'react'
import styled from '@emotion/styled'

export default function PlanDetails(plan: Plan) {
  const Plan = styled.div`
    position: relative;
    flex-basis: 216px;
    width: 216px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    padding-top: 37px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    width: 228.262px;
    margin-bottom: 22px;
    padding-top: 27px;
    padding-bottom: 15px;
    @media (max-width: 812px) {
      height: 234px;
    }
  `

  const Title = styled.div`
    color: #454f5b;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 7px;
    @media (max-width: 812px) {
      text-align: center;
      font-size: 17.502px;
      font-style: normal;
      font-weight: 700;
      line-height: 23.337px;
      letter-spacing: -0.729px;
    }
  `

  const Amounts = styled.div`
    border: 1px solid #44d091;
    padding-top: 13px;
    padding-bottom: 11px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 16.34px;
    font-family: "Open Sans";
    font-weight: 400;
    @media (max-width: 812px) {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 20px;
      width: 138.561px;
      height: 40.839px;
      text-align: center;
      font-size: 8.751px;
      font-style: normal;
    }
  `

  const PlanFooter = styled.div`
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    @media (max-width: 812px) {
      padding-top: inherit;
      text-align: center;
      font-family: Roboto;
      font-size: 8.751px;
      font-style: normal;
      font-weight: 500;
      line-height: 11.668px;
      letter-spacing: 0.073px;
    }
  `

  const PlanDescription = styled.div`
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto";
    height: 85px;
    padding: 20px 15px 0px 15px;
    @media (max-width: 812px) {
      height: 24px;
      width: 169.92px;
      text-align: center;
      font-family: Roboto;
      font-size: 8.751px;
      font-style: normal;
      font-weight: 500;
      line-height: 11.668px;
      letter-spacing: 0.073px;
      padding: 5px
    }
  `

  const WrapperFooter = styled.div`
    background: #fff0d2;
    border-radius: 5px;
    height: 129px;
    padding: 5px;
    line-height: 16.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 812px) {
      line-height: 20px;
      padding-bottom: 20px;
      padding-top: 10px;
      width: 200.549px;
      height: 80px;
    }
  `

  const WrapperAmounts = styled.div`
    padding-left: 14px;
    padding-right: 14px;
    @media (max-width: 812px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  `

  const Recommended = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #30ab76;
    color: #fff;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  `

  return (
    <Plan>
      {plan.recommended && <Recommended>Recomendado</Recommended>}
      <Title>{plan.name.toUpperCase()}</Title>
      <WrapperAmounts>
        <Amounts>
          Depósito inicial desde<br />
          <strong>{plan.amountUSD} USD / {plan.amountCLP} CLP</strong>
        </Amounts>
      </WrapperAmounts>
      <PlanDescription>
        {plan.description}
      </PlanDescription>
      <WrapperFooter>
        <PlanFooter>
          <strong>
            {plan.footer.map((footer, index): JSX.Element => (
              <div key={index}>{footer}</div>
            ))}
          </strong>
        </PlanFooter>
      </WrapperFooter>
    </Plan>
  )
}
