import React, { useState } from "react"
import { Link, useNavigate } from "@reach/router"
import styled from "@emotion/styled"
import { Formik } from "formik"
import 'react-phone-input-2/lib/style.css'
import { useQueryString } from "@capitaria/capitaria-utms"

import DisclaimerV2 from "../../disclaimer-v2"
import CustomInput from "../../ui/custom-form-input"
import CapitariaLogoBlack from "../../ui/images/capitaria-logo-black"
import { trackEvent } from "../../../helpers/tracker"
import fieldsV2 from './fields-v2'
import signupSchema from "./signup-schema"
import "./styles.css"
import { signUp } from "../../../services/auth"
import footerBackground from "../../../images/footer-background.svg"
import CustomButtonV2 from "../../ui/custom-button-v2"
import Declarations from "./declarations"



const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-position: right;
  background-image: url(${footerBackground});
  background-color: #f9f9f9;
	background-position: 0px 304.713px;
	background-size: 100% 131.835%;
  background-repeat: no-repeat;
	mix-blend-mode: multiply;

  @media (max-width: 435px) {
    background-size: 270% 130.835%;
    background-position: -241px 305.713px;
  }
`

const Content = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 425px) {
    padding: 4rem 0 7rem;
    width: 100%;
  }
`

const Title = styled.h1`
  font-family: Open Sans;
  text-align: center;
	font-size: 27.65px;
	font-style: normal;
	font-weight: 500;
	line-height: 116%;
  margin: 0;
  color: #1f2425;
`

const Description = styled.p`
  font-family: Roboto;
  line-height: 140%;
  font-size: 13.33px;
  color: #1f2425;
  margin: 0.5rem 0 0;
  text-align: center;
  max-width: 447px;
  font-weight: 400;
  @media (max-width: 425px) {
    max-width: 301px;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
    letter-spacing: 0.1px;
  }
`

const Form = styled.form`
  padding-top: 2em;
	width: 264px;
	min-height: 495px;
  @media (max-width: 425px) {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const NavBar = styled.nav`
  height: 106px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 812px) {
    justify-content: space-between;
    padding: 0rem 1.25rem;
  }
`

const LogoWrapper = styled.div`
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
`

const CreateAccountMsg = styled.span`
  font-weight: 600;
  line-height: 140%;
  font-size: 0.875em;
  color: #1f2425;
  @media (max-width: 812px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
	width: 136.5px;
	height: 38.7px;
  background: transparent;
  border-radius: 6px;
  margin: 0 2rem;
	text-align: center;
  color: #000000;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #2ba770;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

  @media (max-width: 812px) {
    margin: 0;
  }
`

const buttonCSS = {
  marginTop: "3rem",
  height: "48px",
  width: "264px",
  "@media (max-width: 812px)": {
    width: "264px",
  },
}

const Error = styled.p`
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  max-width: 352px;
  @media (max-width: 812px) {
    max-width: 300px;
  }
`

const ContainerDeclarations = styled.div`
  width: 256px;
  margin-left: 40px;
`

const TextInput = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  margin: 1px 0px;
  & > a {
    color: #1f2425;
    text-decoration: none;
  }
`

const styleGroup = {
  "@media (max-width: 425px)": {
    width: "100%",
    alignItems: "center",
  }
}

const styleLabelWrapper = {
  "@media (max-width: 425px)": {
    width: "85%",
  }
}

const styleInput = {
  "@media (max-width: 425px)": {
    maxWidth: "368px",
    width: "85%",
  },
}

const UsCitizenDeclaration = () => (
  <ContainerDeclarations>
    <TextInput>Declaro no ser ciudadano ni residente de Estados Unidos</TextInput>
  </ContainerDeclarations>
)

const PrivacyPolicies = () => (
  <ContainerDeclarations>
    <TextInput>
      Acepto las{" "}
      <a
        href="https://capitariaplatformmaster-master.s3.us-east-2.amazonaws.com/documents/politica-de-privacidad-v7-Feb2021.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Políticas de Privacidad
      </a>
    </TextInput>
  </ContainerDeclarations>
)

const SignUpContentV2 = () => {
  const { parsedQueryString } = useQueryString()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const queryStringParams = useQueryString()

  const decodeParam = (
    param: string | null) => param ? decodeURIComponent(param.replace(/\+/g, " ")
  ) : ""
  const givenName = decodeParam(queryStringParams.parsedQueryString.name as string || "")
  const familyName = decodeParam(queryStringParams.parsedQueryString.last_name as string || "")
  const email = queryStringParams.parsedQueryString.email as string || ""


  const onSubmit = async (values: UserSignUp) => {
    setLoading(true)

    const result = await signUp({
      values,
      parsedQueryString
    })

    if (result.error.length) {
      setError(result.error)
    } else {
      await navigate("/validate-email", {
        state: {
          email: values.email.toLocaleLowerCase(),
          password: values.password,
        },
      })
    }

    setLoading(false)
  }

  return (
    <Container>
      <NavBar>
        <LogoWrapper>
          <CapitariaLogoBlack />
        </LogoWrapper>
        <CreateAccountMsg>¿Ya tienes una cuenta?</CreateAccountMsg>
        <StyledLink
          to={`/login${queryStringParams.queryString}`}
          onClick={() => trackEvent("sign-up:link-to:login")}
        >
          Ingresar
        </StyledLink>
      </NavBar>
      <Content>
        <Title>Crea tu cuenta trading</Title>
        <Description>
          El mercado da oportunidades todos los días
          <br />
          Te enseñamos, acompañamos y damos acceso al mercado para que seas
          parte de las miles de personas que ya estan tomando estas
          oportunidades
        </Description>
        <Formik
          enableReinitialize
          initialValues={{
            givenName: givenName,
            familyName: familyName,
            email: email,
            password: "",
            acceptPrivacyTerms: "false",
            usCitizenDeclaration: "false",
            liveInUSA: "false",
          }}
          validationSchema={signupSchema()}
          onSubmit={onSubmit}
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {fieldsV2.map(
                  (
                    field: InputSignUpForm,
                    index: number
                  ) => {
                    const name = field.name as keyof typeof values
                    const commonProps = {
                      ...field,
                      className: "custom-input",
                      onBlur: handleBlur,
                      errors: errors[name],
                      hasError: errors[name] && touched[name],
                      onChange: handleChange,
                      value: values[name],
                      errorMessage: errors[name],
                      styleGroup,
                      styleLabelWrapper,
                      style: styleInput,
                    }

                    return <CustomInput {...commonProps} key={index} />
                  }
                )}
                <Declarations
                  field="usCitizenDeclaration"
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                >
                  <UsCitizenDeclaration />
                </Declarations>
                <br />
                <Declarations
                  field="acceptPrivacyTerms"
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                >
                  <PrivacyPolicies />
                </Declarations>
                <CustomButtonV2
                  className="button-signup-v2"
                  loading={loading}
                  type="submit"
                  loadingStyle={buttonCSS}
                >
                  Crear mi cuenta real
                </CustomButtonV2>
                {error.length > 0 && <Error>{error}</Error>}
              </Form>
            )
          }}
        </Formik>
      </Content>
      <DisclaimerV2 />
    </Container>
  )
}

export default SignUpContentV2
