type SignUpField = InputSignUpForm

const fieldsV2: SignUpField[] = [
  {
    name: "givenName",
    label: "Nombre",
  },
  {
    name: "familyName",
    label: "Apellido",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
  },
  {
    name: "password",
    label: "Crear una contraseña",
    type: "password",
  },
]

export default fieldsV2
