
import React, { useEffect } from "react"
import styled from "@emotion/styled"

import { Container, Title, SubTitle } from "./styles"
import useLoadWistia from "./helper"
import trackEventPq from "../trackEventPq"

const VideoAnalytics = styled.span`
  display: inline-block;
  height: 200px;
  position: relative;
  width: 400px;
  border-radius: 5px;
  @media (max-width: 812px) {
    width: 300px;
  }
`

const Button = styled.div`
    background-color: #30AB76;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 55px;
    padding-right: 55px;
    border-radius: 5px;
    border: none;
    height: 40px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    margin-left: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    @media (max-width: 812px) {
      margin-left: 0;
    }
  `

const DemoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 812px) {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
`

const link = () => {
  window.open(
    window.matchMedia("(max-width: 700px)").matches
      ? "https://mobile.capitaria.com"
      : "https://www.capitaria.com/mt5-capitaria.html",
    "_blank"
  )
}


export default function DiscoverPlatform({
  setShowModal,
  setWistia,
  salesforceId,
}: SliderProps) {
  const loadWistia = useLoadWistia({ setShowModal, setWistia })

  useEffect(() => {
    loadWistia("1fj8nrq657")
  }, [loadWistia])

  return (
    <Container>
      <Title>Conoce la Plataforma</Title>
      <VideoAnalytics
        className="wistia_embed wistia_async_1fj8nrq657 popover=true popoverAnimateThumbnail=true"
        onClick={() => {
          trackEventPq({
            id: salesforceId,
            event: {
              element: "video3",
              column: "played",
              value: true
            },
          })
        }}
        id="wistia_1fj8nrq657"
      >
        &nbsp;
      </VideoAnalytics>
      <SubTitle>
        Crea tu cuenta demo y conoce como funciona una de las mejores
        plataformas de trading del mundo
      </SubTitle>
      <DemoWrapper>
        <Button onClick={() => link()}>Crea tu cuenta demo</Button>
      </DemoWrapper>
    </Container>
  )
}
