import * as Yup from "yup"

const Schema = () => {
  return Yup.object().shape({
    givenName: Yup.string().required("Este campo es requerido"),
    familyName: Yup.string().required("Este campo es requerido"),
    email: Yup.string()
      .email("El email ingresado no es válido")
      .required("Este campo es requerido"),
    password: Yup.string()
      .required("Este campo es requerido")
      .min(
        8,
        "La contraseña es muy corta - mínimo, debería tener 8 caracteres"
      ),
    acceptPrivacyTerms: Yup.string().matches(
      /^true$/,
      "Debes aceptar las Políticas de Privacidad para crear tu cuenta"
    ),
    usCitizenDeclaration: Yup.string().matches(
      /^true$/,
      "No debes ser residente de Estados Unidos para crear tu cuenta"
    ),
  })
}

export default Schema
