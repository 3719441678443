import React from "react"
import styled from "@emotion/styled"

import welcomeIconOff from "../../../images/icn-welcome-off.svg"

const WelcomeIcon = styled.div`
  background-image: url(${welcomeIconOff});
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 32px;
`

const WelcomeIconOff = () => <WelcomeIcon />

export default WelcomeIconOff
