import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/browser"

import { API, graphqlOperation } from "aws-amplify"
import { getUserExecutivesTeam } from "../../graphql/queries"

import { useAuth } from "../../hooks/auth-context"

import { Teams } from "../../services/executives-team"

import LoadingScreenIcon from "../ui/images/loading-screen"
import PageLayout from "../page-layout"

import Help from "./help"

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const HelpPage = () => {
  const [executiveTeam, setExecutiveTeam] = useState("loading" as ExecutiveTeam)
  const { state } = useAuth()
  const user = state.user as User

  useEffect(() => {
    const getExecutivesTeamName = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(getUserExecutivesTeam, { cognitoId: user.cognitoId })
        )
        const executiveTeamName = JSON.parse(
          response.data.getUserExecutivesTeam
        )

        if (
          executiveTeamName.hasOwnProperty("sf_Celula__c") &&
          Teams.includes(executiveTeamName.sf_Celula__c)
        ) {
          setExecutiveTeam(executiveTeamName.sf_Celula__c)
        } else {
          setExecutiveTeam("")
        }
      } catch (error) {
        Sentry.setExtra("error", JSON.stringify(error))
        Sentry.captureException(error)
        toast.error(
          "Ocurrió un error inesperado, por favor vuelve a intentarlo más tarde"
        )

        setExecutiveTeam("")
      }
    }

    getExecutivesTeamName()
  }, [user.cognitoId])

  if (executiveTeam === "loading") {
    return (
      <LoadingWrapper>
        <LoadingScreenIcon />
      </LoadingWrapper>
    )
  }

  return (
    <PageLayout>
      <Help />
    </PageLayout>
  )
}

export default HelpPage
