import React from "react"
import styled from "@emotion/styled"
import { Formik } from "formik"
import * as Yup from "yup"

import CustomInput from "../ui/custom-form-input"
import CustomButton from "../ui/custom-button"
import ForgotPasswordLayout from "./forgot-password-layout"

const Title = styled.h1`
  font-family: Open Sans;
  font-size: 27.65px;
  line-height: 116%;
  margin: 0;
  color: #1f2425;
  @media (max-width: 812px) {
    width: 216px;
    text-align: center;
  }
`

const Description = styled.p`
  font-family: Nunito;
  font-size: 13.33px;
  line-height: 140%;
  color: #1f2425;
  margin: 1rem 0 0;
  text-align: center;
  max-width: 380px;
  @media (max-width: 812px) {
    max-width: 237px;
  }
`

const Form = styled.form`
  padding-top: 2em;
`

const buttonCSS = {
  marginTop: "1rem",
  height: "48px",
  width: "350px",
  "@media (max-width: 812px)": {
    width: "300px",
    fontSize: "13.33px",
    lineHeight: "140%",
  },
}

const loadingButtonCss = { ...buttonCSS, marginTop: "2rem" }

const inputCSS = {
  width: "350px",
  "@media (max-width: 812px)": {
    width: "300px",
  },
}

const Error = styled.p`
  color: #e76a6a;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  padding: 0;
  max-width: 300px;
`

const ChangePasswordSchema = Yup.object().shape({
  code: Yup.string().required("Este campo es requerido"),
  password: Yup.string()
    .required("Este campo es requerido")
    .min(8, "La contraseña es muy corta - mínimo, debería tener 8 caracteres"),
})

type Props = {
  email: string
  loading: boolean
  onSubmit: (code: string, password: string) => void
  submitError: string
}

const ChangePassword = ({ email, loading, onSubmit, submitError }: Props) => (
  <ForgotPasswordLayout>
    <Title>Crea una nueva contraseña</Title>
    <Description>
      Ingresa el código que te enviamos a <strong>{email}</strong> para
      crear tu nueva contraseña.
    </Description>

    <Formik
      initialValues={{ code: "", password: "" }}
      validationSchema={ChangePasswordSchema}
      onSubmit={values => {
        onSubmit(values.code.toString().trim(), values.password)
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props
        return (
          <Form onSubmit={handleSubmit}>
            <CustomInput
              name="code"
              label="Código"
              type="number"
              style={inputCSS}
              value={values.code}
              onChange={event => {
                handleChange(event)
                setFieldValue("code", event.target.value)
              }}
              onBlur={handleBlur}
              hasError={errors.code && touched.code}
              errorMessage={errors.code}
            />
            <CustomInput
              name="password"
              label="Nueva contraseña"
              type="password"
              style={inputCSS}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={errors.password && touched.password}
              errorMessage={errors.password}
            />

            <CustomButton
              style={buttonCSS}
              loading={loading}
              type="submit"
              loadingStyle={loadingButtonCss}
            >
              CAMBIAR CONTRASEÑA
            </CustomButton>

            {submitError.length > 0 && <Error>{submitError}</Error>}
          </Form>
        )
      }}
    </Formik>
  </ForgotPasswordLayout>
)

export default ChangePassword
