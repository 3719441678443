import React from "react"
import styled from "@emotion/styled"

import spinnerIcon from "../../../images/spinner.svg"

const Spinner = styled.div`
  background-image: url(${spinnerIcon});
  background-repeat: no-repeat;
  background-size: cover;
  width: 36px;
  height: 36px;
`

const SpinnerIcon = () => <Spinner />

export default SpinnerIcon
