import React from "react"
import styled from "@emotion/styled"

import bancoBci from "../../../images/bci.png"

const BancoBCI = styled.img`
  width: 109px;
  height: 59px;
`

const BancoBCILogo = () => (
  <BancoBCI src={bancoBci} alt="Banco BCI" />
)

export default BancoBCILogo
