import React, { useState } from "react"
import { Location, WindowLocation } from "@reach/router"
import styled from "@emotion/styled"

import WelcomeIconOn from "./images/welcome-icon-on"
import WelcomeIconOff from "./images/welcome-icon-off"
import DepositIconOn from "./images/deposit-icon-on"
import DepositIconOff from "./images/deposit-icon-off"
import VerifyDocumentsIconOn from "./images/verify-documents-icon-on"
import VerifyDocumentsIconOff from "./images/verify-documents-icon-off"
import OperativesAspectsIconOn from "./images/operatives-aspects-icon-on"
import OperativesAspectsIconOff from "./images/operatives-aspects-icon-off"
import CalculatorIconOn from "./images/calculator-icon-on"
import CalculatorIconOff from "./images/calculator-icon-off"
import HelpIconOn from "./images/help-icon-on"
import HelpIconOff from "./images/help-icon-off"
import GreenArrowIcon from "./images/green-arrow-icon"
import { useAuth } from "../../hooks/auth-context"

import SidebarItem from "./sidebar-item"
import MobileNavbarItem from "./mobile-navbar-item"

const DesktopContainer = styled.div`
  border-right: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 2rem 0;
  max-width: 90px;
  @media (max-width: 812px) {
    display: none;
  }
`

const MobileContainer = styled.div`
  border-right: 1px solid #e0e0e0;
  background-color: #ffffff;
  width: 100;
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
`

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
  text-decoration: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 1.375rem;
  min-height: 54px;
`

const ToggleButtonText = styled.span`
  margin-left: 10px;
  flex-grow: 1;
`

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 2rem;
`

type DesktopNavbarProps = {
  location: WindowLocation
  items: Link[]
}

const DesktopNavbar = ({ location, items }: DesktopNavbarProps) => (
  <DesktopContainer>
    <LinkList>
      {items.map((l, idx) => (
        <SidebarItem
          key={`nav-link-${idx}`}
          location={location}
          {...l}
        />
      ))}
    </LinkList>
  </DesktopContainer>
)

type MobileNavbarProps = {
  location: WindowLocation
  items: Link[]
}

const MobileNavbar = ({ location, items }: MobileNavbarProps) => {
  const [isOpened, setIsOpened] = useState(false)

  const filteredItems: Link[] = []
  let selectedItem: Link = items[0]

  items.forEach(l => {
    if (l.path !== location.pathname) {
      filteredItems.push(l)
    } else {
      selectedItem = l
    }
  })

  const onClick = () => setIsOpened(!isOpened)

  return (
    <MobileContainer>
      <ToggleButton onClick={onClick}>
        {selectedItem.iconOn}{" "}
        <ToggleButtonText>{selectedItem.text}</ToggleButtonText>
        <GreenArrowIcon />
      </ToggleButton>

      {isOpened && (
        <LinkList>
          {filteredItems.map((i, idx) => (
            <MobileNavbarItem
              key={`mobile-nav-link-${idx}`}
              location={location}
              path={i.path}
              text={i.text}
              iconOn={i.iconOn}
              iconOff={i.iconOff}
              external={i.external}
              onClick={onClick}
            />
          ))}
        </LinkList>
      )}
    </MobileContainer>
  )
}


const Sidebar = () => {
  const { state } = useAuth()
  const user = state.user as User

  const { firstLoad } = state

  const bottomLinks = [
    {
      id: "deposit",
      path: "/deposit",
      text: "Depósitos",
      iconOn: <DepositIconOn />,
      iconOff: <DepositIconOff />,
    },
    {
      id: "verify-documents",
      path: "/verify-documents",
      text: "Verificación de Tarjeta",
      iconOn: <VerifyDocumentsIconOn />,
      iconOff: <VerifyDocumentsIconOff />,
    },
    {
      id: "operatives-aspects",
      path: "https://bit.ly/3pb6wVA",
      text: "Aspectos Operativos",
      iconOn: <OperativesAspectsIconOn />,
      iconOff: <OperativesAspectsIconOff />,
      external: true,
    },
    {
      id: "calculator",
      path:
        "https://calculadora.capitaria.com/basica?utm_source=capitaria&utm_medium=referral&utm_campaign=capitaria&utm_content=boton-perfil-calculadora",
      text: "Calculadoras",
      iconOn: <CalculatorIconOn />,
      iconOff: <CalculatorIconOff />,
      external: true,
    },
  ]

  let topLinks: Link[] = [
    {
      id: "home",
      path: "/home",
      alias: ["/home"],
      text: "Bienvenida",
      iconOn: <WelcomeIconOn />,
      iconOff: <WelcomeIconOff />,
    },
  ]

  let items: Link[] = []
  if (!firstLoad) {
    items = [...topLinks]
    if (user.kycNextStep === "home") {
      items = [
        ...items,
        ...bottomLinks,
        {
          id: "help",
          path: "/help",
          text: "Preguntas Frecuentes",
          iconOn: <HelpIconOn />,
          iconOff: <HelpIconOff />,
        },
      ]
    } else {
      items = [
        ...items,
        {
          id: "help",
          path: "/help",
          text: "Preguntas Frecuentes",
          iconOn: <HelpIconOn />,
          iconOff: <HelpIconOff />,
        },
      ]
    }
  }

  return (
    <Location>
      {({ location }) => (
        <>
          {user.kycNextStep === "home" &&
            <>
              <DesktopNavbar location={location} items={items} />
              {!firstLoad && <MobileNavbar location={location} items={items} />}
            </>
          }
        </>
      )}
    </Location>
  )
}

export default Sidebar
