import styled from "@emotion/styled"

import helpIcon from "../../../images/help-popover-icon.svg"

const HelpPopoverIcon = styled.div`
  background-image: url(${helpIcon});
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
`

export default HelpPopoverIcon
