import React from "react"
import styled from "@emotion/styled"

import backArrow from "../../../images/back-arrow.svg"

const BackArrow = styled.div`
  background-image: url(${backArrow});
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
`

const BackArrowIcon = () => <BackArrow />

export default BackArrowIcon
