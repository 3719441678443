import React, { useEffect } from "react"
import styled from "@emotion/styled"

import { Container, Title, SubTitle } from "./styles"
import useLoadWistia from "./helper"
import trackEventPq from "../trackEventPq"

const VideoAnalytics = styled.span`
  display: inline-block;
  height: 200px;
  position: relative;
  width: 400px;
  @media (max-width: 812px) {
    width: 300px;
  }
`

export default function TradingVsInvesting({
  setShowModal,
  setWistia,
  salesforceId,
}: SliderProps) {
  const loadWistia = useLoadWistia({ setShowModal, setWistia })

  useEffect(() => {
    loadWistia("6t6nlj7x27")
  }, [loadWistia])

  return (
    <Container>
      <Title>Trading vs Inversiones</Title>
      <VideoAnalytics
        className="wistia_embed wistia_async_6t6nlj7x27 popover=true popoverAnimateThumbnail=true"
        onClick={() => {
          trackEventPq({
            id: salesforceId,
            event: {
              element: "video1",
              column: "played",
              value: true
            },
          })
        }}
        id="wistia_6t6nlj7x27"
      >
        &nbsp;
      </VideoAnalytics>
      <SubTitle>
        ¿Qué es el trading? ¿En que se diferencia de una inversión tradicional?
        Dale play y descubre si el trading es realmente para ti.
      </SubTitle>
    </Container>
  )
}
