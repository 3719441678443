import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as Sentry from "@sentry/browser"
import { API, graphqlOperation } from "aws-amplify"

import { useAuth } from "../../hooks/auth-context"

import { getSubaccountsAndTransactions } from "../../graphql/queries"

import AlertBanner from "../ui/alert-banner"
import SubAccounts from "./subaccounts"
import TransactionList from "./transactions-list"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`
const Alert = styled.p`
  font-size: 16px;
  line-height: 22px;
`

const StyledLink = styled.span`
  color: #363f41;
  font-weight: bold;
  cursor: pointer;
`

type Props = {
  goDeposit: () => void
}

const Transactions = ({ goDeposit }: Props) => {
  const { state } = useAuth()
  const user = state.user as User

  const [subaccountsAndTransactions, setSubaccountsAndTransactions] = useState<
    SubaccountsAndTransactions
  >({} as SubaccountsAndTransactions)

  useEffect(() => {
    const callInfoAccount = async () => {
      const { cognitoId, givenName, familyName, email, customerType } = user

      try {
        const response = await API.graphql(
          graphqlOperation(getSubaccountsAndTransactions, {
            cognitoId: user.cognitoId,
          })
        )

        setSubaccountsAndTransactions(
          JSON.parse(response.data.getSubaccountsAndTransactions)
        )
      } catch (error) {
        Sentry.setExtra("cognitoID", {
          cognitoId,
          givenName,
          familyName,
          email,
          customerType,
        })
        Sentry.captureException(
          "Error al obtener las subcuentas y transacciones de un usuario"
        )
      }
    }

    callInfoAccount()
  }, [user])

  let message = (
    <span>
      Valores actualizados cada 24 horas, solo se muestran las últimas 30
      transacciones
    </span>
  )

  const { subaccounts = [], transactions = [] } = subaccountsAndTransactions

  if (subaccounts.length === 0) {
    message = (
      <span>
        Aún no tienes transacciones, para depositar{" "}
        <StyledLink onClick={goDeposit}>pincha aquí.</StyledLink> La lista de
        transacciones será actualizada en 24 horas
      </span>
    )
  }
  return (
    <Container>
      <AlertBanner>
        <Alert>{message}</Alert>
      </AlertBanner>
      {subaccounts.length ? (
        <>
          <SubAccounts totalSubAccounts={subaccounts} />
          <TransactionList transactionsList={transactions} />
        </>
      ) : null}
    </Container>
  )
}

export default Transactions
