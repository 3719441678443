export const termsChile: ItemTerm[] = [
  {
    key: 'contract',
    title: 'Términos, condiciones y productos',
    description: `Capitaria Latam SPA, en adelante (Capitaria), es un bróker que ofrece a sus clientes una cuenta de inversión para operar en diversos instrumentos financieros, conocidos como Contratos por Diferencia (CFD's), que son instrumentos financieros derivados del mercado OTC (en adelante, denominado “OTC”), que replican los movimientos que mantienen distintos activos financieros, tales como Futuros, Índices, Exchange Trade Funds (ETF's), divisas y acciones, a través de los cuales los clientes pueden invertir bajo su propia cuenta y riesgo.`,
  },
  {
    key: 'market',
    title: 'Mercado OTC',
    description: 'Las operaciones en el mercado OTC se realizan en un mercado informal, conocido internacionalmente como "over the counter" (sobre el mostrador) u "OTC". Por tanto, las operaciones no se realizan por medio de mercados unificados o regulados como son, por ejemplo, el bursátil y el bancario.',
  },
  {
    key: 'risk',
    title: 'Riesgos',
    description: 'Operar en los mercados antes mencionados involucra conocimientos y habilidades especificas incluyendo el conocimiento y sumisión de los riesgos que implica el realizar operaciones con apalancamiento.'
  },
  {
    key: 'market-liquidity',
    title: 'Liquidez del mercado',
    description: 'Puede haber ciertos casos en los cuales la liquidez del mercado disminuye causando que las operaciones con determinadas monedas u otros instrumentos financieros cesen, pudiendo generarse condiciones adversas para el cliente que pueden llevar a pérdidas financieras substanciales, incluso mayores al dinero que disponga en su cuenta de operaciones.'
  },
  {
    key: 'orders',
    title: 'Órdenes',
    description: 'El cliente comprará y/o venderá instrumentos OTC directamente y por su cuenta a Capitaria, a través de órdenes que podrán darse en forma oral vía telefónica, o enviadas a través de la plataforma online que dispone. Capitaria podrá rechazar órdenes de inversión realizada por el cliente cuando los movimientos y/o condiciones de mercado hagan imposible su ejecución.'
  },
  {
    key: 'operations-duration',
    title: 'Duración de las operaciones',
    description: 'Las operaciones de compra y venta que realice el cliente con Capitaria, se mantendrán abiertas hasta que el cliente decida cerrarlas por compensación, o cuando éstas venzan, momento en que se acreditará o devengará, el diferencial que corresponda. En ningún caso Capitaria procederá a la entrega física de las divisas o instrumentos que se negocien.'
  },
  {
    key: 'deposit',
    title: 'Depósitos',
    description: 'El sistema requiere del depósito de dineros que respalden las operaciones, los que podrán ser depositados por el cliente en las cuentas bancarias de Capitaria o bien en cualquiera de las cuentas bancarias de entidades recaudadoras con las que Capitaria mantiene relaciones comerciales.'
  },
  {
    key: 'withdrawals',
    title: 'Retiros',
    description: 'Todos los fondos propiedad del Cliente si bien pueden ser retirados en cualquier momento por este, ello está condicionado a que al momento del retiro el cliente haya cumplido con todas las obligaciones derivadas de su relación con Capitaria. Estos fondos serán entregados, preferentemente, en la misma moneda en que el cliente haya depositado los fondos de garantía necesarios para operar en el sistema.'
  },
  {
    key: 'recommendations',
    title: 'Recomendaciones de inversión',
    description: 'Capitaria, sus empleados, agentes o dependientes, no son asesores financieros. Por tanto, en el hipotético evento de existir determinadas recomendaciones, éstas no son vinculantes y estarán basadas solamente en opiniones personales de quien las emite.'
  },
  {
    key: 'communications',
    title: 'Comunicaciones',
    description: 'Las comunicaciones se realizarán a través de la plataforma o bien a través de correo electrónico fijado para estos efectos. Capitaria proporcionará al cliente claves de acceso a la plataforma y/o a su página web, de modo que pueda acceder a ellas para revisar, controlar y comprobar en detalle todos sobre los movimientos de su cuenta. Las condiciones y aspectos operativos serán informados al cliente en su sitio web www.capitaria.com.'
  },
  {
    key: 'term',
    title: 'Término del Contrato',
    description: 'El contrato tendrá una duración indefinida a partir de la fecha de suscripción. Cualquiera de las partes podrá ponerle término en la medida que no haya obligaciones pendientes, bastando que se comunique de la decisión vía correo electrónico, y, además, se hayan retirado íntegramente los fondos de garantía. En caso de permanencia de los fondos, o bien, en caso de que se realizaren nuevos depósitos, el presente contrato recobrará vigencia.'
  },
  {
    key: 'dof',
    title: 'Origen de los fondos',
    description: 'El cliente declara que los fondos que depositará a la compañía son fondos provenientes de actividades completamente lícitas y que han cumplido todas las obligaciones tributarias respectivas en su país de origen, además declara que los fondos con los que operará son recursos propios y no son fondos de propiedad de terceros. Al igual que en el caso de realizar depósitos con tarjetas bancarias, declara que serán de propiedad del cliente y no de terceros.'
  },
  {
    key: 'relevant-terms',
    title: 'Términos relevantes',
    description: 'El cliente declara que ha entendido todos los conceptos explicados en la declaración de términos relevantes, los cuales son de relevancia para poder operar la cuenta real. Los ejecutivos de la compañía están disponibles para responder cualquier consulta relacionada a los términos relevantes.'
  },
]


export const termsInternational: ItemTerm[] = [
  {
    key: 'contract',
    title: 'Términos, condiciones y productos',
    description: `KT Financial Group Limited, en adelante (KT), es un bróker representado localmente por Capitaria (agente autorizado). KT ofrece a sus clientes una cuenta de inversión para operar en diversos instrumentos financieros, conocidos como Contratos por Diferencia (CFD's), que son instrumentos financieros derivados del mercado OTC (en adelante, denominado “OTC”), que replican los movimientos que mantienen distintos activos financieros, tales como Futuros, Índices, Exchange Trade Funds (ETF's), divisas y acciones, a través de los cuales los clientes pueden invertir bajo su propia cuenta y riesgo.`
  },
  {
    key: 'market',
    title: 'Mercado OTC',
    description: 'Las operaciones en el mercado OTC se realizan en un mercado informal, conocido internacionalmente como "over the counter" (sobre el mostrador) u "OTC". Por tanto, las operaciones no se realizan por medio de mercados unificados o regulados como son, por ejemplo, el bursátil y el bancario.'
  },
  {
    key: 'risk',
    title: 'Riesgos',
    description: 'Operar en los mercados antes mencionados involucra conocimientos y habilidades especificas incluyendo el conocimiento y sumisión de los riesgos que implica el realizar operaciones con apalancamiento.'
  },
  {
    key: 'market-liquidity',
    title: 'Liquidez del mercado',
    description: 'Puede haber ciertos casos en los cuales la liquidez del mercado disminuye causando que las operaciones con determinadas monedas u otros instrumentos financieros cesen, pudiendo generarse condiciones adversas para el cliente que pueden llevar a pérdidas financieras substanciales, incluso mayores al dinero que disponga en su cuenta de operaciones.'
  },
  {
    key: 'orders',
    title: 'Órdenes',
    description: 'El cliente comprará y/o venderá instrumentos OTC directamente y por su cuenta a KT, a través de órdenes que podrán darse en forma oral vía telefónica, o enviadas a través de la plataforma online que dispone KT. KT podrá rechazar órdenes de inversión realizada por el cliente cuando los movimientos y/o condiciones de mercado hagan imposible su ejecución.'
  },
  {
    key: 'operations-duration',
    title: 'Duración de las operaciones',
    description: 'Las operaciones de compra y venta que realice el cliente con KT, se mantendrán abiertas hasta que el cliente decida cerrarlas por compensación, o cuando éstas venzan, momento en que se acreditará o devengará, el diferencial que corresponda. En ningún caso KT procederá a la entrega física de las divisas o instrumentos que se negocien.'
  },
  {
    key: 'deposit',
    title: 'Depósitos',
    description: 'El sistema requiere del depósito de dineros que respalden las operaciones, los que podrán ser depositados por el cliente en las cuentas bancarias de KT o bien en las cuentas bancarias de cualquiera de sus Recaudadoras, Agencias, Representantes, Afiliadas o Agentes alrededor del mundo, y que en cada caso se le comunicarán como alternativa al momento de abrir su cuenta.'
  },
  {
    key: 'withdrawals',
    title: 'Retiros',
    description: 'Todos los fondos propiedad del Cliente si bien pueden ser retirados en cualquier momento por este, ello está condicionado a que al momento del retiro el cliente haya cumplido con todas las obligaciones derivadas de su relación con KT. Estos fondos serán entregados, preferentemente, en la misma moneda en que el cliente haya depositado los fondos de garantía necesarios para operar en el sistema.'
  },
  {
    key: 'recommendations',
    title: 'Recomendaciones de inversión',
    description: 'KT, sus empleados, agentes o dependientes, no son asesores financieros. Por tanto, en el hipotético evento de existir determinadas recomendaciones, éstas no son vinculantes y estarán basadas solamente en opiniones personales de quien las emite.'
  },
  {
    key: 'communications',
    title: 'Comunicaciones',
    description: 'Las comunicaciones se realizarán a través de la plataforma o bien a través de correo electrónico fijado para estos efectos. KT proporcionará al cliente claves de acceso a la plataforma y/o a su página web, de modo que pueda acceder a ellas para revisar, controlar y comprobar en detalle todos sobre los movimientos de su cuenta. Las condiciones y aspectos operativos serán informados al cliente en su sitio web www.ktspirit.com.'
  },
  {
    key: 'term',
    title: 'Término del Contrato',
    description: 'El contrato tendrá una duración indefinida a partir de la fecha de suscripción. Cualquiera de las partes podrá ponerle término en la medida que no haya obligaciones pendientes, bastando que se comunique de la decisión vía correo electrónico, y, además, se hayan retirado íntegramente los fondos de garantía. En caso de permanencia de los fondos, o bien, en caso de que se realizaren nuevos depósitos, el presente contrato recobrará vigencia.'
  },
  {
    key: 'dof',
    title: 'Origen de los fondos',
    description: 'El cliente declara que los fondos que depositará a la compañía son fondos provenientes de actividades completamente lícitas y que han cumplido todas las obligaciones tributarias respectivas en su país de origen, además declara que los fondos con los que operará son recursos propios y no son fondos de propiedad de terceros. Al igual que en el caso de realizar depósitos con tarjetas bancarias, declara que serán de propiedad del cliente y no de terceros.'
  },
  {
    key: 'relevant-terms',
    title: 'Términos relevantes',
    description: 'El cliente declara que ha entendido todos los conceptos explicados en la declaración de términos relevantes, los cuales son de relevancia para poder operar la cuenta real. Los ejecutivos de la compañía están disponibles para responder cualquier consulta relacionada a los términos relevantes.'
  },
]
